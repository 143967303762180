import React from 'react'

export const useClickAway = (
  isOpen: boolean,
  trigger?: string,
  onClose?: () => void
): React.RefObject<HTMLElement | null> => {
  const ref = React.useRef<HTMLElement>(null)
  React.useEffect(() => {
    if (trigger !== 'click' || !document || !isOpen) {
      return
    }
    const listener = (event: MouseEvent): void => {
      if (ref?.current && !ref?.current.contains(event.target as Node)) {
        onClose?.()
      }
    }
    document.addEventListener('click', listener)
    return () => {
      document.removeEventListener('click', listener)
    }
  }, [isOpen, onClose, trigger])

  return ref
}
