import React from 'react'
import { Stack } from '@hub/stack'
import { As, HubStyleObject, StandardColors } from '@hub/design-system-base'
import { Box } from '@hub/box'

export interface TileProps {
  isLastChild?: boolean
  asOuter?: As
  asInner?: As
  onClick?: (e: React.MouseEvent) => void
  sxInner?: HubStyleObject
  sxOuter?: HubStyleObject
  backgroundColor?: StandardColors
}

const Tile: React.FC<React.PropsWithChildren<TileProps>> = ({
  isLastChild = false,
  children,
  asOuter,
  asInner,
  onClick,
  sxOuter,
  sxInner,
  backgroundColor,
}) => (
  <Box
    as={asOuter}
    sx={{
      display: 'flex',
      justifyContent: 'center',
      ...(backgroundColor === undefined
        ? {
            backgroundColor: [
              'surfaceBrandPrimary',
              'surfaceBrandPrimary',
              'transparent',
              'transparent',
            ],
            borderRightWidth: isLastChild ? 'none' : ['0px', '0px', '1px '],
            borderRightStyle: isLastChild ? 'none' : 'solid',
            borderRightColor: isLastChild
              ? 'transparent'
              : ['transparent', 'transparent', 'ghost', 'ghost'],
          }
        : null),
      ['@media screen and (max-width: 310px)']: {
        fontSize: 'font-sm',
      },
      ['@media screen and (max-width: 280px)']: {
        letterSpacing: '-0.5px',
      },
      ...sxOuter,
    }}
    onClick={onClick}
    buttonVariant="tile"
  >
    <Stack
      align="center"
      justify="center"
      sx={{
        paddingY: 'spacing-md',
        minWidth: '100%',
        backgroundColor,

        ...sxInner,
      }}
      as={asInner}
    >
      {children}
    </Stack>
  </Box>
)

export default Tile
