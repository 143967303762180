import {
  // We can't get out of useBreakpointValue here as we need to know the breakpoint
  // in JS to compute the Cloudinary image URLs
  // eslint-disable-next-line no-restricted-syntax
  useBreakpointValue,
  HubResponsiveArray,
  DEFAULT_BREAKPOINT,
} from '@hub/design-system-base'
import { asArray } from './maybe-array'

export const useCurrent = <T>(
  value: HubResponsiveArray<T | undefined>
): T | null | undefined =>
  useBreakpointValue(asArray(value), DEFAULT_BREAKPOINT)
