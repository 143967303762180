import React from 'react'
import { As, StandardColors } from '@hub/design-system-base'
import MagicLink from '../magic-link'
import Tile from './tile'

interface TileLinkProps {
  url: string
  isLastChild?: boolean
  as?: As
  onClick?: (e: React.MouseEvent) => void
  backgroundColor?: StandardColors
}

const TileLink: React.FC<React.PropsWithChildren<TileLinkProps>> = ({
  url,
  isLastChild = false,
  as,
  onClick,
  children,
  backgroundColor,
}) => (
  <Tile
    asOuter={props => <MagicLink {...props} buttonVariant="tile" href={url} />}
    onClick={onClick}
    isLastChild={isLastChild}
    asInner={as}
    backgroundColor={backgroundColor}
  >
    {children}
  </Tile>
)

export default TileLink
