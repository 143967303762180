import { AccordionIcon } from '@chakra-ui/react'
import { MenuButton } from './menu-button'
import React from 'react'
import { ChevronLeftSmallIcon, ChevronRightSmallIcon } from '@hub/icon'
import { StandardSizes } from '@hub/design-system-base'
import { useNavigationContext } from './navigation-context'
import { Spinner } from '@hub/spinner'
import { AccordionButton } from '@hub/accordion'
import { Text } from '@hub/text'
import { Box } from '@hub/box'

export type NavigationButtonProps = {
  isLoading?: boolean
  active?: boolean
  size?: StandardSizes
  tone?: 'neutral' | 'strong'
  onClick?: (
    event: React.MouseEvent,
    context: { isOpen: boolean | undefined }
  ) => void
  onMouseEnter?: React.MouseEventHandler
  onMouseLeave?: React.MouseEventHandler
}

export const NavigationButton: React.FC<
  React.PropsWithChildren<NavigationButtonProps>
> = ({ children, isLoading, size, tone, ...props }) => {
  const {
    buttonRef,
    isBar,
    isOpen,
    isPanel,
    panelLayout,
    navigationPlacement,
    onClick,
    onMouseEnter,
    onMouseLeave,
  } = useNavigationContext()

  const handleClick: React.MouseEventHandler = event => {
    props.onClick?.(event, { isOpen })
    if (event.isDefaultPrevented()) {
      return
    }
    onClick?.(event)
  }

  const handleMouseEnter: React.MouseEventHandler = event => {
    onMouseEnter?.(event)
    props.onMouseEnter?.(event)
  }

  const handleMouseLeave: React.MouseEventHandler = event => {
    onMouseLeave?.(event)
    props.onMouseLeave?.(event)
  }
  const variant = React.useMemo(() => (isBar ? 'bar' : undefined), [isBar])

  const [leftIcon, rightIcon] = React.useMemo(() => {
    const leftIcon =
      isPanel && navigationPlacement === 'right' ? (
        <ChevronLeftSmallIcon key={0} boxSize="size-icon-sm" />
      ) : undefined

    const rightIcon =
      isPanel && navigationPlacement === 'left' ? (
        <ChevronRightSmallIcon key={1} boxSize="size-icon-sm" />
      ) : undefined

    const loadingIcon = isLoading ? <Spinner /> : undefined
    return [leftIcon, loadingIcon ?? rightIcon]
  }, [isLoading, isPanel, navigationPlacement])

  if (panelLayout === 'accordion') {
    return (
      <Box onClick={handleClick}>
        <AccordionButton
          sx={{
            justifyContent: 'space-between',
            minHeight: 48,
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          <Text tone={tone} fontSize={sizeToFontSize(size)}>
            {children}
          </Text>
          <AccordionIcon />
        </AccordionButton>
      </Box>
    )
  }

  return (
    <MenuButton
      ref={buttonRef}
      variant={variant}
      size={size}
      tone={tone}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
    >
      {children}
    </MenuButton>
  )
}

function sizeToFontSize(
  size?: StandardSizes
): React.ComponentProps<typeof Text>['fontSize'] {
  if (!size) {
    return undefined
  }
  const fontSize = `font-${size}` as React.ComponentProps<
    typeof Text
  >['fontSize']
  return fontSize
}
