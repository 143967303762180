import React from 'react'
import { Stack } from '@hub/stack'
import { Text } from '@hub/text'
import { LinkOverlay } from '@hub/link'
import { FeatureProps } from './feature'

export const FeatureDetails: React.FC<
  React.PropsWithChildren<
    Pick<FeatureProps, 'title' | 'body' | 'link' | 'onClick'>
  >
> = ({ title, body, link, onClick }): JSX.Element => {
  return (
    <Stack shouldWrapChildren={false} gap="spacing-sm">
      {title && <Text lineHeight="base">{title}</Text>}
      {body && (
        <Text lineHeight="base" tone="neutral">
          {body}
        </Text>
      )}
      {link && (
        <LinkOverlay href={link.url} onClick={onClick}>
          <Text lineHeight="base" decoration="underline">
            {link.label}
          </Text>
        </LinkOverlay>
      )}
    </Stack>
  )
}
