import React from 'react'
import {
  GridItem as ChakraGridItem,
  GridItemProps as ChakraGridItemProps,
} from '@chakra-ui/react'
import { HubExcludeResponsiveObject } from '@hub/design-system-base'

type GridItemProps = Pick<ChakraGridItemProps, 'as' | 'sx' | 'children'> & {
  colEnd?: HubExcludeResponsiveObject<ChakraGridItemProps['colEnd']>
  colSpan?: HubExcludeResponsiveObject<ChakraGridItemProps['colSpan']>
  colStart?: HubExcludeResponsiveObject<ChakraGridItemProps['colStart']>
  rowEnd?: HubExcludeResponsiveObject<ChakraGridItemProps['rowEnd']>
  rowSpan?: HubExcludeResponsiveObject<ChakraGridItemProps['rowSpan']>
  rowStart?: HubExcludeResponsiveObject<ChakraGridItemProps['rowStart']>
}

export const GridItem: React.FC<React.PropsWithChildren<GridItemProps>> = props => (
  <ChakraGridItem {...props} />
)
