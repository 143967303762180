import React from 'react'

import { Button } from '@hub/button'
import { ClearInvertedSmallIcon } from '@hub/icon'
interface ClearButtonProps {
  clearQuery: () => void
  query?: string
}

const ClearButton: React.FC<React.PropsWithChildren<ClearButtonProps>> = ({
  query,
  clearQuery,
}) => {
  if (!query) {
    return null
  }
  return (
    <Button
      aria-label="Clear search text"
      type="button"
      variant={'transparent'}
      rightIcon={
        <ClearInvertedSmallIcon
          sx={{
            cursor: 'pointer',
            position: 'absolute',
            width: 'size-icon-sm',
            height: 'size-icon-sm',
            top: 'spacing-sm',
            left: 'spacing-md',
            color: 'licorice',
          }}
        />
      }
      onClick={e => {
        e.preventDefault()
        clearQuery()
      }}
    />
  )
}

export default ClearButton
