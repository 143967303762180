import { sizeScale } from '@hub/design-system-base'

export const avatarTheme = {
  baseStyle: {
    container: {
      backgroundColor: 'licorice',
      color: 'white',
      fontWeight: 'bold',
    },
  },
  sizes: {
    sm: {
      container: {
        width: sizeScale[4],
        height: sizeScale[4],
      },
      excessLabel: {
        width: sizeScale[4],
        height: sizeScale[4],
      },
      label: {
        fontSize: 'font-xs',
        lineHeight: 'line-height-none',
      },
    },
    md: {
      container: {
        width: sizeScale[5],
        height: sizeScale[5],
      },
      excessLabel: {
        width: sizeScale[5],
        height: sizeScale[5],
      },
      label: {
        fontSize: 'font-xs',
        lineHeight: 'line-height-none',
      },
    },
    lg: {
      container: {
        width: sizeScale[8],
        height: sizeScale[8],
      },
      excessLabel: {
        width: sizeScale[8],
        height: sizeScale[8],
      },
      label: {
        fontSize: 'font-md',
        lineHeight: 'line-height-none',
      },
    },
    xl: {
      container: {
        width: sizeScale[10],
        height: sizeScale[10],
      },
      excessLabel: {
        width: sizeScale[10],
        height: sizeScale[10],
      },
      label: {
        fontSize: 'font-lg',
        lineHeight: 'line-height-none',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
}
