import React, { ReactElement } from 'react'
import { oneOf } from 'prop-types'
import { Stack } from '@hub/stack'
import { incBaseAccount } from '../../helpers/paths'

import { LinkSectionPropsType } from './link-section'
import * as paths from './paths'

import { MembershipColumnLinks } from './membership-column-links'

interface PropsType {
  country: string
}

const LOGIN = incBaseAccount('login')
const REGISTER = incBaseAccount('register')

const westfieldMembershipSection: LinkSectionPropsType = {
  heading: {
    text: 'Membership',
    url: paths.WESTFIELD_MEMBERSHIP,
    newTab: true,
  },
  links: [
    {
      text: 'Login',
      url: LOGIN,
      trackingLabel: 'Westfield Plus Login',
    },
    {
      text: 'Join',
      url: REGISTER,
      trackingLabel: 'Westfield Plus Join link',
    },
    {
      text: 'Terms and Conditions',
      url: paths.TERMS_CONDITIONS_WESTFIELD_MEMBERSHIP,
      newTab: false,
    },
    {
      text: 'Privacy Statement',
      url: paths.PRIVACY_POLICY_WESTFIELD_MEMBERSHIP,
      newTab: false,
    },
    {
      text: 'FAQs',
      url: paths.FAQS_WESTFIELD_MEMBERSHIP,
      newTab: true,
    },
  ],
}

export const WestfieldMembershipContainer = ({
  country,
}: PropsType): ReactElement => {
  return (
    <Stack
      shouldWrapChildren={false}
      sx={{ flexGrow: 1, marginTop: ['spacing-lg', null, 0] }}
    >
      {country === 'Australia' && (
        <MembershipColumnLinks
          heading={westfieldMembershipSection.heading}
          links={westfieldMembershipSection.links}
        />
      )}
    </Stack>
  )
}

WestfieldMembershipContainer.propTypes = {
  country: oneOf(['Australia', 'New Zealand']).isRequired,
}
