import React from 'react'
import { Box } from '@hub/box'
import { Link } from '@hub/link'

interface SearchRowProps {
  href?: string
  target?: string
  hitKind: string
  children?: React.ReactNode
  index?: number
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

interface MaybeLinkedProps {
  href?: string
  target?: string
  onClick?: React.MouseEventHandler
}
type BoxProps = React.ComponentProps<typeof Box>
type MaybeLinkedBoxProps = MaybeLinkedProps & BoxProps

const MaybeLinkedBox: React.FC<
  React.PropsWithChildren<MaybeLinkedBoxProps>
> = ({ children, href, target, onClick, ...props }) => {
  if (href) {
    return (
      <Link href={href} target={target} onClick={onClick}>
        <Box {...props}>{children}</Box>
      </Link>
    )
  } else {
    return (
      <Box onClick={onClick} {...props}>
        {children}
      </Box>
    )
  }
}

const dataTestId = (hitKind: string, index?: number): string =>
  `search-row-${hitKind}${index === undefined ? '' : `-${index}`}`

const SearchRow: React.FC<React.PropsWithChildren<SearchRowProps>> = ({
  href,
  target,
  hitKind,
  children,
  onClick,
  index,
}) => (
  <MaybeLinkedBox
    data-test-id={dataTestId(hitKind, index)}
    href={href}
    target={target}
    onClick={onClick}
    sx={{
      fontSize: 'font-lg',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      marginX: 'spacing-none',
      marginY: 'spacing-md',
    }}
  >
    {children}
  </MaybeLinkedBox>
)

export default SearchRow
