import React from 'react'
import { Portal as ChakraPortal } from '@chakra-ui/react'
import { forwardRef } from '@hub/design-system-base'
import { ErrorBoundary } from './error-boundary'

export interface PortalProps {
  containerRef?: React.RefObject<HTMLElement | null>
  appendToParentPortal?: boolean
}

// ref needs to be passed in for compatability but
// because portal doesn't have a DOM element we just throw it away
const PortalWithoutErrorBoundary = forwardRef<PortalProps, typeof ChakraPortal>(
  ({ containerRef, appendToParentPortal = false, children }, _ref) => (
    <ChakraPortal
      containerRef={containerRef}
      appendToParentPortal={appendToParentPortal}
    >
      {children}
    </ChakraPortal>
  )
)

const PortalWithErrorBoundary = forwardRef<PortalProps, typeof ChakraPortal>(
  ({ containerRef, appendToParentPortal = false, children }, _ref) => (
    <ErrorBoundary fallback={children}>
      <ChakraPortal
        containerRef={containerRef}
        appendToParentPortal={appendToParentPortal}
      >
        {children}
      </ChakraPortal>
    </ErrorBoundary>
  )
)

// workaround ChakraPortal throwing an error in the test runner by catching
// and rendering the children instead
export const Portal =
  process.env.NODE_ENV === 'test'
    ? PortalWithErrorBoundary
    : PortalWithoutErrorBoundary
