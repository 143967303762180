import { AccordionItem } from '@hub/accordion'
import React from 'react'
import { NavigationButton } from './navigation-button'
import {
  NavigationContextProvider,
  useNavigationContext,
} from './navigation-context'
import { NavigationPanel } from './navigation-panel'
import { useManagedChildren } from './use-managed-children'

export const NavigationPanelMenu: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const context = useNavigationContext()
  const { panelLayout, onClose } = context
  const parentRegisterChild = context.registerChild

  const [activeChild, registerChild] = useManagedChildren()

  const [isOpen, setIsOpen] = React.useState(false)
  const symbol = React.useRef(Symbol())
  React.useEffect(() => {
    parentRegisterChild?.(symbol.current, isOpen)
  }, [isOpen, parentRegisterChild])
  React.useEffect(() => {
    if (context.activeChild !== symbol.current) {
      setIsOpen(false)
    }
  }, [context.activeChild])
  const handleClose = React.useCallback(
    (recursive?: boolean) => {
      setIsOpen(false)
      if (recursive) {
        onClose?.(recursive)
      }
    },
    [onClose]
  )
  const items = React.useMemo(
    () =>
      React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
          return child
        }
        if (child.type === NavigationButton) {
          return (
            <NavigationContextProvider
              value={{
                activeChild,
                registerChild,
                isPanel: true,
                isOpen,
                onClick() {
                  setIsOpen(isOpen => !isOpen)
                },
              }}
            >
              {child}
            </NavigationContextProvider>
          )
        }
        if (child.type === NavigationPanel) {
          return (
            <NavigationContextProvider
              value={{
                activeChild,
                registerChild,
                isOpen,
                onClose: handleClose,
              }}
            >
              {child}
            </NavigationContextProvider>
          )
        }

        return child
      }),
    [activeChild, children, handleClose, isOpen, registerChild]
  )
  const MaybeAccordionItem =
    panelLayout === 'accordion' ? AccordionItem : React.Fragment

  return <MaybeAccordionItem>{items}</MaybeAccordionItem>
}
