import React from 'react'

export const getScrollbarWidth = (): number => {
  const documentWidth = document.documentElement.clientWidth
  const scrollbarWidth = Math.abs(window.innerWidth - documentWidth)
  return scrollbarWidth
}

const toggleScroll = (isOpen: boolean): number => {
  const scrollbarWidth = getScrollbarWidth()
  if (isOpen) {
    document.body.style.paddingRight = `${scrollbarWidth}px`
    document.body.style.overflow = 'hidden'
    return scrollbarWidth
  } else {
    document.body.style.removeProperty('overflow')
    document.body.style.removeProperty('padding-right')
    return scrollbarWidth
  }
}

export const useNoBodyScroll = (isOpen: boolean): number => {
  const [scrollbarWidth, setScrollbarWidth] = React.useState(0)
  React.useEffect(() => {
    setScrollbarWidth(getScrollbarWidth())
  }, [])

  React.useEffect(() => {
    toggleScroll(isOpen)
    if (!isOpen) {
      setScrollbarWidth(getScrollbarWidth())
    }
    return () => {
      toggleScroll(false)
    }
  }, [isOpen])
  return scrollbarWidth
}
