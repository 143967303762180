import React from 'react'
import SearchResult from '../search-result'
import {
  Segment,
  SegmentedHit,
  SegmentRender,
  RenderProps,
  HitType,
  WestfieldProductHit,
  NonProductSegmentedHit,
} from '../types'
import WestfieldProductCard from '../../westfield-product-card'
import getUrlForHit from '../get-url-for-hit'
import { FieldOptions, getFieldForSegment } from '../display-utils'

const getCommonProps = ({
  hit,
  onResultClick,
  index,
  context,
}: RenderProps & { hit: NonProductSegmentedHit }): {
  hit: Exclude<HitType, WestfieldProductHit>
  key: string
  index: number
  onClick: () => void
  href: string
  highlightField: FieldOptions
} => {
  const key = hit.objectID
  const onClick = (): void => onResultClick(hit)
  const href = getUrlForHit(hit, context)
  const highlightField = getFieldForSegment(hit.segment)
  return { hit, key, index, onClick, href, highlightField }
}

const renderNationalStorefront: SegmentRender<Segment.nationalStorefronts> = ({
  hit,
  onResultClick,
  index,
  context,
}) => {
  return (
    <SearchResult
      {...getCommonProps({ hit, onResultClick, index, context })}
      imageUrl={hit.img}
      hitKind="nationalStorefront"
      description={'All stores'}
      isStoreRow
      imageBgSize="contain"
    />
  )
}

const renderStore: SegmentRender<Segment.stores> = ({
  hit,
  onResultClick,
  context,
  index,
}) =>
  context.centre ? (
    <SearchResult
      {...getCommonProps({ hit, onResultClick, index, context })}
      imageUrl={hit.img}
      hitKind="store"
      description={context.centre.title}
      isStoreRow
      imageBgSize="contain"
    />
  ) : null

const renderSite: SegmentRender<Segment.sites> = ({
  hit,
  onResultClick,
  context,
  index,
}) =>
  context.centre ? (
    <SearchResult
      {...getCommonProps({ hit, onResultClick, index, context })}
      noImage
      hitKind="site"
    />
  ) : null

const renderService: SegmentRender<Segment.services> = ({
  hit,
  onResultClick,
  context,
  index,
}) =>
  context.centre ? (
    <SearchResult
      {...getCommonProps({ hit, onResultClick, index, context })}
      noImage
      hitKind="service"
    />
  ) : null

const renderLocalOffer: SegmentRender<Segment.localOffers> = ({
  hit,
  onResultClick,
  context,
  index,
}) =>
  context.centre ? (
    <SearchResult
      {...getCommonProps({ hit, onResultClick, index, context })}
      imageUrl={hit.img}
      hitKind="offer"
      description={hit.storeTitles.join(', ')}
    />
  ) : null

const renderNationalOffer: SegmentRender<Segment.nationalOffers> = ({
  hit,
  onResultClick,
  context,
  index,
}) => (
  <SearchResult
    {...getCommonProps({ hit, onResultClick, index, context })}
    imageUrl={hit.img}
    hitKind="national-offer"
    description={hit.storeTitles.join(', ')}
  />
)

const renderEvent: SegmentRender<Segment.events> = ({
  hit,
  onResultClick,
  context,
  index,
}) =>
  context.centre ? (
    <SearchResult
      {...getCommonProps({ hit, onResultClick, index, context })}
      imageUrl={hit.img}
      hitKind="event"
      description={hit.shortDescription}
    />
  ) : null

const renderLocalNews: SegmentRender<Segment.localNews> = ({
  hit,
  onResultClick,
  context,
  index,
}) =>
  context.centre ? (
    <SearchResult
      {...getCommonProps({ hit, onResultClick, index, context })}
      imageUrl={hit.img}
      hitKind="local-news"
      description={hit.shortDescription}
    />
  ) : null

const renderNationalNews: SegmentRender<Segment.nationalNews> = ({
  hit,
  onResultClick,
  index,
  context,
}) => (
  <SearchResult
    {...getCommonProps({ hit, onResultClick, index, context })}
    imageUrl={hit.img}
    hitKind="national-news"
    description={hit.shortDescription}
  />
)

const renderLocalStory: SegmentRender<Segment.localStories> = ({
  hit,
  onResultClick,
  context,
  index,
}) =>
  context.centre ? (
    <SearchResult
      {...getCommonProps({ hit, onResultClick, index, context })}
      imageUrl={hit.img}
      hitKind="local-story"
      description={hit.shortDescription}
    />
  ) : null

const renderNationalStory: SegmentRender<Segment.nationalStories> = ({
  hit,
  onResultClick,
  index,
  context,
}) => (
  <SearchResult
    {...getCommonProps({ hit, onResultClick, index, context })}
    imageUrl={hit.img}
    hitKind="national-story"
    description={hit.shortDescription}
  />
)

const renderCentre: SegmentRender<Segment.centres> = ({
  hit,
  onResultClick,
  index,
  context,
}) => (
  <SearchResult
    {...getCommonProps({ hit, onResultClick, index, context })}
    noImage
    hitKind="centre"
    isTextBold={false}
  />
)

const renderOnlineService: SegmentRender<Segment.onlineServices> = ({
  hit,
  onResultClick,
  index,
  context,
}) => (
  <SearchResult
    {...getCommonProps({ hit, onResultClick, index, context })}
    noImage
    hitKind="online-service"
    isTextBold={false}
  />
)

const renderNearCentreStore: SegmentRender<Segment.otherStores> = ({
  hit,
  onResultClick,
  index,
  context,
}) => (
  <SearchResult
    {...getCommonProps({ hit, onResultClick, index, context })}
    noImage
    hitKind="other-store"
    description={hit.centreName}
  />
)

const renderWestfieldProduct: SegmentRender<Segment.westfieldProducts> = ({
  hit,
}) => <WestfieldProductCard key={hit.objectID} westfieldProduct={hit} />

const getRenderForSegment: React.FC<
  React.PropsWithChildren<RenderProps & { hit: SegmentedHit }>
> = ({ hit, ...rest }) => {
  switch (hit.segment) {
    case 'nationalStorefronts':
      return renderNationalStorefront({ hit, ...rest })
    case 'stores':
      return renderStore({ hit, ...rest })
    case 'sites':
      return renderSite({ hit, ...rest })
    case 'services':
      return renderService({ hit, ...rest })
    case 'localOffers':
      return renderLocalOffer({ hit, ...rest })
    case 'nationalOffers':
      return renderNationalOffer({ hit, ...rest })
    case 'events':
      return renderEvent({ hit, ...rest })
    case 'localNews':
      return renderLocalNews({ hit, ...rest })
    case 'nationalNews':
      return renderNationalNews({ hit, ...rest })
    case 'localStories':
      return renderLocalStory({ hit, ...rest })
    case 'nationalStories':
      return renderNationalStory({ hit, ...rest })
    case 'centres':
      return renderCentre({ hit, ...rest })
    case 'onlineServices':
      return renderOnlineService({ hit, ...rest })
    case 'otherStores':
      return renderNearCentreStore({ hit, ...rest })
    case 'westfieldProducts':
      return renderWestfieldProduct({ hit, ...rest })
    default:
      throw new Error(`Unknown segment ${hit.segment}`)
  }
}

// TODO rename this file and export
export default getRenderForSegment
