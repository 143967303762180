import { AlgoliaApiKeys } from './types'
import { makeClients } from './algolia-helpers'
import { Clients } from './query-segments'
import useOnce from '@scentregroup/shared/hooks/use-once'

export function useAlgoliaClients(
  algoliaApiKeys: AlgoliaApiKeys
): Clients | undefined {
  return useOnce(() => makeClients(algoliaApiKeys))
}
