import React from 'react'

interface VideoProps {
  url: string
}

export const Video: React.FC<React.PropsWithChildren<VideoProps>> = ({
  url,
}): JSX.Element => {
  return (
    <video playsInline autoPlay loop muted controls={false}>
      <source type="video/mp4" src={url} />
    </video>
  )
}
