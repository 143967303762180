import { createAutocomplete } from '@algolia/autocomplete-core'
import React, { useContext } from 'react'
import {
  SearchModalAutocomplete,
  GetSources,
  useSyncState,
  AutocompleteWithMaybeState,
  BaseItem,
  ResetState,
  SpecialSearchKind,
  PickedReshape,
} from '../../../components/search'
import { CentreContext } from '../../../context'
import { useIsRouteChanging } from '../../../next/router'

type HeaderSearchProps = {
  isOpen?: boolean
  onClose?: () => void
  getSources: GetSources<BaseItem> | undefined
  country: 'Australia' | 'New Zealand'
  specialSearchKind?: SpecialSearchKind
  onSubmit?: (query: string) => void
  reshape: PickedReshape
}

export const useSearchModalAutocomplete = (
  getSources: GetSources<BaseItem>,
  onSubmit: (query: string) => void,
  reshape: PickedReshape
): [autocomplete: AutocompleteWithMaybeState, resetState: ResetState] => {
  const { autocompleteState, onStateChange, resetState } = useSyncState()
  const [query, setQuery] = React.useState('')
  return React.useMemo(
    () => [
      {
        autocomplete: createAutocomplete({
          getSources,
          onStateChange,
          reshape,
          onSubmit: ({ event }) => {
            event.preventDefault()
            resetState()
            onSubmit(query)
          },
          openOnFocus: true,
        }),
        autocompleteState,
        resetState,
        setNonDebouncedQuery: setQuery,
      },
      resetState,
    ],
    [
      autocompleteState,
      getSources,
      onStateChange,
      onSubmit,
      query,
      resetState,
      reshape,
    ]
  )
}

export const HeaderSearch: React.FC<
  React.PropsWithChildren<HeaderSearchProps>
> = ({
  isOpen = false,
  onClose,
  getSources,
  country,
  specialSearchKind,
  onSubmit,
  reshape,
}) => {
  const centre = useContext(CentreContext)
  const isRouteChanging = useIsRouteChanging()
  const [autocomplete, resetState] = useSearchModalAutocomplete(
    getSources ?? (() => []),
    onSubmit ?? (() => {}),
    reshape ?? (() => {})
  )

  React.useEffect(() => {
    if (isRouteChanging) {
      onClose?.()
      resetState()
    }
  }, [isRouteChanging, onClose, resetState])

  return (
    <SearchModalAutocomplete
      baseContext={{
        centre,
        country,
        user: undefined, // TODO: is it used? Should restrict shape to only fields that are in use
        specialSearchKind,
      }}
      onClickClose={() => {
        onClose?.()
        resetState()
      }}
      autocompleteWithState={autocomplete}
      isOpen={isOpen}
      backgroundColor="surfaceBrandPrimary"
    />
  )
}
