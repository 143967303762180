import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { cfTopStripToTopStripProps, ITopStripProps } from './types'
import { GetTopStripDocument } from '../../graphql'

async function getTopStripProps(
  client: ApolloClient<NormalizedCacheObject>,
  country: 'Australia' | 'New Zealand',
  centre?: string
): Promise<ITopStripProps | null> {
  const isCentre = Boolean(centre)
  const { data } = await client.query({
    query: GetTopStripDocument,
    variables: {
      country,
      isCentre,
    },
  })

  if (!data.cfTopStripCollection) {
    return null
  }

  const cfTopStrip = data.cfTopStripCollection.items.find(item => {
    if (!item) {
      return false
    }
    if (
      isCentre &&
      item.centresCollection?.items.find(item => item?.slug === centre)
    ) {
      return true
    }
    return !isCentre
  })

  if (!cfTopStrip && isCentre) {
    return getTopStripProps(client, country)
  }

  if (!cfTopStrip) {
    return null
  }

  return cfTopStripToTopStripProps(cfTopStrip)
}

export default getTopStripProps
