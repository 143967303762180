import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@hub/breadcrumb'
import { CoreContainer } from '@hub/core-container'
import { Stack } from '@hub/stack'
import React from 'react'

type HeaderBreadcrumbProps = {
  items?: { label: string; url?: string }[]
}

export const HeaderBreadcrumb: React.FC<HeaderBreadcrumbProps> = ({
  items,
}) => {
  if (!Array.isArray(items)) {
    return null
  }

  return (
    <CoreContainer sx={{ marginTop: ['spacing-md', null, 'spacing-lg'] }}>
      <Stack minHeight={48} justify="center">
        <Breadcrumb>
          {items.map(({ label, url }, i) => {
            const isCurrentPage = i === items.length - 1
            return (
              <BreadcrumbItem isCurrentPage={isCurrentPage} key={i}>
                <BreadcrumbLink href={url}>{label}</BreadcrumbLink>
              </BreadcrumbItem>
            )
          })}
        </Breadcrumb>
      </Stack>
    </CoreContainer>
  )
}
