import React from 'react'

export const useManagedChildren = (): [
  activeChild: symbol | undefined,
  registerChild: (child: symbol, isOpen: boolean) => void,
  closeAll: () => void,
] => {
  const [{ activeChild }, dispatchRegisterChild] = React.useReducer(
    (
      state: {
        activeChild: symbol | undefined
        registeredChildren: [child: symbol, isOpen: boolean][]
      },
      payload: { child: symbol; isOpen: boolean } | boolean
    ) => {
      if (typeof payload === 'boolean') {
        const registeredChildren: [child: symbol, isOpen: boolean][] =
          state.registeredChildren.map(([child]) => [child, false])
        return {
          activeChild: undefined,
          registeredChildren,
        }
      } else {
        const { child, isOpen } = payload
        const registeredChildren: [child: symbol, isOpen: boolean][] = [
          ...state.registeredChildren
            .filter(([r]) => r !== child)
            .map(
              ([r, o]) =>
                [r, isOpen ? false : o] as [child: symbol, isOpen: boolean]
            ),
          [child, isOpen],
        ]
        const activeChild = registeredChildren.find(([, isOpen]) => isOpen)?.[0]
        return {
          activeChild,
          registeredChildren,
        }
      }
    },
    { activeChild: undefined, registeredChildren: [] }
  )

  const registerChild = React.useCallback((child: symbol, isOpen: boolean) => {
    dispatchRegisterChild({ child, isOpen })
  }, [])
  const closeAll = React.useCallback(() => {
    dispatchRegisterChild(false)
  }, [])

  return [activeChild, registerChild, closeAll]
}
