import { Button } from '@hub/button'
import { SearchMediumIcon } from '@hub/icon'
import React from 'react'
import { useHeaderProps } from './header'

export const HeaderSearchButton: React.FC = () => {
  const { onClickSearch: onClick } = useHeaderProps()
  return (
    <Button
      variant="searchBar"
      colorScheme="smoke"
      size="icon-lg"
      width="size-full"
      maxWidth={[null, null, 'size-24']}
      leftIcon={<SearchMediumIcon boxSize="size-icon-sm" />}
      onClick={onClick}
    >
      Search all of Westfield
    </Button>
  )
}
