import React, { ReactElement } from 'react'
import { Box } from '@hub/box'
import { kebabCase } from 'lodash'
import * as paths from './paths'
import { LinkSection, LinkSectionPropsType } from './link-section'
import { GIFT_CARDS } from '../../paths'
import { trackUniversalFooterLinkClick } from './analytics'

const giftCards: LinkSectionPropsType = {
  heading: { text: 'Westfield Gift Cards', url: GIFT_CARDS },
  links: [
    {
      text: 'Digital Gift Card',
      url: paths.GIFT_CARDS_DIGITAL_RETAIL,
    },
    {
      text: 'Gift Card',
      url: paths.GIFT_CARDS_PHYSICAL_RETAIL,
      newTab: true,
      links: [
        {
          text: 'Terms and Conditions',
          url: paths.TERMS_CONDITIONS,
          newTab: true,
        },
      ],
    },
    {
      text: 'Corporate',
      url: paths.GIFT_CARDS_PHYSICAL_CORPORATE,
      newTab: true,
    },
  ],
}

const westfieldOnline: LinkSectionPropsType = {
  heading: { text: 'Online shopping', url: paths.WESTFIELD_ONLINE },
  links: [
    {
      text: 'Terms and Conditions',
      url: paths.TERMS_CONDITIONS_WESTFIELD_ONLINE,
      newTab: false,
    },
    {
      text: 'Privacy Statement',
      url: paths.PRIVACY_POLICY_WESTFIELD_ONLINE,
      newTab: false,
    },
    { text: 'FAQs', url: paths.FAQS, newTab: true },
  ],
}

export const LeftColumnLinks = (): ReactElement => (
  <>
    <Box
      as="nav"
      aria-labelledby={kebabCase(westfieldOnline.heading.text)}
      sx={{
        flexGrow: 1,
      }}
    >
      <LinkSection
        heading={westfieldOnline.heading}
        links={westfieldOnline.links}
        trackers={{ clicked: trackUniversalFooterLinkClick }}
      />
    </Box>

    <Box
      as="nav"
      aria-labelledby={kebabCase(giftCards.heading.text)}
      sx={{
        flexGrow: 1,
      }}
    >
      <LinkSection
        heading={giftCards.heading}
        links={giftCards.links}
        trackers={{ clicked: trackUniversalFooterLinkClick }}
      />
    </Box>
  </>
)
