import React from 'react'
import { kebabCase } from 'lodash'
import { Stack } from '@hub/stack'
import { LinkSection } from './link-section'
import { CentreDetails } from './centre-details'
import * as paths from './paths'
import { trackUniversalFooterLinkClick } from './analytics'
import { LeftColumnLinks } from './left-column-links'
import useFeatureFlag from '../../hooks/use-feature-flag'
import { FeatureFlagName } from '../../feature-flags'
import { MagicLinkValue, useMagicLink } from '../../hub-components/magic-link'

const customerSupport = {
  text: 'Customer Support',
  url: paths.CUSTOMER_SUPPORT,
}

const LeftColumnContainerFlexGrowDesktop = 3

export const LeftColumnContainer: React.FC<
  React.PropsWithChildren<{
    centre?: React.ComponentProps<typeof CentreDetails>['centre']
    country: string
    onFindACentre?: () => void
  }>
> = ({ centre, country, onFindACentre }) => {
  const storeDirectoryEnabled = useFeatureFlag(
    FeatureFlagName.NEXT_PUBLIC_FEAT_FLAG_ENABLE_STORE_DIRECTORY
  )
  const [, setMagicLink] = useMagicLink()

  const handleStoreMagicLink = (): void => {
    setMagicLink(MagicLinkValue.stores)
  }

  return (
    <Stack
      direction={['column', 'column', 'column', 'row']}
      shouldWrapChildren={false}
      gap={['spacing-lg', 'spacing-xl', 'spacing-xl', '0']}
      sx={{ flexGrow: [1, 1, 1, LeftColumnContainerFlexGrowDesktop] }}
      flexGrow={0}
    >
      <Stack
        as="nav"
        aria-label="Footer Links"
        shouldWrapChildren={false}
        sx={{
          flexGrow: 1,
        }}
      >
        {centre ? (
          <CentreDetails centre={centre} onFindACentre={onFindACentre} />
        ) : (
          onFindACentre && (
            <LinkSection
              heading={{ text: 'Find a centre', onClick: onFindACentre }}
              trackers={{ clicked: trackUniversalFooterLinkClick }}
            />
          )
        )}

        {!centre && !storeDirectoryEnabled && (
          <LinkSection
            heading={{ text: 'Find a store', onClick: handleStoreMagicLink }}
            trackers={{ clicked: trackUniversalFooterLinkClick }}
          />
        )}

        {!centre && storeDirectoryEnabled && (
          <LinkSection
            heading={{ text: 'Find a store', url: '/stores' }}
            trackers={{ clicked: trackUniversalFooterLinkClick }}
          />
        )}

        {country === 'Australia' && (
          <LinkSection
            heading={customerSupport}
            trackers={{ clicked: trackUniversalFooterLinkClick }}
            aria-labelledby={kebabCase(customerSupport.text)}
          />
        )}
      </Stack>
      {country === 'Australia' && <LeftColumnLinks />}
    </Stack>
  )
}
