import React, { useState } from 'react'

import { Box } from '@hub/box'
import { H4 } from '@hub/heading'
import { CentreListItem } from '../filtered-centre-list'
import { CentrePicker } from '../centre-picker'

interface FindACentreBannerProps {
  searchTerm: string
  centres: CentreListItem['centre'][]
}

export const FindACentreBanner: React.FC<
  React.PropsWithChildren<FindACentreBannerProps>
> = ({ centres, searchTerm }) => {
  const items = centres.map(centre => ({
    centre,
    label: centre.title,
    url: `/${centre.slug}/search?q=${searchTerm}`,
  }))
  const heading = `Looking for '${searchTerm}' in a Westfield Centre?`
  const [query, setQuery] = useState('')
  return (
    <Box sx={{ width: 'size-full', padding: 0 }}>
      <Box
        sx={{
          paddingY: 'spacing-2xl',
          maxWidth: 'size-24',
        }}
      >
        <H4 sx={{ paddingBottom: 'spacing-lg' }}>{heading}</H4>
        <CentrePicker
          items={items}
          query={query}
          onChange={setQuery}
          onBlur={() => setQuery('')}
        />
      </Box>
    </Box>
  )
}
