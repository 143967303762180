import { mapValues } from 'lodash'

import {
  Segment,
  GroupAndRenderSegmentsArgs,
  HitsGroup,
  OnResultClick,
  SegmentedHit,
  SegmentHits,
  RenderContext,
  GroupedHit,
} from '../types'

type GetHeadingForSegment = (segment: Segment) => string

interface ExtraArgs {
  headingsToDisplay: string[]
  getHeadingForSegment: GetHeadingForSegment
}

type SectionResult = {
  hits: SegmentedHit[]
  queryId?: string
  indexName?: string
}

type ResultsByHeadings = Record<string, SectionResult>

function makeResultsByHeadings(
  segmentResults: SegmentHits,
  getHeadingForSegment: GetHeadingForSegment
): ResultsByHeadings {
  const resultsByHeadings: ResultsByHeadings = {}
  ;(Object.keys(segmentResults) as Segment[]).forEach(segment => {
    const hits = segmentResults[segment]?.hits
    // N.B I suspect the following two lines are bugged in the
    // sense that the section under a heading can have multiple
    // segments and thus multiple queryId/indexName but we're only
    // capturing one - but I'm leaving that as a separate issue to
    // fix later.
    const queryId = segmentResults[segment]?.queryId ?? ''
    const indexName = segmentResults[segment]?.indexName
    if (hits) {
      const heading = getHeadingForSegment(segment)
      resultsByHeadings[heading] = resultsByHeadings[heading] ?? {
        hits: [],
      }
      resultsByHeadings[heading].hits.push(...hits)
      resultsByHeadings[heading].queryId = queryId
      resultsByHeadings[heading].indexName = indexName
    }
  })
  return resultsByHeadings
}

function makeGroupedHitsByHeading(
  resultsByHeadings: ResultsByHeadings,
  context: RenderContext,
  onResultClickHeading: (heading: string, hit: SegmentedHit) => void
): Record<string, GroupedHit[]> {
  return mapValues(resultsByHeadings, (result, heading) => {
    const onResultClick: OnResultClick = hit =>
      onResultClickHeading(heading, hit)
    return result.hits.map(hit => ({
      hit,
      onResultClick,
      context,
    }))
  })
}

export default function groupSegmentsByHeading({
  segmentResults,
  context,
  onResultClickHeading,
  headingsToDisplay,
  getHeadingForSegment,
}: GroupAndRenderSegmentsArgs & ExtraArgs): HitsGroup[] {
  const resultsByHeadings = makeResultsByHeadings(
    segmentResults,
    getHeadingForSegment
  )
  const groupedHitsByHeading = makeGroupedHitsByHeading(
    resultsByHeadings,
    context,
    onResultClickHeading
  )
  return headingsToDisplay
    .filter(
      heading =>
        groupedHitsByHeading[heading] &&
        groupedHitsByHeading[heading].length > 0
    )
    .map(heading => ({
      indexName: resultsByHeadings[heading]?.indexName ?? '',
      queryId: resultsByHeadings[heading]?.queryId ?? '',
      heading,
      groupedHits: groupedHitsByHeading[heading],
    }))
}
