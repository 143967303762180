import React from 'react'
import { Icon as ChakraIcon } from '@chakra-ui/react'
import {
  HubResponsiveValue,
  StandardIconSizes,
  HubStyleObject,
  forwardRef,
} from '@hub/design-system-base'
import { default as WestfieldLogoSVG } from './assets/westfield-logo.inline.svg'

interface WestfieldLogoProps {
  boxSize?: HubResponsiveValue<StandardIconSizes>
  sx?: HubStyleObject
  label?: string
}

export const WestfieldLogo: React.FC<React.PropsWithChildren<WestfieldLogoProps>> = forwardRef<
  WestfieldLogoProps,
  typeof ChakraIcon
>(
  (
    { boxSize = 'size-icon-line-height-shorter', sx, label = 'Westfield' },
    ref
  ) => (
    <ChakraIcon
      aria-label={label}
      ref={ref}
      minW={boxSize}
      maxH={boxSize}
      w="size-auto"
      h="size-auto"
      as={WestfieldLogoSVG}
      sx={sx}
    />
  )
)
