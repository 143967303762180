import products from './products'
import canonical from './canonical'
export { completelyCanonicalUrl } from './completely-canonical-url'

const canonicalLink = {
  products,
  canonical,
}

export default canonicalLink
