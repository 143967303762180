import React, { useContext } from 'react'
import { CoreContainer } from '@hub/core-container'
import { Box } from '@hub/box'
import { Grid } from '@hub/grid'
import { Text } from '@hub/text'
import { As } from '@hub/design-system-base'
import { CloudinaryImage } from '../cloudinary-image'
import { SGPageTemplateContentEntry } from '@scentregroup/shared/types/page-templates'
import { TrackBox } from '../../analytics-observer'
import TileLink from '../tile-list/tile-link'
import analyticsUtils from '../../helpers/analytics-utils'
import trackClickEvent from '../../helpers/analytics-utils/track-event'
import { CentreContext } from '../../context'

export type UtilityPanelItem = SGPageTemplateContentEntry & {
  as?: As
}

type Props = {
  items: UtilityPanelItem[]
  as?: As
  clickEvent?: (index: number) => void
}

function UtilityPanel({ items, as, clickEvent }: Props): JSX.Element {
  const iconHeight = 'size-icon-md'
  // This approach will be more robust than a prop in the event
  // this component gets reused outside of centre homepages
  const isCentre = !!useContext(CentreContext)
  return (
    <CoreContainer as={as}>
      <Box>
        <Grid
          rowGap="spacing-sm"
          columnGap={['spacing-sm', null, 'spacing-md']}
          sx={{
            justifyContent: 'center',
            alignItems: 'stretch',
            gridAutoRows: '1fr',
            justifyItems: 'stretch',
            ['@media screen and (min-width: 664px)']: {
              gridTemplateColumns: 'repeat(4, 148px)',
            },
            ['@media screen and (max-width: 663px)']: {
              gridTemplateColumns: 'repeat(2, 171px)',
            },
            ['@media screen and (max-width: 389px)']: {
              gridTemplateColumns:
                'repeat(2, calc((100% - var(--chakra-space-spacing-sm))/2))',
            },
            ['@media screen and (max-width: 343px)']: {
              gridTemplateColumns: '171px', // 1-col override for tiny screens
            },
          }}
        >
          {items.slice(0, 4).map((entry, i) => (
            <TrackBox key={i}>
              <TileLink
                url={entry.callToAction.url}
                as={entry.as}
                isLastChild={items.length - 1 === i}
                onClick={() => {
                  clickEvent?.(i)
                  const { categories, actions } = analyticsUtils
                  const { UTILITY_PANEL } = categories
                  const { HOMEPAGE_CLICK, CENTRE_CLICK } = actions
                  const action = isCentre ? CENTRE_CLICK : HOMEPAGE_CLICK
                  trackClickEvent(UTILITY_PANEL, action, entry.title)
                }}
                backgroundColor="surfaceBrandSecondary"
              >
                <CloudinaryImage
                  ratio="1"
                  imageSetOrImage={{
                    type: 'SQUARE',
                    url: entry.image.url,
                    // SGA11y: Image elements have [alt] attributes that are redundant text.
                    // Alternative text of images should not be repeated as text
                    alt: entry.image.alt || '',
                  }}
                  sx={{ boxSize: iconHeight }}
                  sizes={24}
                />
                <Text
                  isBodyText
                  align="center"
                  tone="strong"
                  sx={{ marginTop: 'spacing-sm' }}
                >
                  {entry.title}
                </Text>
              </TileLink>
            </TrackBox>
          ))}
        </Grid>
      </Box>
    </CoreContainer>
  )
}

export default UtilityPanel
