const CENTRE = 'centre'
const ID = 'id'
const SLUG = 'slug'
const CATEGORY = 'category'
const TEMPLATE_PATH = 'templatePath'
const LEGACYIDENTIFIER = 'legacyIdentifier'
const ENTRYID = 'entryId'
const PRODUCT_ID = 'productId'
const DATE = 'date'
const STORE_CAT = 'storeCat'
const STORE_SUBCAT = 'storeSubCat'

const HOMEPAGE = '/'
const TERMS_AND_CONDITIONS = '/terms-and-conditions'
const PRIVACY_POLICY = '/privacy-policy'
const FREE_WIFI = `/free-wifi`
const PRODUCTS = '/products'
const GIFT_CARDS = '/gift-cards'
const DIGITAL_GIFT_CARD = '/shop/westfield-digital-gift-card'
const PRODUCT_COLLECTIONS_LIST = '/shop/collections'

const BROWSE_STORES = `/:${CENTRE}/browse-stores`
const BROWSE_STORES_WITH_CAT = `/:${CENTRE}/browse-stores/:${STORE_CAT}/:${STORE_SUBCAT}`
const OPENING_HOURS = `/:${CENTRE}/opening-hours`
const CENTRE_MAP = `/:${CENTRE}/centre-map/:${LEGACYIDENTIFIER}?/:${SLUG}?`
const PARKING = `/:${CENTRE}/parking`
const STORE = `/:${CENTRE}/store/:${ID}/:${SLUG}`
const WHATS_HAPPENING = `/:${CENTRE}/whats-happening/:${CATEGORY}?`
const EVENT = `/:${CENTRE}/event/:${ID}/:${SLUG}`
const NEWS = `/:${CENTRE}/news/:${ID}/:${SLUG}`
const OFFER = `/:${CENTRE}/offer/:${ID}/:${SLUG}`
const STORY = `/:${CENTRE}/story/:${ID}/:${SLUG}`
const SERVICE = `/:${CENTRE}/service/:${ID}/:${SLUG}`
const MOVIES = `/:${CENTRE}/movies`
const MOVIE_DETAILS = `/:${CENTRE}/movie/:${ID}/:${SLUG}/:${DATE}?`
const TEMPLATE = `/:${CENTRE}/:${TEMPLATE_PATH}`
const DINING = `/:${CENTRE}/dining`
const OLYMPICS = '/olympics'
const PARALYMPICS = '/paralympic-games'

// Account related
const LOGIN = '/login'
const LOGOUT = '/logout'

module.exports = {
  HOMEPAGE,
  CENTRE,
  ID,
  SLUG,
  CATEGORY,
  TEMPLATE_PATH,
  LEGACYIDENTIFIER,
  ENTRYID,
  PRODUCT_ID,
  DATE,
  STORE_CAT,
  STORE_SUBCAT,
  TERMS_AND_CONDITIONS,
  PRIVACY_POLICY,
  FREE_WIFI,
  PRODUCTS,
  GIFT_CARDS,
  DIGITAL_GIFT_CARD,
  PRODUCT_COLLECTIONS_LIST,
  BROWSE_STORES,
  BROWSE_STORES_WITH_CAT,
  OPENING_HOURS,
  CENTRE_MAP,
  PARKING,
  STORE,
  WHATS_HAPPENING,
  EVENT,
  NEWS,
  OFFER,
  STORY,
  SERVICE,
  MOVIES,
  MOVIE_DETAILS,
  TEMPLATE,
  DINING,
  LOGIN,
  LOGOUT,
  OLYMPICS,
  PARALYMPICS,
}
