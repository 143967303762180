import {
  useTrackComponentSimplified as useTrackComponent,
  TrackComponentArgsSimplified as TrackComponentArgs,
} from '@scentregroup/analytics'
import { LinkBox } from '@hub/link'
import React, { useImperativeHandle } from 'react'
import { forwardRef } from '@hub/design-system-base'

type TrackLinkBoxProps = TrackComponentArgs &
  React.ComponentProps<typeof LinkBox>

export const TrackLinkBox = forwardRef<TrackLinkBoxProps, typeof LinkBox>(
  (
    {
      onCreateImpressionWhenView,
      onImpressionTrackEvent,
      onClickTrackEvent,
      onImpressionTrackEventOnce,
      ...linkBoxProps
    },
    ref
  ) => {
    const { inViewRef, onClick: onClickTrack } = useTrackComponent({
      onCreateImpressionWhenView,
      onImpressionTrackEvent,
      onClickTrackEvent,
      onImpressionTrackEventOnce,
    })
    useImperativeHandle(ref, () => ({
      focus: () => {
        inViewRef()
      },
    }))
    const onClick: React.MouseEventHandler = e => {
      linkBoxProps.onClick?.(e)
      onClickTrack(e)
    }
    return <LinkBox {...linkBoxProps} onClick={onClick} ref={inViewRef} />
  }
)
