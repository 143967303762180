import React from 'react'
import {
  AccordionButton as ChakraAccordionButton,
  AccordionButtonProps,
} from '@chakra-ui/react'

export const AccordionButton: React.FC<
  React.PropsWithChildren<
    Pick<AccordionButtonProps, 'as' | 'sx' | 'children' | 'onClick'>
  >
> = props => <ChakraAccordionButton {...props} />
