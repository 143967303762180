import React, { KeyboardEvent } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@hub/input'
import { FormLabel } from '@hub/form'
import { SearchMediumIcon } from '@hub/icon'
import { Box } from '@hub/box'

import { Auto } from '../types'
import ClearButton from './clear-button'
import type { RefsAndCursor } from './use-refs-and-cursor'
import QuitButton from './quit-button'

interface SearchInputProps {
  autocomplete: Auto
  refsAndCursor: RefsAndCursor
  query?: string
  placeholder?: string
  clearQuery: () => void
  onClickClose: () => void
  reinitialiseQuery: () => void
  setNonDebouncedQuery: (query: string) => void
}

const DEBOUNCE_TIME = 300

function useDebouncedHandler<Args extends any[]>(
  handler: (...args: Args) => void
): (...args: Args) => void {
  return useDebouncedCallback(handler, DEBOUNCE_TIME, { leading: true })
}

const SearchInput: React.FC<React.PropsWithChildren<SearchInputProps>> = ({
  autocomplete,
  placeholder,
  query,
  refsAndCursor,
  clearQuery,
  onClickClose,
  reinitialiseQuery,
  setNonDebouncedQuery,
}) => {
  const elems = refsAndCursor.autocompleteRefs.getElems()
  // If you leave value in there it wipes the field on every state change
  const {
    value: _value,
    onChange: rawOnChange,
    ...inputProps
  } = autocomplete.getInputProps(elems)
  // We debounce at the level of keystrokes rather than in useGetSources because doing
  // it there is an absolute pain that I can tell you about over a strong drink some time.
  const debouncedOnChange = useDebouncedHandler(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // Unfortunately autocomplete-core looks for e.currentTarget.value which stops
      // existing in a setTimeout or some nonsense, so we need to spoof it.
      const currentTarget = { value: e.target.value }
      rawOnChange?.({ currentTarget } as React.ChangeEvent<HTMLInputElement>)
    }
  )
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedOnChange(e)
    setNonDebouncedQuery(e.target.value)
  }

  // onClick also triggers search API calls so we debounce it too.
  const onClick = useDebouncedHandler(
    (e: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
      reinitialiseQuery()
      inputProps?.onClick?.(e)
    }
  )
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
      }}
    >
      <InputGroup
        sx={{
          background: 'surfaceTertiary',
          paddingLeft: 'spacing-md',
          paddingY: 'spacing-sm',
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: 'borderSecondary',
          height: 'size-8',
        }}
      >
        <InputLeftElement pointerEvents={'none'}>
          <FormLabel
            {...autocomplete.getLabelProps(elems)}
            htmlFor="search-textfield-new"
          >
            <SearchMediumIcon
              sx={{
                cursor: 'pointer',
                opacity: '1',
                stroke: 'licorice',
                marginLeft: 'spacing-3',
                marginTop: '14px',
              }}
            />
          </FormLabel>
        </InputLeftElement>
        <Input
          {...inputProps}
          id="search-textfield-new"
          ref={refsAndCursor.autocompleteRefs.inputRef}
          placeholder={placeholder}
          sx={{
            width: '414px',
            margin: 'auto 24px',
            height: '100%',
            padding: 'spacing-none',
            '-webkit-appearance': 'none',
            color: 'textSecondary',
            border: 'none',
            '&::-webkit-search-cancel-button': {
              '-webkit-appearance': 'none',
            },
            '&::-ms-clear': {
              display: 'none',
            },
            '&:focus, &:active': {
              outline: 'none',
            },
          }}
          _placeholder={{
            color: 'quartz',
          }}
          onChange={onChange}
          onClick={onClick}
          onFocus={reinitialiseQuery}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'ArrowDown') {
              refsAndCursor.moveDown()
              e.preventDefault()
            }
          }}
        />
        <InputRightElement sx={{ top: 'unset' }}>
          <ClearButton clearQuery={clearQuery} query={query} />
        </InputRightElement>
      </InputGroup>
      <QuitButton
        sx={{
          display: ['none', 'none', 'initial'],
        }}
        onClickClose={onClickClose}
      />
    </Box>
  )
}

export default SearchInput
