import React from 'react'
import { Portal } from '@hub/portal'
import { Box } from '@hub/box'
import { HubResponsiveValue, StandardColors } from '@hub/design-system-base'
import { OverlayContentContext } from './overlay-content'
import { useSingleOverlay } from './use-single-overlay'
import { useCloseOnEscape } from './use-close-on-escape'
import { useNoBodyScroll } from './use-no-body-scroll'
import { useClickAway } from './use-click-away'
import { useOverlay } from './use-overlay'
import { useContentIsOpen } from './use-content-is-open'

export type Placement = 'top' | 'left' | 'right'

type OverlayProps = {
  isOpen?: boolean
  /**
   * number of seconds for fading in and out when changing `isOpen`
   * default is 0.2s
   */
  fadeDuration?: number
  containerRef?: React.RefObject<HTMLElement | null>
  trigger?: 'click' | 'hover'
  placement?: Placement
  onClose?: () => void
  backgroundColor?: HubResponsiveValue<StandardColors>
}

export const Overlay: React.FC<React.PropsWithChildren<OverlayProps>> = ({
  backgroundColor,
  children,
  containerRef,
  fadeDuration: duration = 0.2,
  placement = 'top',
  trigger,
  isOpen: propsIsOpen,
  onClose,
}) => {
  // allow children to signal opening/closing
  const [isOpen, onOpenContent, onCloseContent] = useContentIsOpen(propsIsOpen)

  // do the complicated stuff to get props for the overlay and gutter
  const [state, ref] = useOverlay(
    duration,
    propsIsOpen,
    placement,
    isOpen,
    containerRef
  )

  // prevent opening two Overlays at once
  useSingleOverlay(isOpen, onClose)

  // capture 'esc' key presess
  useCloseOnEscape(isOpen, onClose)

  // prevent body scrolling beneath an overlay
  const scrollbarWidth = useNoBodyScroll(isOpen)

  // clicking outside the content area will close the overlay
  const clickAwayRef = useClickAway(isOpen, trigger, onClose)

  const backgroundRef = React.useRef<HTMLElement>(null)
  const handleMouseEnter = React.useCallback<React.MouseEventHandler>(
    event => {
      if (trigger !== 'hover') {
        return
      }
      const rect = backgroundRef.current?.getBoundingClientRect()
      if (!rect) {
        return
      }
      if (event.clientY < rect.top) {
        // This introduces a possible way to enter undetected
        // but is here to avoid a problem where the menu closes when
        // moving the cursor from the NavButton to the overlay content.
        // For some reason the background box gets a hover state
        //
        return
      }
      onClose?.()
    },
    [onClose, trigger]
  )

  const handleClose = React.useCallback(() => {
    onClose?.()
  }, [onClose])

  const overlayContentContext = React.useMemo(
    () => ({
      backgroundColor,
      containerRef: containerRef || ref,
      clickAwayRef,
      duration,
      overlayPlacement: placement,
      scrollbarWidth,
      onOpen: onOpenContent,
      onClose: onCloseContent,
    }),
    [
      backgroundColor,
      clickAwayRef,
      containerRef,
      duration,
      onCloseContent,
      onOpenContent,
      placement,
      ref,
      scrollbarWidth,
    ]
  )

  return (
    <>
      {!containerRef && <Box ref={ref} />}
      <Portal>
        {state.gutterProps && (
          <Box
            animation={state.gutterProps.animation}
            sx={state.gutterProps.sx}
            backgroundColor={backgroundColor}
          />
        )}
        <Box
          ref={backgroundRef}
          animation={state.backgroundProps.animation}
          sx={state.backgroundProps.sx}
          onClick={handleClose}
          onMouseEnter={handleMouseEnter}
        />
      </Portal>
      <OverlayContentContext.Provider value={overlayContentContext}>
        {children}
      </OverlayContentContext.Provider>
    </>
  )
}
