import React, { ReactElement, ReactNode, useContext, useMemo } from 'react'
import { LinkContext, FeatureFlagsContext } from '../../context'
import { Link as LinkType } from '@scentregroup/shared/types/link'
import * as paths from './paths'
import { ILink, SiteFooter } from './site-footer'
import { WestfieldMembershipContainer } from './westfield-membership-container'
import { LeftColumnContainer } from './left-column-container'

interface ITaskModeProps {
  taskMode: true
  country: string
  centre?: never
}

interface INormalModeProps {
  taskMode?: false
  Link: LinkType
  centre?: React.ComponentProps<typeof LeftColumnContainer>['centre']
  country: string
  onFindACentre?: () => void
}

type UniversalFooterProps = ITaskModeProps | INormalModeProps

const getChildren = (props: UniversalFooterProps): ReactNode => {
  if (props.taskMode) {
    return null
  }
  const { Link, centre, country, onFindACentre } = props
  return (
    <LinkContext.Provider value={({ ...props }) => <Link {...props} />}>
      <LeftColumnContainer
        centre={centre}
        country={country}
        onFindACentre={onFindACentre}
      />
      <WestfieldMembershipContainer country={country} />
    </LinkContext.Provider>
  )
}

export const UniversalFooter = (props: UniversalFooterProps): ReactElement => {
  const { centre, country } = props
  const isPersonalisedAdsEnabled =
    useContext(FeatureFlagsContext).NEXT_PUBLIC_FEAT_FLAG_PERSONALISED_ADS

  const links: ILink[] = useMemo(
    () => [
      { label: 'Careers', href: paths.CAREERS },
      { label: 'Business Solutions', href: paths.BUSINESS_SOLUTIONS },
      { label: 'Responsible Business', href: paths.RESPONSIBLE_BUSINESS },
      ...(isPersonalisedAdsEnabled
        ? [{ label: 'Personalised Ads', href: paths.PERSONALISED_ADS }]
        : []),
      { label: 'Privacy Policy', href: paths.PRIVACY_POLICY },
      { label: 'Terms and Conditions', href: paths.TERMS_CONDITIONS },
    ],
    [isPersonalisedAdsEnabled]
  )
  return (
    <SiteFooter links={links} country={country} centreSlug={centre?.slug}>
      {getChildren(props)}
    </SiteFooter>
  )
}

UniversalFooter.displayName = 'UniversalFooter'
