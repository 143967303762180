import React, { useContext, createContext, FC, PropsWithChildren } from 'react'

export type Centre = {
  country: string
  latitude: number
  longitude: number
  slug: string
  state: string
  status: string
  suburb: string
  timeZone: string
  title: string
}

const CentresContext = createContext<Centre[] | null>(null)

export const CentresProvider: FC<PropsWithChildren<{ centres: Centre[] }>> = ({
  centres,
  children,
}) => (
  <CentresContext.Provider value={centres}>{children}</CentresContext.Provider>
)

export const useCentres = (): Centre[] => {
  const centres = useContext(CentresContext)

  if (centres === null) {
    throw new Error('useCentres must be used within a <CentresProvider />')
  }

  return centres
}
