import React from 'react'
import { Box } from '@hub/box'
import { RenderContext, SegmentedHit } from './types'
import { TextWithJustHighlights } from './text-with-highlights'
import { StoreMediumIcon } from '@hub/icon'
import { getFieldForSegment, getSupplementaryTextForHit } from './display-utils'

export default function getDisplayNameForHit(hit: SegmentedHit): string {
  const { segment } = hit
  const isProduct = segment === 'westfieldProducts'
  return isProduct ? hit.name : hit.title
}

export const DisplayForHitWithHiglighting: React.FC<
  React.PropsWithChildren<{
    hit: SegmentedHit
    context: RenderContext
  }>
> = ({ hit, context }) => (
  <Box
    sx={{
      '& > em': {
        fontWeight: 'medium',
      },
    }}
  >
    {context.specialSearchKind === 'stores' && context.query.length === 0 && (
      <StoreMediumIcon sx={{ marginRight: 10 }} />
    )}
    <TextWithJustHighlights
      doUseAutocompleteTags
      field={getFieldForSegment(hit.segment)}
      hit={hit as any}
      supplementaryText={getSupplementaryTextForHit(hit)}
    />
  </Box>
)
