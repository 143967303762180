import { standardRatios } from '@hub/frame'
import { clamp } from 'lodash'
import { SGPageTemplateImage } from '@scentregroup/shared/types/page-templates'

const MAX_IMAGE_WIDTH = 1200
const MIN_IMAGE_WIDTH = 100
const URL_PREFIX = 'https://images.scentregroup.io/image/fetch/'
const RESIZE_MODES = {
  'crop-face': 'c_fill,q_auto,g_faces:auto',
  'crop-center': 'c_fill,q_auto,g_center',
  pad: 'c_pad,b_auto',
}
const WIDTH_TOLERANCE = 1.2

/**
 * Specifications here {@link https://cloudinary.com/documentation/transformation_reference#f_format}
 */
export type ImageFormat =
  | `f_auto`
  | `f_auto:${'image' | 'video' | 'animated'}`
  | `f_${string}`
  | 'none'

function getWidth(maxWidth?: number): number {
  const maxResizeWidth =
    typeof window === 'undefined'
      ? maxWidth ?? MAX_IMAGE_WIDTH
      : clamp(
          Math.floor(window.innerWidth * WIDTH_TOLERANCE),
          MIN_IMAGE_WIDTH,
          maxWidth ?? MAX_IMAGE_WIDTH
        )

  return Math.max(maxResizeWidth, MIN_IMAGE_WIDTH)
}

function getHeight(
  width: number,
  currentRatio?: string | number | null
): number {
  const imageRatio =
    typeof currentRatio === 'string'
      ? standardRatios[currentRatio]
      : currentRatio ?? 1

  return Math.floor(width / imageRatio)
}

function extractPreferredImageUrl(
  preferredImage: SGPageTemplateImage | string
): string {
  const baseUrl =
    typeof preferredImage === 'string' ? preferredImage : preferredImage.url
  return baseUrl.startsWith('//') ? `https:${baseUrl}` : baseUrl
}

export default function makeImageUrl(
  preferredImage: SGPageTemplateImage | string,
  currentRatio?: string | number | null,
  currentResizeMode?: 'crop-face' | 'crop-center' | 'pad' | null,
  maxWidth?: number,
  dpr?: number,
  // If `none`, ignore format parameter. If undefined/null, default to `f_auto`
  imageFormat?: ImageFormat | null
): string {
  const computedMaxWidth = getWidth(maxWidth)
  const computedMaxHeight = getHeight(computedMaxWidth, currentRatio)

  const cloudinaryResizeMode = RESIZE_MODES[currentResizeMode ?? 'crop-face']
  const cropOptions = [
    cloudinaryResizeMode,
    `w_${computedMaxWidth},h_${computedMaxHeight}`,
  ]

  if (imageFormat !== 'none') {
    cropOptions.push(imageFormat ?? 'f_auto')
  }

  cropOptions.push(`dpr_${dpr ?? 1}`)
  const preferredImageUrl = extractPreferredImageUrl(preferredImage)
  return `${URL_PREFIX}${cropOptions.join(',')}/${preferredImageUrl}`
}

export function makeUnscaledImageUrl(url: string): string {
  return `${URL_PREFIX}/${url}`
}

export function makeSchemaImageUrls(baseUrl: string): string[] {
  return ['16/9', '4/3', '1'].map(ratio => makeImageUrl(baseUrl, ratio))
}
