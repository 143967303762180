import { flatten, tail } from 'lodash'
import {
  SGPageTemplateImage,
  SGPageTemplateImageType,
  sgPageTemplateImageTypeValues,
} from '@scentregroup/shared/types/page-templates'
import { asArray, MaybeArray } from './maybe-array'
import { useCurrent } from './use-current'
import { HubResponsiveArray } from '@hub/design-system-base'

function getUnmentionedImageTypes(
  preferredImageType?: MaybeArray<SGPageTemplateImageType> | null
): readonly SGPageTemplateImageType[] {
  if (!preferredImageType) {
    return sgPageTemplateImageTypeValues
  }

  return sgPageTemplateImageTypeValues.filter(
    imageType => asArray(preferredImageType).indexOf(imageType) === -1
  )
}

function findBestSrcWithPreferredImageTypes(
  imageSetOrImage: SGPageTemplateImage | SGPageTemplateImage[],
  preferredImageType: SGPageTemplateImageType[]
): SGPageTemplateImage {
  if (!Array.isArray(imageSetOrImage)) {
    return imageSetOrImage
  }

  if (preferredImageType.length === 0) {
    return imageSetOrImage[0]
  }

  const matchingImage = imageSetOrImage.filter(
    image => image.type === preferredImageType[0]
  )[0]

  if (matchingImage) {
    return matchingImage
  }

  return findBestSrcWithPreferredImageTypes(
    imageSetOrImage,
    tail(preferredImageType)
  )
}

export default function findBestImage(
  imageSetOrImage: MaybeArray<SGPageTemplateImage>,
  preferredImageType?: MaybeArray<SGPageTemplateImageType> | null
): SGPageTemplateImage {
  const unmentionedImageTypes = getUnmentionedImageTypes(preferredImageType)

  const preferredImageTypesWithUnmentionedImageTypes = [
    ...flatten([preferredImageType]),
    ...unmentionedImageTypes,
  ].filter(
    type => type === 'WIDE' || type === 'SQUARE'
  ) as SGPageTemplateImageType[]

  return findBestSrcWithPreferredImageTypes(
    imageSetOrImage,
    preferredImageTypesWithUnmentionedImageTypes
  )
}

export function useBestImage(
  imageSetOrImage: MaybeArray<SGPageTemplateImage>,
  preferredImageType?: HubResponsiveArray<
    SGPageTemplateImageType | SGPageTemplateImageType[]
  >
): SGPageTemplateImage {
  const currentPreferredImageType = useCurrent(preferredImageType)
  return findBestImage(imageSetOrImage, currentPreferredImageType)
}
