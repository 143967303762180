import React, { useCallback, useState } from 'react'

import { Box } from '@hub/box'
import { Input, InputGroup, InputRightElement } from '@hub/input'
import { ChevronDownSmallIcon, ChevronUpSmallIcon } from '@hub/icon'
import { IconButton } from '@hub/button'
import {
  FilteredCentreList,
  FilteredCentreListVariant,
  CentreListItem,
} from '../filtered-centre-list'

const PLACEHOLDER = 'Choose a centre'

interface CentrePickerProps {
  variant?: FilteredCentreListVariant
  items: CentreListItem[]
  query: string
  onChange: (query: string) => void
  onBlur?: () => void
  onFocus?: () => void
}

const InputBar: React.FC<
  React.PropsWithChildren<{
    isOpen: boolean
    query: string
    setIsOpen: (val: boolean) => void
    setQuery: (query: string) => void
    onBlur?: () => void
    onFocus?: () => void
  }>
> = ({ isOpen, setIsOpen, setQuery, query, onFocus, onBlur }) => (
  <InputGroup
    backgroundColor="surfaceTertiary"
    borderStyle="solid"
    borderWidth={1}
    borderColor="borderSecondary"
    color="textPrimary"
    height="size-8"
    paddingTop="spacing-xs"
  >
    <Input
      placeholder={PLACEHOLDER}
      _placeholder={{ paddingX: 'spacing-sm' }}
      sx={{
        paddingX: 'spacing-md',
        border: '0px',
        borderColor: 'borderSecondary',
      }}
      onChange={event => setQuery(event.target.value)}
      value={query}
    />
    <InputRightElement marginTop="spacing-xs">
      <IconButton
        aria-label="Toggle centre list"
        variant="transparent"
        boxSize="size-icon-sm"
        colorScheme="surfacePrimaryWithIcon"
        onMouseDown={() => {
          if (!isOpen) {
            onFocus?.()
          } else {
            onBlur?.()
          }
          setIsOpen(!isOpen)
        }}
        icon={
          isOpen ? (
            // Disabling the icon pointer events as clicking directly
            // on them caused the parent onBlur not to trigger
            <ChevronUpSmallIcon sx={{ pointerEvents: 'none' }} />
          ) : (
            <ChevronDownSmallIcon sx={{ pointerEvents: 'none' }} />
          )
        }
      />
    </InputRightElement>
  </InputGroup>
)

const DropdownPanel: React.FC<{
  variant: FilteredCentreListVariant
  query: string
  setIsOpen: (val: boolean) => void
  setQuery: (query: string) => void
  items: CentreListItem[]
}> = ({ variant, query, items, setIsOpen, setQuery }) => {
  const handleSelect = useCallback(
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (variant === 'select') {
        setQuery(event.currentTarget.getAttribute('name') || '')
        setIsOpen(false)
      }
    },
    [setQuery, variant, setIsOpen]
  )

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 'overlay',
        bgColor: 'surfaceTertiary',
        borderStyle: 'solid',
        borderWidth: '0px 1px 1px 1px',
        width: '100%',
        borderColor: 'borderSecondary',
        height: 'size-24',
        overflowY: 'auto',
        padding: 'spacing-md',
      }}
    >
      <FilteredCentreList
        variant={variant}
        items={items}
        query={query}
        onClick={handleSelect}
      />
    </Box>
  )
}

export const CentrePicker: React.FC<
  React.PropsWithChildren<CentrePickerProps>
> = ({ variant = 'link', items, query, onChange, onBlur, onFocus }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box
      tabIndex={-1}
      onBlur={(e: React.FocusEvent) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          onBlur?.()
          setIsOpen(false)
        }
      }}
      onFocus={(e: React.FocusEvent) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          onFocus?.()
          setIsOpen(true)
        }
      }}
      sx={{ position: 'relative' }}
    >
      <InputBar
        query={query}
        setQuery={onChange}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {isOpen && (
        <DropdownPanel
          variant={variant}
          query={query}
          setQuery={onChange}
          setIsOpen={setIsOpen}
          items={items}
        />
      )}
    </Box>
  )
}
