import { Box } from '@hub/box'
import React from 'react'
import UtilityPanel from '../../utility-panel'

type HeaderQuickLinksProps = {
  items?: { label: string; url: string; image: string }[]
}

export const HeaderQuickLinks: React.FC<HeaderQuickLinksProps> = ({
  items,
}) => {
  if (!Array.isArray(items)) {
    return null
  }
  return (
    <>
      {
        <Box
          marginTop={['spacing-md', null, 'spacing-lg']}
          marginBottom={['spacing-md', null, 'spacing-lg']}
        >
          <UtilityPanel
            items={items.map(({ label, url, image }) => ({
              // to accommodate legacy props in UtilityPanel
              __typename: 'SGPageTemplateContentEntry',
              kind: 'featuredLink',
              title: label,
              image: {
                type: 'SQUARE',
                url: image,
                alt: null,
              },
              callToAction: {
                url,
                label,
              },
              viewItem: () => {},
            }))}
          />
        </Box>
      }
    </>
  )
}
