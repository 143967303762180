//
// This file is auto generated. See ./README.md for details
//
import React from 'react'
import { Icon as ChakraIcon } from '@chakra-ui/react'
import {
  HubResponsiveValue,
  StandardIconSizes,
  HubStyleObject,
  forwardRef,
  IconColors,
} from '@hub/design-system-base'
import { WaterMediumSVG } from './svg'

interface WaterMediumIconProps {
  boxSize?: HubResponsiveValue<StandardIconSizes>
  sx?: HubStyleObject
  ariaLabel?: string
  color?: IconColors
}

export const WaterMediumIcon: React.FC<
  React.PropsWithChildren<WaterMediumIconProps>
> = forwardRef<WaterMediumIconProps, typeof ChakraIcon>(
  ({ boxSize = 'size-icon-sm', sx, ariaLabel, color }, ref) => (
    <ChakraIcon
      ref={ref}
      boxSize={boxSize}
      aria-label={ariaLabel}
      as={WaterMediumSVG}
      sx={sx}
      color={color}
    />
  )
)
