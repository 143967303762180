import React from 'react'
import {
  BreadcrumbLink as ChakraBreadcrumbLink,
  BreadcrumbLinkProps,
} from '@chakra-ui/react'
import { Link } from '@hub/link'

export const BreadcrumbLink: React.FC<
  React.PropsWithChildren<
    Pick<
      BreadcrumbLinkProps,
      'as' | 'sx' | 'href' | 'children' | 'isCurrentPage'
    >
  >
> = ({ isCurrentPage, ...props }) => (
  <ChakraBreadcrumbLink
    as={isCurrentPage ? undefined : Link}
    sx={{
      ...{
        minHeight: 48,
        display: 'flex',
        alignItems: 'center',
      },
      fontWeight: 'thin',
      color: isCurrentPage ? 'quartz' : undefined,
      '&:hover': {
        textDecoration: isCurrentPage ? 'none' : undefined,
        cursor: isCurrentPage ? 'default' : undefined,
      },
    }}
    {...props}
  />
)
