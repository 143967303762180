export enum AlgoliaAnalyticsTagsRepositories {
  WebsiteClient = 'repo:website-client',
}

// generally base on the file name unless there's
// drastically different use cases in the one files
export enum AlgoliaAnalyticsTagsUses {
  SiteSearch = 'use:site-search',
  LiveSearch = 'use:live-search',
}

export type AlgoliaAnalyticsTags =
  | AlgoliaAnalyticsTagsRepositories
  | AlgoliaAnalyticsTagsUses
