// These are the same as we're now deciding which one is enabled in the
// nginx configuration, as they live on the same path.
const prefixAccount = '/account'

export function incBaseAccount(path: string): string {
  return prependPrefix(path, prefixAccount)
}

function prependPrefix(path: string, prefix: string): string {
  const separator = path.startsWith('/') || path === '' ? '' : '/'
  return `${prefix}${separator}${path}`
}

function isPrefixedPath(path: string, prefix: string): boolean {
  return new RegExp(`${prefix}(\\/.*)?$`).test(path)
}

export function isAccountPath(path: string): boolean {
  return isPrefixedPath(path, prefixAccount)
}

export function removeAccountPrefix(path: string): string {
  return path.replace(/^\/?account(\/|$)/, '')
}
