export type GeoLocation = {
  latitude: number
  longitude: number
}

export const geoLocationDistanceInKm = (
  { latitude: lat1, longitude: lon1 }: GeoLocation,
  { latitude: lat2, longitude: lon2 }: GeoLocation
): number => {
  // ‘Haversine’ formula to calculate distances between two points (latitude-longitude)
  const earthRadius = 6371 // km
  // Cosine in degrees instead of radians
  const c = (degrees: number): number => Math.cos((degrees * Math.PI) / 180)
  const twoH = 1 - c(lat2 - lat1) + c(lat1) * c(lat2) * (1 - c(lon2 - lon1))
  return 2 * earthRadius * Math.asin(Math.sqrt(twoH / 2))
}

export const getNearestLocations = <Location extends GeoLocation>(
  fromLocation: GeoLocation,
  locations: Location[]
): (Location & { distance: number })[] =>
  locations
    .map(location => ({
      ...location,
      distance: geoLocationDistanceInKm(fromLocation, location),
    }))
    .sort((a, b) => a.distance - b.distance)

export default getNearestLocations
