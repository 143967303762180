import { zip, max } from 'lodash'

export default function limitResults<
  Item,
  Collection extends { items: Item[] },
>(collections: Collection[], overallLimit: number): Collection[] {
  const nonEmpty = collections.filter(collection => collection.items.length > 0)
  if (nonEmpty.length === 0) {
    return []
  }
  let total = 0
  const limited = nonEmpty.map(collection => ({ ...collection, items: [] }))
  const maxCollectionLength =
    max(nonEmpty.map(collection => collection.items.length)) ?? 0
  for (
    let collectionIndex = 0;
    collectionIndex < maxCollectionLength;
    collectionIndex++
  ) {
    for (const [unfilteredCollection, filteredCollection] of zip(
      nonEmpty,
      limited
    )) {
      // Adding the first element of a collection counts for two
      // because we count the heading of a section as a row
      const cost = collectionIndex === 0 ? 2 : 1
      if (total + cost > overallLimit) {
        return limited
      }
      const item = unfilteredCollection?.items[collectionIndex]
      if (item) {
        filteredCollection?.items.push(item)
        total += cost
      }
    }
  }
  return limited
}
