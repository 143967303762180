import { Button } from '@hub/button'
import { forwardRef, StandardSizes } from '@hub/design-system-base'
import React from 'react'

type MenuButtonProps = Omit<React.ComponentProps<typeof Button>, 'variant'> & {
  variant?: 'bar' | 'panel'
  size?: StandardSizes
  tone?: 'neutral' | 'strong'
}

export const MenuButton: React.FC<React.PropsWithChildren<MenuButtonProps>> =
  forwardRef(
    ({ variant = 'panel', size = 'sm', tone = 'neutral', ...props }, ref) => {
      if (variant === 'bar') {
        return <Button ref={ref} variant="menuButton" size={size} {...props} />
      }
      if (variant === 'panel') {
        return (
          <Button
            variant="plainText"
            colorScheme="licorice"
            size={size}
            {...props}
            sx={{
              display: 'flex',
              justifyContent: props.leftIcon ? 'start' : 'space-between',
              fontWeight: tone === 'strong' ? 'bold' : undefined,
              textDecoration: props.active ? 'underline' : undefined,
              minHeight: 48,
              ...props.sx,
            }}
          />
        )
      }
      return null
    }
  )
