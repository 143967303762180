import { Box } from '@hub/box'
import { CoreContainer } from '@hub/core-container'
import React from 'react'
import { INavigationMenuItem } from '../types'
import { HeaderNavigation } from './navigation'

type HeaderNavigationBarProps = {
  trigger?: 'click' | 'hover'
  fadeDuration?: number
  primaryMenuItems?: INavigationMenuItem[]
  secondaryMenuItems?: INavigationMenuItem[]
  onOpen?: () => void
  onClickLink?: (event: React.MouseEvent, path: INavigationMenuItem[]) => void
}

export const HeaderNavigationBar: React.FC<
  React.PropsWithChildren<HeaderNavigationBarProps>
> = ({
  trigger,
  fadeDuration,
  primaryMenuItems,
  secondaryMenuItems,
  onOpen,
  onClickLink,
}) => {
  const [isOpenNavigation, setIsOpenNavigation] = React.useState(false)
  const handleOpenNavigation = React.useCallback((): void => {
    setIsOpenNavigation(true)
    onOpen?.()
  }, [onOpen])
  const handleCloseNavigation = React.useCallback(() => {
    setIsOpenNavigation(false)
  }, [])

  if (!Array.isArray(primaryMenuItems)) {
    return null
  }

  return (
    <Box backgroundColor="surfaceBrandSecondary">
      <CoreContainer fadeOutOverflow={[true, true, null, false]}>
        <HeaderNavigation
          trigger={trigger}
          fadeDuration={fadeDuration}
          isOpen={isOpenNavigation}
          onClose={handleCloseNavigation}
          onOpen={handleOpenNavigation}
          primaryItems={primaryMenuItems}
          secondaryItems={secondaryMenuItems}
          onClickLink={onClickLink}
        />
      </CoreContainer>
    </Box>
  )
}
