import { MegaMenuFeature, MegaMenuLinkItem } from '../mega-menu/types'

export type INavigationLink = {
  label: string
  url: string
}
export type INavigationSimpleMenu = {
  title: string
  items: (INavigationLink | INavigationSimpleMenu)[]
}
export type INavigationMegaMenu = {
  title: string
  items: MegaMenuLinkItem[]
  feature: MegaMenuFeature
}
export type INavigationMenuItem =
  | INavigationLink
  | INavigationSimpleMenu
  | INavigationMegaMenu

export type INavigationMenu = (INavigationLink | INavigationSimpleMenu)[]
export type INavigationMenuWithMegaMenu = INavigationMenuItem[]

export const isNavigationSimpleMenu = (
  item: INavigationMenuItem
): item is INavigationSimpleMenu => {
  return (
    Object.hasOwn(item, 'title') &&
    Object.hasOwn(item, 'items') &&
    !isNavigationMegaMenu(item)
  )
}
export const isNavigationLink = (
  item: INavigationMenuItem
): item is INavigationLink => {
  {
    return Object.hasOwn(item, 'label') && Object.hasOwn(item, 'url')
  }
}
export const isNavigationMegaMenu = (
  item: INavigationMenuItem
): item is INavigationMegaMenu => {
  return (
    Object.hasOwn(item, 'title') &&
    Object.hasOwn(item, 'items') &&
    Object.hasOwn(item, 'feature')
  )
}
