import { useQuery } from '@apollo/client'
import { cfTopStripToTopStripProps, ITopStripProps } from './types'
import { GetTopStripDocument } from '../../graphql'

function useTopStripProps(country: string): ITopStripProps | null {
  const { data } = useQuery(GetTopStripDocument, {
    variables: {
      country,
      isCentre: false,
    },
  })

  const cfTopStrip = data?.cfTopStripCollection?.items[0]

  if (!cfTopStrip) {
    return null
  }

  return cfTopStripToTopStripProps(cfTopStrip)
}

export default useTopStripProps
