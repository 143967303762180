import { useState, useMemo, useCallback } from 'react'
import { useRecentCentres } from '../hooks/use-recent-centres'
import { Centre as CompleteCentre } from '../helpers/centre'
import analyticsUtils from '../helpers/analytics-utils'
import getNearestLocations from '../helpers/get-nearest-locations'

type SuggestedCentre = Pick<
  CompleteCentre,
  'state' | 'suburb' | 'title' | 'slug' | 'latitude' | 'longitude'
>

export const useNearestCentres = (
  centres: SuggestedCentre[],
  MAX_SUGGESTIONS = 3
): [
  state: { loadingLocation: boolean; suggestedCentres: SuggestedCentre[] },
  getSuggestedCentresNearMe: () => void,
] => {
  const recentCentres: string[] = useRecentCentres()
  const [loadingLocation, setLoadingLocation] = useState(false)
  const maxSuggests = useMemo(() => MAX_SUGGESTIONS, [MAX_SUGGESTIONS])

  const getCentreDisplayTitle = (centre?: SuggestedCentre): string => {
    if (!centre) {
      return ''
    }
    const location =
      centre.suburb === centre.title
        ? centre.state
        : `${centre.suburb}, ${centre.state}`

    return `${centre.title}, ${location}`
  }

  // Inject a `.displayTitle` field for better rendering
  const centresWithDisplayTitle: SuggestedCentre[] = useMemo(
    () =>
      (centres ?? []).map(centre => ({
        ...centre,
        displayTitle: getCentreDisplayTitle(centre),
      })),
    [centres]
  )

  const [suggestedCentres, setSuggestedCentres] = useState<SuggestedCentre[]>(
    (): SuggestedCentre[] => {
      return (
        recentCentres
          // Convert it into a complete object
          .map(slug =>
            centresWithDisplayTitle.find(centre => centre.slug === slug)
          )
          // Clear out any bad data
          .filter(centre => Boolean(centre))
          // Limit the number of suggestions we return
          .slice(0, maxSuggests) as SuggestedCentre[]
      )
    }
  )

  const getSuggestedCentresNearMe = useCallback(() => {
    setLoadingLocation(true)
    analyticsUtils.DEPRECATED_gaTagTracking({
      event: analyticsUtils.events.SEARCH,
      eventCategory: analyticsUtils.categories.CENTRE_SEARCH,
      eventAction: analyticsUtils.actions.CLICK_TO_SET_LOCATION,
    })

    try {
      // For browsers that do not support geolocation
      navigator.geolocation.getCurrentPosition(
        position => {
          const nearestCentres = getNearestLocations(
            position.coords,
            centresWithDisplayTitle
          ).slice(0, maxSuggests)
          setSuggestedCentres(nearestCentres)
          setLoadingLocation(false)
        },
        () => {
          setLoadingLocation(false)
        },
        {
          timeout: 10000,
        }
      )
    } catch {
      setLoadingLocation(false)
      // TODO: Verify this is the right event
      analyticsUtils.DEPRECATED_gaTagTracking({
        event: analyticsUtils.events.SEARCH,
        eventCategory: analyticsUtils.categories.CENTRE_SEARCH,
        eventAction: analyticsUtils.actions.CLICK_TO_SET_LOCATION_DENIED,
      })
    }
  }, [centresWithDisplayTitle, maxSuggests])
  return [{ loadingLocation, suggestedCentres }, getSuggestedCentresNearMe]
}
