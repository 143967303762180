import { Segment, UrlGetter, RenderContext, SegmentedHit } from './types'

const centreSlug = (context: RenderContext): string =>
  context.centre?.slug ?? ''

const getUrlForNationalStorefront: UrlGetter<
  Segment.nationalStorefronts
> = hit => `/store/${hit.retailerId}/${hit.slug}`

const getUrlForStore: UrlGetter<Segment.stores> = (hit, context) =>
  `/${centreSlug(context)}/store/${hit.objectID}/${hit.slug}`

const getUrlForSite: UrlGetter<Segment.sites> = (hit, context) =>
  (hit.href || '').replace('CENTRE', centreSlug(context))

const getUrlForService: UrlGetter<Segment.services> = (hit, context) =>
  `/${centreSlug(context)}/service/${hit.objectID}/${hit.slug}`

const getUrlForLocalOffer: UrlGetter<Segment.localOffers> = (hit, context) =>
  `/${centreSlug(context)}/offer/${hit.objectID}/${hit.slug}`

const getUrlForNationallOffer: UrlGetter<Segment.nationalOffers> = (
  hit,
  _context
) => `/offer/${hit.objectID}/${hit.slug}`

const getUrlForEvent: UrlGetter<Segment.events> = (hit, context) =>
  `/${centreSlug(context)}/event/${hit.objectID}/${hit.slug}`

const getUrlForLocalNews: UrlGetter<Segment.localNews> = (hit, context) =>
  `/${centreSlug(context)}/news/${hit.objectID}/${hit.slug}`

const getUrlForNationalNews: UrlGetter<Segment.nationalNews> = (
  hit,
  _context
) => `/news/${hit.objectID}/${hit.slug}`

const getUrlForLocalStory: UrlGetter<Segment.localStories> = (hit, context) =>
  `/${centreSlug(context)}/story/${hit.objectID}/${hit.slug}`

const getUrlForNationalStory: UrlGetter<Segment.nationalStories> = (
  hit,
  _context
) => `/story/${hit.objectID}/${hit.slug}`

const getUrlForCentre: UrlGetter<Segment.centres> = (hit, _context) => hit.href

const getUrlForOnlineService: UrlGetter<Segment.onlineServices> = (
  hit,
  _context
) => hit.href

const getUrlForOtherStore: UrlGetter<Segment.otherStores> = (hit, _context) =>
  `/${hit.centreSlug}/store/${hit.objectID}/${hit.slug}`

const getUrlForWestfieldProduct: UrlGetter<Segment.westfieldProducts> = (
  hit,
  _context
) => `${hit.href}`

const getUrlForSearchSuggestion: UrlGetter<Segment.searchSuggestions> = (
  hit,
  context
) => (hit.url ?? '').replace('CENTRE', centreSlug(context))

export default function getUrlForHit(
  hit: SegmentedHit,
  context: RenderContext
): string {
  switch (hit.segment) {
    case 'nationalStorefronts':
      return getUrlForNationalStorefront(hit, context)
    case 'stores':
      return getUrlForStore(hit, context)
    case 'sites':
      return getUrlForSite(hit, context)
    case 'services':
      return getUrlForService(hit, context)
    case 'localOffers':
      return getUrlForLocalOffer(hit, context)
    case 'nationalOffers':
      return getUrlForNationallOffer(hit, context)
    case 'events':
      return getUrlForEvent(hit, context)
    case 'localNews':
      return getUrlForLocalNews(hit, context)
    case 'nationalNews':
      return getUrlForNationalNews(hit, context)
    case 'localStories':
      return getUrlForLocalStory(hit, context)
    case 'nationalStories':
      return getUrlForNationalStory(hit, context)
    case 'centres':
      return getUrlForCentre(hit, context)
    case 'onlineServices':
      return getUrlForOnlineService(hit, context)
    case 'otherStores':
      return getUrlForOtherStore(hit, context)
    case 'westfieldProducts':
      return getUrlForWestfieldProduct(hit, context)
    case 'searchSuggestions':
      return getUrlForSearchSuggestion(hit, context)
    default:
      throw new Error(`Unknown segment ${hit.segment}`)
  }
}
