import { useEffect } from 'react'
import createLocalStorageStateHook from 'use-local-storage-state'
import type { LocalStorageState } from 'use-local-storage-state'
import { Centre } from '@scentregroup/shared/types/centre'

const LOCAL_STORAGE_KEY = 'recent-centres'

function useLocalStorage(): LocalStorageState<string[]> {
  return createLocalStorageStateHook<string[]>(LOCAL_STORAGE_KEY, {
    defaultValue: [],
  })
}

export const useRecentCentresTracker = (centre: Centre): void => {
  const [, setRecentCentres] = useLocalStorage()
  useEffect(() => {
    // NOTE: We use the functional version of state setting here to avoid an
    // infinite loop when two tabs are open with different values for
    // `centre.slug`. This is a problem because `use-local-storage-state` will
    // sync the value across tabs by subscribing to the `session` window event.
    // If we were to add `recentCentres` to the dependency list of `useEffect`,
    // it would trigger a re-evaluation of the effect when either tab altered
    // value and so the tabs would fight about setting their own slug to be the
    // first in the list.
    // But by using the functional style, it's not a dependency, and our effect
    // is only executed when this page's `centre.slug` changes, which is what we
    // expect
    setRecentCentres(recentCentres => {
      // A short-cut for when the current centre is already the first. This
      // allows the `recentCentres` value to stay consistent meaning any later
      // object equality checks (for example within a `useEffect` dependency
      // array elsewhere) will still match, and wont unnecessarily re-run the
      // hook/effect.
      if (
        recentCentres &&
        recentCentres.length &&
        recentCentres[0] === centre.slug
      ) {
        return recentCentres
      }
      return [
        centre.slug,
        ...(recentCentres || []).filter(slug => slug !== centre.slug),
      ]
    })
  }, [centre.slug, setRecentCentres]) // Only run once per page load
}

export const useRecentCentres = (): string[] => {
  const [recentCentres] = useLocalStorage()
  return recentCentres || []
}
