import React from 'react'
import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbProps,
} from '@chakra-ui/react'

export const Breadcrumb: React.FC<
  React.PropsWithChildren<BreadcrumbProps> & { wrap?: boolean }
> = ({ sx, as, children, wrap = true, ...props }) => (
  <ChakraBreadcrumb
    sx={{
      color: 'licorice',
      '& > ol': { minHeight: 48 },
      ...(wrap ? { '& > ol': { flexWrap: 'wrap' } } : null),
      ...sx,
    }}
    as={as}
    {...props}
  >
    {children}
  </ChakraBreadcrumb>
)
