/*
Common core of algolia functionality used by
both the use-algolia Hook, and the Next JS
GetServerSideProps for server rendering
*/

import algoliasearch from 'algoliasearch'
import { getNowInCentreInTimeFormat } from '../../helpers/date-helpers'
import {
  CentreSearchFields,
  AlgoliaApiKeys,
  Segment,
  DoQueryAlgoliaArgs,
  Clients,
  SearchSuggestionsContext,
  QueryContext,
  RenderContext,
  SegmentHits,
  RenderedSectionResults,
} from './types'
import querySegments from './query-segments'
import { makeOnResultClick } from './analytics'
import groupAndRenderSegments from './group-and-render-segments'

interface OffsetlessTimeGetters {
  getOffsetlessTimeInDefaultTz: () => number
  getOffsetlessTimeInCentre?: () => number
}

const getDefaultNationalTimezone = (country: string): string => {
  if (country === 'New Zealand') {
    return 'Pacific/Auckland'
  } else {
    return 'Australia/Sydney'
  }
}

export const makeOffsetlessTimeGetters = (
  country: string,
  centre?: CentreSearchFields
): OffsetlessTimeGetters => ({
  getOffsetlessTimeInDefaultTz: getNowInCentreInTimeFormat(
    getDefaultNationalTimezone(country)
  ) as () => number,
  getOffsetlessTimeInCentre:
    centre && (getNowInCentreInTimeFormat(centre.timeZone) as () => number),
})

export function makeClients(
  algoliaApiKeys: AlgoliaApiKeys,
  extraConfig?: Parameters<typeof algoliasearch>[2]
): Clients | undefined {
  const { contentApplicationId, contentApiKey } = algoliaApiKeys
  if (!contentApplicationId || !contentApiKey) {
    return undefined
  }
  return {
    content: algoliasearch(contentApplicationId, contentApiKey, extraConfig),
  }
}

const toExclude: Segment[] = [
  // search suggestions are only relevant to live search:
  Segment.searchSuggestions,
]

export const doQueryAlgolia = ({
  query,
  clients,
  context,
}: DoQueryAlgoliaArgs): ReturnType<typeof querySegments> => {
  const segments = Object.values(Segment).filter(s => !toExclude.includes(s))

  return clients
    ? querySegments(query, clients, context, segments)
    : Promise.reject(new Error('Algolia clients not initalised'))
}

export const searchSuggestionsContextFromQueryContext = (
  context: QueryContext
): SearchSuggestionsContext => {
  const countryCode = context.country === 'New Zealand' ? 'NZ' : 'AU'
  const suffix =
    context.specialSearchKind === 'stores'
      ? 'stores search'
      : context.centre
      ? 'centre defaults'
      : 'national search'
  return `${countryCode} ${suffix}`
}

export function processResults(
  query: string,
  context: RenderContext,
  segmentHits: SegmentHits
): { showNoResults: boolean; results: RenderedSectionResults[] } {
  const onResultClickHeading = makeOnResultClick(query)
  const results = groupAndRenderSegments({
    segmentResults: segmentHits,
    context,
    onResultClickHeading,
  })
  const showNoResults = query.length > 0 && results.length === 0
  return { showNoResults, results }
}
