import { Logo as HubLogo } from '@hub/logos'
import React from 'react'
import { HubStyleObject } from '@hub/design-system-base'
import dynamic from 'next/dynamic'

export enum CentreSlug {
  airportwest = 'airportwest',
  albany = 'albany',
  belconnen = 'belconnen',
  bondijunction = 'bondijunction',
  booragoon = 'booragoon',
  burwood = 'burwood',
  carindale = 'carindale',
  carousel = 'carousel',
  chatswood = 'chatswood',
  chermside = 'chermside',
  coomera = 'coomera',
  doncaster = 'doncaster',
  eastgardens = 'eastgardens',
  fountaingate = 'fountaingate',
  geelong = 'geelong',
  helensvale = 'helensvale',
  hornsby = 'hornsby',
  hurstville = 'hurstville',
  innaloo = 'innaloo',
  knox = 'knox',
  kotara = 'kotara',
  liverpool = 'liverpool',
  manukau = 'manukau',
  marion = 'marion',
  miranda = 'miranda',
  mtdruitt = 'mtdruitt',
  mtgravatt = 'mtgravatt',
  national = 'national',
  newmarket = 'newmarket',
  northlakes = 'northlakes',
  parramatta = 'parramatta',
  penrith = 'penrith',
  plentyvalley = 'plentyvalley',
  riccarton = 'riccarton',
  southland = 'southland',
  stlukes = 'stlukes',
  sydney = 'sydney',
  teatreeplaza = 'teatreeplaza',
  tuggerah = 'tuggerah',
  warringahmall = 'warringahmall',
  westlakes = 'westlakes',
  whitfordcity = 'whitfordcity',
  woden = 'woden',
}

const getCentreSlug = (s: string): CentreSlug | undefined => {
  const gaurd = (s: string): s is CentreSlug => {
    const values: string[] = Object.values(CentreSlug)
    return values.includes(s)
  }
  if (!gaurd(s)) {
    return undefined
  }
  return s
}

type LogoProps = {
  variant?: 'gift-cards' | 'national' | 'account' | 'centre'
  centreSlug?: string
  sx?: HubStyleObject
}

export const Logo: React.FC<React.PropsWithChildren<LogoProps>> = ({
  variant = 'national',
  centreSlug,
}) => {
  const logoString = (() => {
    switch (variant) {
      case 'national':
      case 'account':
      case 'gift-cards':
        return variant
      case 'centre':
        return (centreSlug && getCentreSlug(centreSlug)) ?? 'national'
    }
  })()

  const dynamicCentreLogo = dynamic(
    () => import(`./assets/westfield-logo-red-${logoString}.inline.svg`)
  )

  return <HubLogo svg={logoString ? dynamicCentreLogo : undefined} />
}
