import React from 'react'
import { Link } from '@hub/link'
import { Box } from '@hub/box'
import { Image } from '@hub/image'
import { Text } from '@hub/text'

import { displayStringForPriceRange } from '../../helpers/product-price'

interface IWestfieldProduct {
  objectID: string
  name: string
  description: string
  minPrice: number
  maxPrice: number
  image: string
  href: string
}

export interface IWestfieldProductCardProps {
  westfieldProduct: IWestfieldProduct
  index?: number
}

const cloudinaryPrefix = `https://images.scentregroup.io/image/fetch/`

const WestfieldProductCard: React.FunctionComponent<
  React.PropsWithChildren<IWestfieldProductCardProps>
> = ({ westfieldProduct, index }) => {
  return (
    <Box
      data-test-id="westfield-product-card"
      data-insights-object-id={westfieldProduct.objectID}
      data-insights-position={index}
      sx={{ maxWidth: 'size-19' }}
    >
      <Link
        href={westfieldProduct.href}
        sx={{ _hover: { textDecoration: 'none' } }}
      >
        <Image src={`${cloudinaryPrefix}${westfieldProduct.image}`} />
        <Text
          fontSize="font-sm"
          sx={{
            marginTop: 'spacing-md',
            wordBreak: 'break-word',
          }}
        >
          {westfieldProduct.name}
        </Text>
        <Text tone="strong" fontSize="font-sm" sx={{ marginTop: 'spacing-xs' }}>
          {displayStringForPriceRange({
            min: westfieldProduct.minPrice,
            max: westfieldProduct.maxPrice,
          })}
        </Text>
        <Text
          color="surfacePrimaryHover"
          fontSize="font-sm"
          sx={{
            marginTop: 'spacing-sm',
            overflow: 'hidden',
            maxHeight: 'size-xs',
          }}
        >
          {westfieldProduct.description}
        </Text>
      </Link>
    </Box>
  )
}

export default WestfieldProductCard
