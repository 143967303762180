import { IconButton } from '@hub/button'
import { ChevronLeftMediumIcon } from '@hub/icon'
import { Link } from '@hub/link'
import React from 'react'
import { useHeaderProps } from './header'

export const HeaderBackButton: React.FC = () => {
  const { backLink, onClickBack } = useHeaderProps()
  if (!backLink && !onClickBack) {
    return null
  }
  if (backLink) {
    return (
      <IconButton
        as={(props: React.ComponentProps<typeof IconButton>) => (
          <Link href={backLink} ariaLabel="back" {...props} />
        )}
        variant="transparent"
        colorScheme="licorice"
        size="icon-lg"
        icon={<ChevronLeftMediumIcon boxSize="size-icon-md" />}
        onClick={onClickBack ?? (() => {})}
        aria-label="back"
      />
    )
  }
  return (
    <IconButton
      variant="transparent"
      colorScheme="licorice"
      size="icon-lg"
      icon={<ChevronLeftMediumIcon boxSize="size-icon-md" />}
      onClick={onClickBack ?? (() => {})}
      aria-label="back"
    />
  )
}
