import {
  NavigationAccordion,
  NavigationButton,
  NavigationLink,
  NavigationPanel,
} from '@hub/navigation'
import React from 'react'
import {
  INavigationMenu,
  INavigationMenuItem,
  isNavigationLink,
} from '../types'

type AccordionMenuProps = {
  items: INavigationMenu
  onClickLink?: (event: React.MouseEvent, path: INavigationMenuItem[]) => void
  onClickButton?: (
    event: React.MouseEvent,
    context: { isOpen: boolean | undefined },
    path: INavigationMenuItem[]
  ) => void
}

export const AccordionMenu: React.FC<AccordionMenuProps> = ({
  items,
  onClickLink,
  onClickButton,
}) => {
  let index = 0
  return (
    <>
      {items.map((item, key) => {
        if (isNavigationLink(item)) {
          return (
            <NavigationLink
              size="md"
              key={key}
              href={item.url}
              onClick={event => onClickLink?.(event, [item])}
            >
              {item.label}
            </NavigationLink>
          )
        }
        return (
          <NavigationAccordion key={key} index={index++}>
            <NavigationButton
              onClick={(event, context) => {
                onClickButton?.(event, context, [item])
              }}
            >
              {item.title}
            </NavigationButton>
            <NavigationPanel>
              {item.items.filter(isNavigationLink).map((childItem, key) => (
                <NavigationLink
                  size="md"
                  key={key}
                  href={childItem.url}
                  onClick={event => onClickLink?.(event, [item, childItem])}
                >
                  {childItem.label}
                </NavigationLink>
              ))}
            </NavigationPanel>
          </NavigationAccordion>
        )
      })}
    </>
  )
}
