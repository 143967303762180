import React, { useCallback, useContext, useMemo } from 'react'

type FoldStatus = 'above' | 'below' | 'unknown'

// This context doesn't appear in src/shared/context.ts because it's not supposed to be publicly
// accessible but rather only through this module's exports.
const FoldStatusContext = React.createContext<FoldStatus>('unknown')

interface IsAboveFoldProviderProps {
  foldStatus: FoldStatus
}

const IsAboveFoldProvider: React.FC<
  React.PropsWithChildren<IsAboveFoldProviderProps>
> = ({ children, foldStatus }) => {
  const existingStatus = useContext(FoldStatusContext)
  // Optimisation to avoid unnecesarily instantiating a provider
  if (foldStatus === 'unknown') {
    return <>{children}</>
  }
  const newStatus = existingStatus === 'below' ? 'below' : foldStatus
  return (
    <FoldStatusContext.Provider value={newStatus}>
      {children}
    </FoldStatusContext.Provider>
  )
}

export function withIsAboveFoldProvider<Props>(
  Component: React.ComponentType<React.PropsWithChildren<Props>>,
  foldStatus: FoldStatus
): React.ComponentType<React.PropsWithChildren<Props>> {
  const WithIsAboveFold: React.FC<React.PropsWithChildren<Props>> = props => (
    <IsAboveFoldProvider foldStatus={foldStatus}>
      <Component {...props} />
    </IsAboveFoldProvider>
  )
  return WithIsAboveFold
}

// Bit of a hack to stop too many re-renders of the withIsAboveFoldProvider HOC where
// the parent uses React.cloneElement to pass props to the child (e.g. <Reel>).
export function useWithIsAboveFoldProvider<Props>(
  Component: React.ComponentType<React.PropsWithChildren<Props>>
): (
  foldStatus: FoldStatus
) => React.ComponentType<React.PropsWithChildren<Props>> {
  const componentsByStatus: Record<
    FoldStatus,
    React.ComponentType<React.PropsWithChildren<Props>>
  > = useMemo(
    () => ({
      above: withIsAboveFoldProvider(Component, 'above'),
      below: withIsAboveFoldProvider(Component, 'below'),
      unknown: withIsAboveFoldProvider(Component, 'unknown'),
    }),
    [Component]
  )
  return useCallback(
    (foldStatus: FoldStatus) => componentsByStatus[foldStatus],
    [componentsByStatus]
  )
}

export function useIsAboveFold(): boolean {
  return useContext(FoldStatusContext) === 'above'
}

export default IsAboveFoldProvider
