//
// This file is auto generated. See ./README.md for details
//
import React from 'react'
import { Icon as ChakraIcon } from '@chakra-ui/react'
import {
  HubResponsiveValue,
  StandardIconSizes,
  HubStyleObject,
  forwardRef,
  IconColors,
} from '@hub/design-system-base'
import { ElectricalVehicleChargingStationLargeSVG } from './svg'

interface ElectricalVehicleChargingStationLargeIconProps {
  boxSize?: HubResponsiveValue<StandardIconSizes>
  sx?: HubStyleObject
  ariaLabel?: string
  color?: IconColors
}

export const ElectricalVehicleChargingStationLargeIcon: React.FC<
  React.PropsWithChildren<ElectricalVehicleChargingStationLargeIconProps>
> = forwardRef<
  ElectricalVehicleChargingStationLargeIconProps,
  typeof ChakraIcon
>(({ boxSize = 'size-icon-sm', sx, ariaLabel, color }, ref) => (
  <ChakraIcon
    ref={ref}
    boxSize={boxSize}
    aria-label={ariaLabel}
    as={ElectricalVehicleChargingStationLargeSVG}
    sx={sx}
    color={color}
  />
))
