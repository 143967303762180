import React from 'react'
import { Frame, StandardRatios } from '@hub/frame'
import { Image } from '@hub/image'
import { Box } from '@hub/box'
import { Video } from '@hub/video'
import {
  As,
  HubStyleObject,
  HubResponsiveValue,
  StandardSizes,
  forwardRef,
} from '@hub/design-system-base'

// We allow passing imageSrcSet without imageSrc as only truly
// ancient browsers don't support srcSet.
// https://caniuse.com/srcset
type Sources =
  | { imageSrc: string; imageSrcSet?: string; videoSrc?: string }
  | { imageSrc?: string; imageSrcSet: string; videoSrc?: string }
  | { imageSrc?: string; imageSrcSet?: string; videoSrc: string }

type FramedMediaProps = {
  as?: As
  sx?: HubStyleObject
  description?: string
  minWidth?: HubResponsiveValue<StandardSizes>
  maxWidth?: HubResponsiveValue<StandardSizes>
  ratio?: HubResponsiveValue<StandardRatios>
  sizes?: string
  loading?: 'lazy' | 'eager'
  onClick?: () => void
} & Sources

export const FramedMedia = forwardRef<FramedMediaProps, typeof Frame>(
  (
    {
      as,
      sx,
      imageSrc,
      imageSrcSet,
      videoSrc,
      description,
      minWidth,
      maxWidth,
      sizes,
      onClick,
      loading,
      ratio = 1,
    },
    ref
  ) => (
    <Frame
      ref={ref}
      as={as}
      ratio={ratio}
      minWidth={minWidth}
      maxWidth={maxWidth}
      sx={sx}
      onClick={onClick}
    >
      <>
        {(imageSrc || imageSrcSet) && (
          <Image
            src={imageSrc}
            srcSet={imageSrcSet}
            description={description}
            sizes={sizes}
            boxSize="size-full"
            loading={loading}
          />
        )}
        {videoSrc && (
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
          >
            <Video url={videoSrc} />
          </Box>
        )}
      </>
    </Frame>
  )
)
