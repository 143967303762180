import { IconButton } from '@hub/button'
import { HamburgerMediumIcon } from '@hub/icon'
import React from 'react'
import { useHeaderProps } from './header'

export const HeaderPrimaryMenuButton: React.FC = () => {
  const { onClickPrimaryMenu } = useHeaderProps()
  return (
    <IconButton
      variant="transparent"
      colorScheme="licorice"
      size="icon-lg"
      icon={<HamburgerMediumIcon boxSize="size-icon-md" />}
      onClick={onClickPrimaryMenu ?? (() => {})}
      aria-label="navigation menu"
    />
  )
}
