import { Segment, SegmentedHit } from './types'

export type FieldOptions =
  | 'title'
  | 'titleWithOptionalStatus'
  | 'text'
  | 'name'
  | 'query'

export function getFieldForSegment(segment: Segment): FieldOptions {
  if (segment === Segment.westfieldProducts) {
    return 'name'
  } else if (segment === Segment.sites) {
    return 'text'
  } else if (segment === Segment.stores) {
    return 'titleWithOptionalStatus'
  } else {
    return 'title'
  }
}

export function getSupplementaryTextForHit(hit: SegmentedHit): string {
  if (hit.segment === 'stores' || hit.segment === 'otherStores') {
    return ` ${hit.centreName}`
  } else if (hit.segment === 'nationalStorefronts') {
    const suffix = ''
    return ` All stores${suffix}`
  }
  return ''
}
