import React from 'react'
import { getCentreAcknowledgementCountry } from '@scentregroup/shared/helpers/get-centre-acknowledgement'

import { Centre } from '@scentregroup/shared/types/centre'
import { makeMailUrl } from '../../helpers'
import { conciergeTitle } from '../../helpers/concierge'
import { Stack } from '@hub/stack'
import { Box } from '@hub/box'
import { Text } from '@hub/text'
import { Link } from '@hub/link'
import { FacebookMediumIcon, InstagramMediumIcon } from '@hub/icon'

export const CentreDetails: React.FC<
  React.PropsWithChildren<{
    centre: Pick<
      Centre,
      | 'slug'
      | 'title'
      | 'physicalAddress'
      | 'suburb'
      | 'state'
      | 'postcode'
      | 'country'
      | 'telephoneNumber'
      | 'emailAddress'
      | 'contactLinks'
    >
    onFindACentre?: () => void
  }>
> = ({ centre, onFindACentre }) => {
  const country = getCentreAcknowledgementCountry(centre.slug)
  return (
    <>
      <Stack direction="column" justify="flex-end">
        <Stack
          direction="row"
          gap={'spacing-md'}
          sx={{ paddingY: 'spacing-md' }}
        >
          <Text as="span" tone="strong" fontSize="font-xl">
            {centre.title}
          </Text>
          {onFindACentre && (
            <Box
              as="button"
              sx={{ textDecoration: 'underline' }}
              onClick={onFindACentre}
            >
              Change&nbsp;centre
            </Box>
          )}
        </Stack>
        <Box sx={{ marginTop: 'spacing-sm' }}>
          {!!country && (
            <>
              {country} <br />
            </>
          )}
          {centre.physicalAddress} <br />
          {centre.suburb} {centre.state} {centre.postcode} <br />
          <Link href={`tel:${centre.telephoneNumber}`}>
            {centre.telephoneNumber}
          </Link>
        </Box>
        <Link
          href={makeMailUrl(centre.emailAddress)}
          sx={{ marginBottom: 'spacing-sm' }}
        >
          {`Contact ${centre.title} ${conciergeTitle(
            centre.country,
            centre.slug
          )}`}
        </Link>
      </Stack>
      <Stack
        direction="row"
        justify={'flex-start'}
        paddingTop={'spacing-md'}
        data-test-id="contact-links"
      >
        {centre.contactLinks
          .filter(({ type }) => type !== 'twitter')
          .map(
            contact =>
              contact.url && (
                <Link
                  href={contact.url}
                  key={contact.type}
                  aria-label={`Go to Westfield ${centre.title}'s ${contact.type}`}
                >
                  {contact.type === 'facebook' && (
                    <FacebookMediumIcon
                      sx={{
                        marginRight: 'spacing-lg',
                        color: 'iconPrimary',
                        height: 'size-icon-md',
                        width: 'size-icon-md',
                      }}
                      key={contact.type}
                      ariaLabel={`Go to Westfield ${centre.title}'s ${contact.type}`}
                    />
                  )}
                  {contact.type === 'instagram' && (
                    <InstagramMediumIcon
                      sx={{
                        color: 'iconPrimary',
                        height: 'size-icon-md',
                        width: 'size-icon-md',
                      }}
                      key={contact.type}
                      ariaLabel={`Go to Westfield ${centre.title}'s ${contact.type}`}
                    />
                  )}
                </Link>
              )
          )}
      </Stack>
    </>
  )
}
