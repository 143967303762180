import React, { useState } from 'react'
import useInterval from '@use-it/interval'

type Child = React.ReactElement | null

interface TimerProps {
  children: Child[]
  timing: number | ((i: number) => number)
  doLoop?: boolean
}

function makeTickWhichChild(
  children: Child[],
  doLoop?: boolean
): (whichChild: number) => number {
  return function tickWhichChild(whichChild: number): number {
    if (whichChild < children.length - 1) {
      return whichChild + 1
    } else if (doLoop) {
      return 0
    } else {
      return whichChild
    }
  }
}

const TimedDisplay: React.FC<React.PropsWithChildren<TimerProps>> = ({
  children,
  timing,
  doLoop,
}) => {
  const timingFn = typeof timing === 'function' ? timing : () => timing
  const [whichChild, setWhichChild] = useState(0)
  const delay = timingFn(whichChild + 1)
  const tickWhichChild = makeTickWhichChild(children, doLoop)
  useInterval(() => {
    setWhichChild(tickWhichChild)
  }, delay)
  return children[whichChild]
}

export default TimedDisplay
