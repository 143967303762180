import React, { ReactElement } from 'react'
import { Text } from '@hub/text'
import { AlgoliaHit } from './types'
import { FieldOptions } from './display-utils'
import { Box } from '@hub/box'
import { HubStyleObject } from '@hub/design-system-base'

function getHighlightRegex(doUseAutocompleteTags: boolean): RegExp {
  // The search indexes need to be configured with this. The default is <em> and
  // </em> and we could use dangerouslySetInnerHTML, but that makes me
  // uncomfortable.
  const highlightStart = doUseAutocompleteTags
    ? '__aa-highlight__'
    : '__HL_START__'
  const highlightEnd = doUseAutocompleteTags
    ? '__/aa-highlight__'
    : '__HL_END__'
  return new RegExp(`${highlightStart}(.*?)${highlightEnd}`)
}

interface TextWithHighlightsWrappedAndStyledProps {
  field: FieldOptions
  hit: {
    _highlightResult?: AlgoliaHit['_highlightResult']
    title?: string
    titleWithOptionalStatus?: string
    text?: string
  }
  isTextBold: boolean
  doUseAutocompleteTags?: boolean
  // False positive from the lint rule :-( Maybe from the Omit?
  // eslint-disable-next-line react/no-unused-prop-types
  supplementaryText?: string
  sx?: HubStyleObject
}

const MaybeHighlighted: React.FC<
  React.PropsWithChildren<{
    isHighlighted: boolean
  }>
> = props =>
  props.isHighlighted ? (
    <em>{props.children}</em>
  ) : (
    <span>{props.children}</span>
  )

// We can maybe clean up the type safety issues here on a later pass
export const TextWithJustHighlights: React.FC<
  React.PropsWithChildren<
    Omit<TextWithHighlightsWrappedAndStyledProps, 'isTextBold'>
  >
> = ({ hit, field, doUseAutocompleteTags, supplementaryText }) => {
  const highlight = hit._highlightResult?.[field]
  const text = (
    highlight
      ? hit._highlightResult?.[field]?.value
      : hit[field as keyof typeof hit]
  ) as string
  const highlightRegex = getHighlightRegex(Boolean(doUseAutocompleteTags))
  // Elements will be a list of [out, in, out, in, ...]. Eg, if we search for
  // "shoe" in "nike shoes", elements will contain ["nike ", "shoe", "s"]
  const elements = text ? text.split(highlightRegex) : []
  const htmlContent: ReactElement[] = []
  for (let i = 0; i < elements.length; i++) {
    const isHighlighted = i % 2 === 1 // eslint-disable-line no-magic-numbers
    htmlContent.push(
      <MaybeHighlighted isHighlighted={isHighlighted} key={i.toString()}>
        {elements[i]}
      </MaybeHighlighted>
    )
  }
  return (
    <>
      {htmlContent}
      {supplementaryText && (
        <Text as="span" color="manatee">
          {supplementaryText}
        </Text>
      )}
    </>
  )
}

const TextWithHighlightsWrappedAndStyled: React.FC<
  React.PropsWithChildren<TextWithHighlightsWrappedAndStyledProps>
> = ({ sx, isTextBold, hit, field, doUseAutocompleteTags }) => (
  <Box sx={{ ...sx, ...(isTextBold ? { 'font-weight': 'bolder' } : {}) }}>
    <TextWithJustHighlights
      hit={hit}
      field={field}
      doUseAutocompleteTags={doUseAutocompleteTags}
    />
  </Box>
)

export default TextWithHighlightsWrappedAndStyled
