import React from 'react'
import { Image as ChakraImage } from '@chakra-ui/image'
import { HubStyleObject, forwardRef } from '@hub/design-system-base'

interface ImageProps {
  sx?: HubStyleObject
  description?: string
  boxSize?: string
  src?: string
  srcSet?: string
  sizes?: string
  loading?: 'lazy' | 'eager'
}

export const Image = forwardRef<ImageProps, typeof ChakraImage>(
  ({ sx, src, srcSet, description, boxSize, loading, sizes }, ref) => (
    <ChakraImage
      ref={ref}
      src={src}
      srcSet={srcSet}
      boxSize={boxSize}
      alt={description}
      sx={sx}
      loading={loading}
      sizes={sizes}
    />
  )
)
