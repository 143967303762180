import React from 'react'
import { Box } from '@hub/box'
import {
  FlexProps,
  ResponsiveValue,
  Stack as ChakraStack,
} from '@chakra-ui/react'
import {
  As,
  HubResponsiveValue,
  HubStyleObject,
  StandardSizes,
  StandardSpacings,
  forwardRef,
  mapResponsiveValue,
} from '@hub/design-system-base'

interface StackProps {
  gap?: HubResponsiveValue<StandardSpacings>
  sx?: HubStyleObject
  as?: As
  divider?: React.ComponentProps<typeof ChakraStack>['divider']
  className?: string
  direction?: HubResponsiveValue<'column' | 'row'>
  /**
   * The minimum width of children, merged into the child's `sx` prop.
   */
  minWidth?: HubResponsiveValue<StandardSizes>
  /**
   * The maximum width of children, merged into the child's `sx` prop.
   */
  maxWidth?: HubResponsiveValue<StandardSizes>
  /**
   * Wrap each child in a Hub <Box>. Can be useful when children styles
   * conflict with the styles a <Stack> needs to inject.
   */
  shouldWrapChildren?: boolean
  justify?: FlexProps['justify']
  align?: FlexProps['align']
  flexBasis?: FlexProps['basis']
  flexGrow?: FlexProps['grow']
  children?:
    | React.ReactElement<{ sx?: HubStyleObject }>
    | React.ReactNode
    | (React.ReactElement<{ sx?: HubStyleObject }> | React.ReactNode)[]
}

type FlexGrow = React.ComponentProps<typeof ChakraStack>['flexGrow']
type ScalarFlexGrow = null | FlexGrow extends ResponsiveValue<infer U>
  ? U
  : never

type ScalarDirection = 'row' | 'column' | undefined | null

/*
If flexGrow has been explicitly specified we respect that value. Otherwise:
* - For a vertical stack we use a default flexGrow of 0 (the standard default)
* - For a horizontal stack we used a default flexGrow of 1
* - If direction is also unspecified, the flexGrow will be unspecified
*/

export const getFlexGrowScalar = (
  baseFlexGrow: ScalarFlexGrow,
  direction: ScalarDirection
): ScalarFlexGrow | null => {
  if (baseFlexGrow !== null && baseFlexGrow !== undefined) {
    return baseFlexGrow
  }
  if (direction === null || direction === undefined) {
    return direction
  }
  const verticals = ['column', 'column-reverse']
  return verticals.includes(direction) ? 0 : 1
}

const getFlexGrowResponsive = (
  baseFlexGrow: FlexGrow,
  direction: HubResponsiveValue<'row' | 'column'>,
  shouldWrapChildren: boolean
): { flexGrow?: FlexGrow } => {
  if (shouldWrapChildren) {
    return {}
  }
  if (typeof baseFlexGrow === 'object') {
    return { flexGrow: baseFlexGrow }
  }
  const scalarTransform = (direction: ScalarDirection): ScalarFlexGrow =>
    getFlexGrowScalar(baseFlexGrow, direction)
  return {
    flexGrow: mapResponsiveValue(direction, scalarTransform),
  }
}

export const Stack = forwardRef<StackProps, typeof ChakraStack>(
  (
    {
      gap = 'spacing-none',
      sx,
      as,
      className,
      id,
      children,
      direction = 'column',
      minWidth,
      maxWidth,
      divider,
      shouldWrapChildren = true,
      justify,
      align,
      flexBasis,
      flexGrow,
      flexShrink,
      ...props
    },
    ref
  ) => (
    <ChakraStack
      ref={ref}
      shouldWrapChildren={shouldWrapChildren}
      as={as}
      spacing={gap}
      direction={direction}
      id={id}
      className={className}
      divider={divider}
      justify={justify}
      align={align}
      sx={sx}
      {...props}
    >
      {React.Children.map(
        children,
        child =>
          React.isValidElement<StackProps>(child) &&
          React.cloneElement(child, {
            sx: {
              minWidth,
              maxWidth,
              ...{ flexBasis, flexShrink },
              ...getFlexGrowResponsive(flexGrow, direction, shouldWrapChildren),
              ...(child?.props?.sx ?? {}),
            },
          })
      )}
    </ChakraStack>
  )
)

interface StackSpacerProps {
  sx?: HubStyleObject
  as?: As
}

export const StackSpacer: React.FC<React.PropsWithChildren<StackSpacerProps>> = ({
  sx,
  as: AsComp = Box,
}) => (
  // We have to wrap the `as` component to ensure our styles are injected
  // correctly
  (<Box
    as={forwardRef((props, ref) => (
      <AsComp
        ref={ref}
        sx={{
          ...sx,
          ...props.sx,
          // Forcibly override Chakra's margins
          marginInlineStart: '0 !important',
          marginLeft: '0 !important',
          marginTop: '0 !important',
          flexGrow: 1,
          flexShrink: 1,
          minWidth: 'auto',
          maxWidth: 'none',
        }}
      />
    ))}
  />)
)

Stack.displayName = 'Stack'
