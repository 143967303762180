import {
  ACTIONS,
  categories,
  labels,
} from '../../helpers/analytics-utils/constants'
import trackClickEvent from '../../helpers/analytics-utils/track-event'

type EventTracker = (category?: string, action?: string, label?: string) => void

export const createTrackUniversalFooterLinkClickTracker =
  (tracker: EventTracker) =>
  (label: string): void => {
    tracker(
      categories.UNIVERSAL,
      ACTIONS.clicked,
      labels.createFooterLink(label)
    )
  }

export const trackUniversalFooterLinkClick =
  createTrackUniversalFooterLinkClickTracker(trackClickEvent)
