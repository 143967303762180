import { Link } from '@hub/link'
import React from 'react'
import { useHeaderProps } from './header'
import { Logo } from '../logo'

export const HeaderLogo: React.FC = () => {
  const {
    logoLink: link,
    logoSlug: slug,
    logoLabel: label,
    onClickLogo: onClick,
    taskMode,
  } = useHeaderProps()
  if (taskMode) {
    return <Logo variant={slug === 'gift-cards' ? slug : 'account'} />
  }
  const variant =
    slug === 'account' || slug === 'gift-cards'
      ? slug
      : slug
      ? 'centre'
      : 'national'
  if (!link) {
    return <Logo variant={slug ? 'centre' : variant} centreSlug={slug} />
  }
  return (
    <Link href={link} onClick={onClick} ariaLabel={label}>
      <Logo
        variant={variant}
        centreSlug={variant === 'centre' ? slug : undefined}
      />
    </Link>
  )
}
