import {
  HubResponsiveValue,
  HubStyleObject,
  StandardIconSizes,
} from '@hub/design-system-base'
import React from 'react'
import { WestfieldLogo } from './westfield-logo'
import { WestfieldLogoRed } from './westfield-logo-red'
import { WestfieldMembershipLogo } from './westfield-membership-logo'

interface LogoProps {
  variant?:
    | 'direct'
    | 'westfield'
    | 'westfield-red'
    | 'westfield-plus'
    | 'westfield-online'
    | 'westfield-membership'
  boxSize?: HubResponsiveValue<StandardIconSizes>
  sx?: HubStyleObject
  mono?: boolean
  contrast?: boolean
  label?: string
  svg?: React.ComponentType<React.SVGAttributes<SVGElement>>
}

export const Logo: React.FC<React.PropsWithChildren<LogoProps>> = ({
  variant,
  boxSize,
  sx,
  label,
  svg,
}) => {
  switch (variant) {
    case 'westfield':
      return <WestfieldLogo sx={sx} boxSize={boxSize} label={label} />

    case 'westfield-red':
      return (
        <WestfieldLogoRed svg={svg} sx={sx} boxSize={boxSize} label={label} />
      )

    case 'westfield-membership':
      return <WestfieldMembershipLogo sx={sx} boxSize={boxSize} label={label} />

    default:
      return (
        <WestfieldLogoRed boxSize={undefined} svg={svg} sx={sx} label={label} />
      )
  }
}
