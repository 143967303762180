import { AutocompleteReshapeSource } from '@algolia/autocomplete-core'
import { partition, sortBy, uniq } from 'lodash'
import { Segment } from '../../types'
import { HEADINGS } from '../../group-and-render-segments/headings'

const isString = (value: unknown): value is string => typeof value === 'string'

const getIndexOrLength = (indexFrom: string[], value: unknown): number => {
  if (!isString(value)) {
    return indexFrom.length
  }
  const index = indexFrom.indexOf(value)
  return index === -1 ? indexFrom.length : index
}

type Sources<BaseItem extends Record<string, unknown>> = Pick<
  AutocompleteReshapeSource<BaseItem>,
  'sourceId' | 'getItems'
>[]

const baseItemsToRetailerIds = <BaseItem extends Record<string, unknown>>(
  items: BaseItem[]
): string[] => items.map(item => item.retailerId).filter(isString)

function findRetailerOrder<BaseItem extends Record<string, unknown>>(
  preMergeSources: Sources<BaseItem>,
  searchContext: { centre?: { sys: { id: string } } }
): string[] {
  const getSegmentItems = (segment: Segment): BaseItem[] =>
    preMergeSources.find(source => source.sourceId === segment)?.getItems() ??
    []
  const { nationalStorefronts, stores } = Segment
  const relevantRetailerOrder: string[] = baseItemsToRetailerIds(
    getSegmentItems(nationalStorefronts)
  )
  const { centre } = searchContext
  if (!centre) {
    return relevantRetailerOrder
  }
  const retailersInCentre: string[] = baseItemsToRetailerIds(
    getSegmentItems(stores).filter(item => item.centre === centre.sys.id)
  )
  const [relevantRetailersInCentre, relevantRetailersNotInCentre] = partition(
    relevantRetailerOrder,
    (retailerId: string) => retailersInCentre.indexOf(retailerId) >= 0
  )
  return uniq([
    ...relevantRetailersInCentre,
    ...retailersInCentre,
    ...relevantRetailersNotInCentre,
  ])
}

export function makePostMergeSort<BaseItem extends Record<string, unknown>>(
  preMergeSources: Sources<BaseItem>,
  searchContext: { centre?: { sys: { id: string } } }
): (items: BaseItem[], mergedSourceId: string) => BaseItem[] {
  const retailerOrder = findRetailerOrder(preMergeSources, searchContext)
  const segmentOrder = [Segment.stores, Segment.nationalStorefronts]
  return function postMergeSort(
    items: BaseItem[],
    mergedSourceId: string
  ): BaseItem[] {
    if (mergedSourceId !== HEADINGS.topStores) {
      return items
    }
    return sortBy(items, [
      item => getIndexOrLength(retailerOrder, item.retailerId),
      item => getIndexOrLength(segmentOrder, item.segment),
    ])
  }
}
