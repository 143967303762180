import { Box } from '@hub/box'
import { Stack } from '@hub/stack'
import React from 'react'

export const NavigationPanelHeader: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <Box
      paddingTop={['spacing-md', null, 'spacing-lg']}
      paddingLeft={['spacing-md', null, 'spacing-none']}
      paddingRight={['spacing-md', null, 'spacing-none']}
      flex={0}
    >
      <Stack
        shouldWrapChildren={false}
        minHeight="size-8"
        width="size-full"
        sx={{
          gap: 'spacing-sm',
          justifyContent: 'space-between',
          alignItems: ['end', null, 'start'],
          flexDirection: ['row-reverse', null, 'row'],
        }}
      >
        {children}
      </Stack>
    </Box>
  )
}
