import React from 'react'
import { Icon as ChakraIcon } from '@chakra-ui/react'
import {
  HubResponsiveValue,
  StandardIconSizes,
  HubStyleObject,
  forwardRef,
} from '@hub/design-system-base'
import { default as WestfieldLogoRedSVG } from './assets/westfield-logo-red.inline.svg'

interface WestfieldLogoRedProps {
  boxSize?: HubResponsiveValue<StandardIconSizes>
  sx?: HubStyleObject
  label?: string
  svg?: React.ComponentType<React.SVGAttributes<SVGElement>>
}

export const WestfieldLogoRed: React.FC<
  React.PropsWithChildren<WestfieldLogoRedProps>
> = forwardRef<WestfieldLogoRedProps, typeof ChakraIcon>(
  ({ boxSize, sx, label = 'Westfield', svg }, ref) => (
    <ChakraIcon
      aria-label={label}
      ref={ref}
      minW={boxSize}
      maxH={boxSize}
      w="size-auto"
      h="size-auto"
      as={svg ?? WestfieldLogoRedSVG}
      sx={sx}
    />
  )
)
