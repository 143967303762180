import { injectTheme } from '@hub/design-system-base'
import { inputTheme } from './theme'

injectTheme('Input', inputTheme)

export { Input } from './input'
export type { InputProps } from './input'
export { InputGroup } from './input-group'
export { InputLeftElement } from './input-left-element'
export { InputRightElement } from './input-right-element'
