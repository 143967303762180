const parts = ['field', 'addon']

const baseStyle = {
  field: {
    width: 'size-full',
    minWidth: 0,
    position: 'relative',
    appearance: 'none',
    transitionProperty: 'common',
    transitionDuration: 'normal',
  },
}

const size = {
  lg: {
    fontSize: 'font-large',
    px: 'spacing-xs',
  },

  md: {
    fontSize: 'font-body',
    px: 'spacing-sm',
    py: 'spacing-xs',
    height: 'size-7',
  },

  sm: {
    fontSize: 'font-small',
    px: 'spacing-xs',
  },

  xs: {
    fontSize: 'font-tiny',
    px: 'spacing-xs',
  },
}

const sizes = {
  lg: {
    field: size.lg,
    addon: size.lg,
  },
  md: {
    field: size.md,
    addon: size.md,
  },
  sm: {
    field: size.sm,
    addon: size.sm,
  },
  xs: {
    field: size.xs,
    addon: size.xs,
  },
}

const variantUnstyled = {
  field: {
    bg: 'transparent',
    px: 0,
    height: 'auto',
  },
  addon: {
    bg: 'transparent',
    px: 0,
    height: 'auto',
  },
}

const variants = {
  outline: {
    field: {
      border: '1px solid',
      borderColor: 'licorice',
      bg: 'inherit',
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      _invalid: {
        borderColor: 'error',
        '--hub-input-box-shadow-color': 'error',
        boxShadow: `0 0 0 1px var(--hub-input-box-shadow-color)`,
      },
      _focus: {
        zIndex: 'docked',
      },
    },
    addon: {
      bg: 'quartz',
    },
  },
  filled: {
    field: {
      border: '2px solid',
      borderColor: 'transparent',
      bg: 'quartz',
      _hover: {
        bg: 'manatee',
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      _invalid: {
        borderColor: 'error',
      },
    },
    addon: {
      bg: 'quartz',
    },
  },
  flushed: {
    field: {
      borderBottom: '1px solid',
      borderColor: 'inherit',
      borderRadius: 0,
      px: 0,
      bg: 'transparent',
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _invalid: {
        borderColor: 'error',
        '--hub-input-box-shadow-color': 'error',
        boxShadow: `0 0 0 1px var(--hub-input-box-shadow-color)`,
      },
    },
    addon: {
      borderRadius: 0,
      px: 0,
      bg: 'transparent',
    },
  },
  unstyled: variantUnstyled,
}

const defaultProps = {
  size: 'md',
  variant: 'outline',
}

export const inputTheme = {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
}
