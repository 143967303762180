import { Accordion } from '@hub/accordion'
import { Stack } from '@hub/stack'
import React from 'react'
import {
  NavigationContextProvider,
  useNavigationContext,
} from './navigation-context'

type NavigationPanelBodyProps = {
  layout?: 'accordion' | 'panel'
}

export const NavigationPanelBody: React.FC<
  React.PropsWithChildren<NavigationPanelBodyProps>
> = ({ children, layout = 'panel' }) => {
  const { isSubPanel } = useNavigationContext()
  const [accordionExpandedIndex, setExpandedIndex] = React.useState<
    number | number[]
  >(-1)
  if (layout === 'accordion') {
    return (
      <NavigationContextProvider
        value={{ panelLayout: 'accordion', accordionExpandedIndex }}
      >
        <Accordion allowToggle onChange={setExpandedIndex}>
          <Stack
            shouldWrapChildren={false}
            width="100%"
            paddingLeft={[20, null, isSubPanel ? 'spacing-md' : 'spacing-3xl']}
            paddingRight={[20, null, 'spacing-md']}
            paddingTop="spacing-md"
            paddingBottom="spacing-md"
          >
            {children}
          </Stack>
        </Accordion>
      </NavigationContextProvider>
    )
  }
  return (
    <Stack
      shouldWrapChildren={false}
      sx={{
        flexGrow: 1,
        alignItems: 'end',
        overflowY: 'auto',
        scrollBehavior: 'smooth',
      }}
    >
      <Stack
        shouldWrapChildren={false}
        width="100%"
        paddingLeft={[20, null, isSubPanel ? 'spacing-md' : 'spacing-3xl']}
        paddingRight={[20, null, 'spacing-md']}
        paddingTop="spacing-md"
        paddingBottom="spacing-md"
      >
        <NavigationContextProvider value={{ isSubPanel: true }}>
          {children}
        </NavigationContextProvider>
      </Stack>
    </Stack>
  )
}
