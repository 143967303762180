import React, { ReactNode } from 'react'
import { Box } from '@hub/box'
import { Text } from '@hub/text'
import { AlertMediumIcon } from '@hub/icon'
import { Grid } from '@hub/grid'
import { Spinner } from '@hub/spinner'
import { H1, H3 } from '@hub/heading'

import { kebabCase, lowerCase } from 'lodash'
import { FindACentreBanner } from '../../hub-components/find-a-centre-banner'
import { RenderContext, RenderedSectionResults } from './types'
import { orderResults } from './result-order'
import { useCentres } from '../../centres'
import TimedDisplay from '../timed-display'

const SPINNER_DELAY = 250

interface SearchResultsProps {
  context: RenderContext
  isLoading: boolean
  showNoResults: boolean
  results: RenderedSectionResults[]
  showFixedQuery?: string
}

const Loading: React.FC<React.PropsWithChildren<unknown>> = () => (
  <TimedDisplay timing={SPINNER_DELAY}>
    {null}
    <Spinner
      variant="large"
      sx={{
        width: 'size-lg',
        height: 'size-lg',
        marginTop: 'spacing-xl',
      }}
    />
  </TimedDisplay>
)

const NoResults: React.FC<React.PropsWithChildren> = () => {
  return (
    <Box
      sx={{
        fontSize: 'font-lg',
        maxWidth: '100%',
        textDecoration: 'none',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}
    >
      <AlertMediumIcon
        sx={{ marginBottom: 'spacing-md' }}
        boxSize="size-icon-md"
      />
      <H3>No results found.</H3>
      <Text
        isBodyText
        sx={{
          fontSize: 'font-md',
        }}
      >
        Try a new search or select a centre to find more results.
      </Text>
    </Box>
  )
}

const SectionContent: React.FC<{
  heading: string
  hits: ReactNode[]
  showFixedQuery?: string
}> = ({ heading, hits }) => {
  const templateColumns =
    heading === 'Westfield Gift Cards'
      ? ['1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr 1fr']
      : ['1fr', null, '1fr 1fr', null, '1fr 1fr 1fr']
  return (
    <Grid
      as="nav"
      gap="spacing-md"
      templateColumns={templateColumns}
      sx={{ lineHeight: '1.3' }}
    >
      {hits}
    </Grid>
  )
}

const SomeResults: React.FC<
  React.PropsWithChildren<{
    sortedResults: RenderedSectionResults[]
    showFixedQuery?: string
  }>
> = ({ sortedResults, showFixedQuery }) => (
  <>
    {sortedResults.map(({ heading, hits, queryId, indexName }, index) => (
      <Box
        as="section"
        sx={{
          width: '100%',
          marginX: 'auto',
          marginTop: index === 0 ? 'spacing-none' : 'spacing-3xl',
        }}
        key={index}
        data-test-id={`search-contents-${kebabCase(lowerCase(heading))}`}
        data-insights-index={indexName}
        data-insights-query-id={queryId}
      >
        <H3 as="h2" sx={{ paddingBottom: 'spacing-md' }}>
          {heading}
        </H3>
        <SectionContent
          heading={heading}
          hits={hits}
          showFixedQuery={showFixedQuery}
        />
      </Box>
    ))}
  </>
)

const Contents: React.FC<React.PropsWithChildren<SearchResultsProps>> = ({
  context,
  isLoading,
  showNoResults,
  results,
  showFixedQuery,
}) => {
  if (isLoading) {
    return <Loading />
  } else if (showNoResults) {
    return <NoResults />
  } else {
    const sortedResults = orderResults(
      results,
      context,
      'results',
      results => results.heading
    )
    return (
      <SomeResults
        sortedResults={sortedResults}
        showFixedQuery={showFixedQuery}
      />
    )
  }
}

const SearchResults: React.FC<
  React.PropsWithChildren<SearchResultsProps>
> = props => {
  const centres = useCentres()
  const numberOfHits = props.results
    .map(el => el.hits.length)
    .reduce((partialSum, a) => partialSum + a, 0)
  const heading = `${numberOfHits} Results for "${props.showFixedQuery}"`
  return (
    <Box
      sx={{
        flex: '1 1',
        '-webkit-overflow-scrolling': 'touch',
        bgColor: 'surfaceTertiary',
        width: '100%',
      }}
    >
      {props.showFixedQuery !== undefined && (
        <>
          <H1
            textAlign="left"
            fontWeight="bold"
            sx={{ marginBottom: 'spacing-xl' }}
          >
            {heading}
          </H1>
          {!props.context.centre && centres.length > 0 && (
            <FindACentreBanner
              searchTerm={props.showFixedQuery}
              centres={centres}
            />
          )}
        </>
      )}
      <Contents {...props} />
    </Box>
  )
}

export default SearchResults
