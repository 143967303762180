import { SystemStyleObject, keyframes } from '@chakra-ui/react'

type Keyframes = ReturnType<typeof keyframes>

export const getFadeKeyframes = (
  isOpen: boolean,
  opacity: number
): Keyframes => {
  if (isOpen) {
    return keyframes`
      from {
        opacity: 0;
        display: none;
      }
      to {
        opacity: ${opacity};
        display: block;
      }
    `
  } else {
    return keyframes`
      from {
        opacity: ${opacity};
        display: block;
      }
      to {
        opacity: 0;
        display: none;
      }
    `
  }
}

export const getTopSlideStyle = (
  isOpen: boolean,
  height: number
): SystemStyleObject => {
  if (isOpen) {
    return {
      transform: `translateY(0px)`,
      clipPath: 'inset(0px 0px 0px 0px)',
      opacity: 1,
    }
  } else {
    return {
      transform: `translateY(-${height}px)`,
      clipPath: `inset(${height}px 0px 0px 0px)`,
      opacity: 0,
    }
  }
}

export const getBottomSlideStyle = (
  isOpen: boolean,
  height: number
): SystemStyleObject => {
  if (isOpen) {
    return {
      transform: `translateY(0px)`,
      opacity: 1,
      clipPath: `inset(0px 0px 0px 0px)`,
    }
  } else {
    return {
      transform: `translateY(${height}px)`,
      opacity: 0,
      clipPath: `inset(0px 0px ${height}px 0px)`,
    }
  }
}

export const getLeftSlideStyle = (
  isOpen: boolean,
  width: number
): SystemStyleObject => {
  if (isOpen) {
    return {
      transform: 'translateX(0px)',
      clipPath: 'inset(0px -100vw 0px 0px)',
      opacity: 1,
    }
  } else {
    return {
      transform: `translateX(-${width}px)`,
      clipPath: `inset(0px -100vw 0px ${width}px)`,
      opacity: 0,
    }
  }
}

export const getRightSlideStyle = (
  isOpen: boolean,
  width: number
): SystemStyleObject => {
  if (isOpen) {
    return {
      transform: `translateX(0)`,
      clipPath: 'inset(0px 0px 0px -100vw)',
      opacity: 1,
    }
  } else {
    return {
      transform: `translateX(${width}px)`,
      clipPath: `inset(0px ${width}px 0px  -100vw)`,
      opacity: 0,
    }
  }
}
