import { useMemo, useCallback } from 'react'
import Fuse from 'fuse.js'

import { Centre as BaseCentreType } from '@scentregroup/shared/helpers/centre'

type CentreType = Pick<BaseCentreType, 'title' | 'state'>

const defaultCompare = <Centre extends CentreType>(
  centreA: Centre,
  centreB: Centre
): number => {
  const stateCompare = centreA.state.localeCompare(centreB.state)
  if (stateCompare) {
    return stateCompare
  } else {
    return centreA.title.localeCompare(centreB.title)
  }
}

export const useCentreFilter = <Centre extends CentreType>(
  centres: Centre[]
): ((query?: string) => Centre[]) => {
  const sortedCentresWithSearchTitle: Centre[] = useMemo(
    () =>
      centres
        .map(centre => ({
          ...centre,
          searchTitle: centre?.title?.split?.(' ') ?? [],
        }))
        .sort(defaultCompare),
    [centres]
  )

  const fuse = useMemo(
    () =>
      new Fuse(sortedCentresWithSearchTitle, {
        shouldSort: true,
        threshold: 0.3,
        location: 0,
        keys: [
          { name: 'searchTitle', weight: 0.8 },
          { name: 'state', weight: 0.1 },
          // We look at title as well as the split up version
          // searchTitle because otherwise queries with spaces
          // ("Mt Druitt", "Bondi Junction") return no results
          { name: 'title', weight: 0.1 },
        ],
      }),
    [sortedCentresWithSearchTitle]
  )

  return useCallback(
    (query: string | undefined) =>
      query ? fuse.search(query) : sortedCentresWithSearchTitle,
    [sortedCentresWithSearchTitle, fuse]
  )
}
