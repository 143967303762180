import { useTheme, useToken } from '@hub/design-system-base'

interface ISizes {
  sm?: number | string
  md?: number | string
  lg?: number | string
  xl?: number | string
  ['2xl']?: number
}
export type Sizes = number | string | (number | string | null)[] | ISizes

/**
 * generate the <img> 'sizes' prop string in various ways
 *
 * sizes is empty will return the max fixed width (normally not what is wanted)
 * sizes is a string will return the string; this allows using custom breakpoints
 * sizes is a number will return the number in pixels; used for images that have a fixed width
 * sizes is an array maps the array values to theme breakpoints; use null to skip a breakpoint
 *   so the breakpoint uses the same size as the previous
 * sizes is an object using theme breakpoint names as keys will map sizes to theme breakpoints
 */
export function useImageSizes(sizes?: Sizes): string {
  const maxFixedWidth = useToken('sizes', 'size-max-fixed')
  const theme = useTheme()
  const breakpoints = theme.__breakpoints?.normalized

  const INHERIT_VALUE_FROM_PREVIOUS_BREAKPOINT_PLACEHOLDER = 'NO VALUE'
  const sizesWithPlaceholders = Array.isArray(sizes)
    ? [sizes]
        .flatMap(s => s)
        .map(s => s || INHERIT_VALUE_FROM_PREVIOUS_BREAKPOINT_PLACEHOLDER)
    : []
  const sizeValues = useToken('sizes', sizesWithPlaceholders) as (
    | string
    | number
  )[]

  if (!breakpoints) {
    return `${maxFixedWidth}px`
  }
  if (typeof sizes === 'string') {
    return sizes
  }
  if (typeof sizes === 'number') {
    return `${sizes}px`
  }
  if (typeof sizes !== 'object') {
    return `${maxFixedWidth}px`
  }
  if (Array.isArray(sizes)) {
    if (!breakpoints?.length) {
      return `${maxFixedWidth}px`
    }
    if (sizeValues.length === 0) {
      return `${maxFixedWidth}px`
    }
    const [defaultSize] = sizeValues.slice(-1)
    return sizeValues
      .slice(0, -1)
      .map((size, i) => {
        if (size === null) {
          return null
        }
        const width = typeof size === 'string' ? size : `${size}px`
        return `(max-width: calc(${breakpoints[i + 1]} - 1px)) ${width}`
      })
      .filter(s => s !== null)
      .concat(
        typeof defaultSize === 'string' ? defaultSize : `${defaultSize}px`
      )
      .join(', ')
  }
  const entries = Object.entries(breakpoints).filter(([k]) =>
    isNaN(parseInt(k))
  ) as [keyof ISizes, string][]
  return entries
    .reduce(
      ([result, sizes], [breakpoint, value]) => {
        if (!sizes[breakpoint]) {
          return [result, sizes] as [string[], ISizes]
        }
        const { [breakpoint]: size, ...rest } = sizes
        if (size === undefined) {
          return [result, sizes] as [string[], ISizes]
        }
        const width = typeof size === 'string' ? size : `${size}px`
        if (Object.keys(rest).length === 0) {
          return [result.concat(width), rest] as [string[], ISizes]
        }
        return [
          result.concat(`(max-width: calc(${value} - 1px)) ${width}`),
          rest,
        ] as [string[], ISizes]
      },
      [[], sizes] as [string[], ISizes]
    )[0]
    .join(', ')
}
