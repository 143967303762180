import {
  Navigation,
  NavigationBar,
  NavigationButton,
  NavigationCustomMenu,
  NavigationLink,
  NavigationMenu,
  NavigationMenuList,
} from '@hub/navigation'
import { Stack } from '@hub/stack'
import React from 'react'
import { isDefinedAndNotNull } from '../../../lib'
import { useIsRouteChanging } from '../../../next/router'
import MegaMenu from '../../mega-menu'
import {
  INavigationMegaMenu,
  INavigationMenuItem,
  INavigationSimpleMenu,
  isNavigationLink,
  isNavigationMegaMenu,
  isNavigationSimpleMenu,
} from '../types'

type HeaderNavigationProps = {
  isOpen: boolean
  trigger?: 'click' | 'hover'
  fadeDuration?: number
  primaryItems?: INavigationMenuItem[]
  secondaryItems?: INavigationMenuItem[]
  onOpen?: () => void
  onClose?: () => void
  onClickLink?: (event: React.MouseEvent, path: INavigationMenuItem[]) => void
}

interface NavigationBarChildMegaMenuProps {
  item: INavigationMegaMenu
  onClickLink?: (event: React.MouseEvent, path: INavigationMenuItem[]) => void
}

const NavigationBarChildMegaMenu: React.FC<NavigationBarChildMegaMenuProps> = ({
  item,
  onClickLink,
}) => {
  return (
    <NavigationCustomMenu>
      <NavigationButton>{item.title}</NavigationButton>
      {OverlayContentBox => (
        <OverlayContentBox
          backgroundColor="surfaceBrandPrimary"
          sx={{ minHeight: ['100vh', null, 'auto'] }}
        >
          <MegaMenu
            items={item.items}
            feature={item.feature}
            onClickFeature={event =>
              item.feature.link &&
              onClickLink?.(event, [
                item,
                {
                  label: item.feature.title ?? item.feature.link.label,
                  url: item.feature.link.url,
                },
              ])
            }
            onClickLink={(event, path) =>
              path.length === 2 && // megamenu columns titles aren't tracked (to match legacy behaviour)
              onClickLink?.(event, [item, ...path])
            }
          />
        </OverlayContentBox>
      )}
    </NavigationCustomMenu>
  )
}

interface NavigationBarChildSimpleMenuProps {
  item: INavigationSimpleMenu
  onClickLink?: (event: React.MouseEvent, path: INavigationMenuItem[]) => void
}

const NavigationBarChildSimpleMenu: React.FC<
  NavigationBarChildSimpleMenuProps
> = ({ item, onClickLink }) => {
  return (
    <NavigationMenu>
      <NavigationButton>{item.title}</NavigationButton>
      <NavigationMenuList>
        {item.items.map(
          (subItem, key) =>
            isNavigationLink(subItem) && (
              <NavigationLink
                key={key}
                href={subItem.url}
                onClick={event => onClickLink?.(event, [item, subItem])}
              >
                {subItem.label}
              </NavigationLink>
            )
        )}
      </NavigationMenuList>
    </NavigationMenu>
  )
}

interface NavigationBarChildProps {
  item: INavigationMenuItem
  onClickLink?: (event: React.MouseEvent, path: INavigationMenuItem[]) => void
}

const NavigationBarChild: React.FC<NavigationBarChildProps> = ({
  item,
  onClickLink,
}) => {
  if (isNavigationMegaMenu(item)) {
    return <NavigationBarChildMegaMenu item={item} onClickLink={onClickLink} />
  } else if (isNavigationSimpleMenu(item)) {
    return (
      <NavigationBarChildSimpleMenu item={item} onClickLink={onClickLink} />
    )
  } else if (isNavigationLink(item)) {
    return (
      <NavigationLink
        href={item.url}
        onClick={event => onClickLink?.(event, [item])}
      >
        {item.label}
      </NavigationLink>
    )
  }
  return null
}

export const HeaderNavigation: React.FC<
  React.PropsWithChildren<HeaderNavigationProps>
> = ({
  trigger = ['click' as const, null, 'hover' as const],
  fadeDuration,
  isOpen,
  primaryItems,
  secondaryItems,
  onOpen,
  onClose,
  onClickLink,
}) => {
  const isRouteChanging = useIsRouteChanging()
  return (
    <Navigation
      allowOpen={!isRouteChanging}
      trigger={trigger}
      fadeDuration={fadeDuration}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      backgroundColor="surfaceBrandPrimary"
    >
      <Stack direction="row" justify="space-between">
        {[primaryItems, secondaryItems]
          .filter(isDefinedAndNotNull)
          .map((items, key) => (
            <NavigationBar key={key}>
              {items.map((item, key) => (
                <NavigationBarChild
                  key={key}
                  item={item}
                  onClickLink={onClickLink}
                />
              ))}
            </NavigationBar>
          ))}
      </Stack>
    </Navigation>
  )
}
