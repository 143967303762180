import { incBaseAccount } from '@scentregroup/shared/helpers/paths'

export default function RegisterRedirect(
  originPath: string | Location | URL = window.location,
  params = {}
): void {
  let url: string | Location | URL = originPath

  if (typeof url === 'string') {
    url = new URL(url, window.location.origin)
  }

  const redirectSearchParams = new URLSearchParams(url.search)

  for (const [name, value] of Object.entries(params)) {
    if (!value) {
      continue
    }
    redirectSearchParams.set(name, String(value))
  }

  const path = `${url.pathname}?${redirectSearchParams.toString()}${url.hash}`

  const searchParams = new URLSearchParams({ path })
  const redirectUrl = `${url.origin}${incBaseAccount(
    'register'
  )}?${searchParams.toString()}`

  window.location.assign(redirectUrl)
}
