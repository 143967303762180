import React, { PropsWithChildren } from 'react'
import { Box } from '@hub/box'
import { Stack } from '@hub/stack'
import { CoreContainer } from '@hub/core-container'
import { HeaderPrimaryMenuButton } from './header-primary-menu-button'
import { HeaderTopStrip } from './header-top-strip'
import { HeaderLogo } from './header-logo'
import { HeaderSearchButton } from './header-search-button'
import { HeaderMembership } from './header-membership'
import { ITopStripProps } from '../top-strip'
import { HeaderBackButton } from './header-back-button'

type HeaderProps = {
  country?: 'Australia' | 'New Zealand'
  logoLink?: string
  logoSlug?: string
  logoLabel?: string
  topStripProps?: ITopStripProps
  user?: { firstName?: string | null; isLoggedIn: boolean }
  taskMode?: boolean
  backLink?: string
  onClickBack?: React.MouseEventHandler
  onClickLogin?: React.MouseEventHandler
  onClickLogo?: React.MouseEventHandler
  onClickPrimaryMenu?: React.MouseEventHandler
  onClickSearch?: React.MouseEventHandler
  onClickSecondaryMenu?: React.MouseEventHandler
  onClickTopStrip?: React.MouseEventHandler
}

const HeaderContext = React.createContext<{
  props?: HeaderProps
}>({})

export const useHeaderProps = (): HeaderProps => {
  return React.useContext(HeaderContext).props || {}
}

export const HeaderMenus: React.FC<PropsWithChildren> = ({ children }) => {
  const { props } = React.useContext(HeaderContext)
  if (props?.taskMode) {
    return null
  }
  return <>{children}</>
}

export const HeaderInner: React.FC<PropsWithChildren> = ({ children }) => {
  const { props } = React.useContext(HeaderContext)
  if (props?.taskMode) {
    return null
  }
  return <>{children}</>
}

export const HeaderOuter: React.FC<PropsWithChildren> = ({ children }) => {
  return <>{children}</>
}

export const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  children,
  ...props
}) => {
  const isTaskMode = props.taskMode

  const [menusChildren, innerChildren, outerChildren] = React.useMemo(() => {
    const menusChildren: React.ReactElement[] = []
    const innerChildren: React.ReactElement[] = []
    const outerChildren: React.ReactElement[] = []
    const callback: Parameters<typeof React.Children.forEach>[1] = child => {
      if (!React.isValidElement(child)) {
        return
      }
      if (child.type === React.Fragment) {
        React.Children.forEach((child.props as any).children, callback)
      }
      if (child.type === HeaderMenus) {
        menusChildren.push(child)
      }
      if (child.type === HeaderInner) {
        innerChildren.push(child)
      }
      if (child.type === HeaderOuter) {
        outerChildren.push(child)
      }
    }
    React.Children.forEach(children, callback)
    return [menusChildren, innerChildren, outerChildren]
  }, [children])

  return (
    <HeaderContext.Provider value={{ props }}>
      <Box backgroundColor="surfaceBrandPrimary" display="flow-root">
        <Box>
          <HeaderTopStrip />
          <CoreContainer>
            <Box>
              {menusChildren}
              {isTaskMode ? (
                <Stack
                  shouldWrapChildren={false}
                  direction="row"
                  align="center"
                  justify="space-between"
                  wrap={['wrap', null, 'nowrap']}
                  columnGap="spacing-md"
                  rowGap="spacing-sm"
                  lineHeight={0}
                  marginTop={['spacing-md', null, 'spacing-lg']}
                  marginBottom={['spacing-md', null, 'spacing-lg']}
                  sx={{
                    minWidth:
                      'calc(var(--chakra-sizes-size-21) - 2 * var(--chakra-space-spacing-lg))',
                  }}
                >
                  <Box flex={1} minWidth="size-2">
                    <HeaderBackButton />
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 0,
                    }}
                  >
                    <HeaderLogo />
                  </Box>
                  <Box flex={1}></Box>
                </Stack>
              ) : (
                <Stack
                  shouldWrapChildren={false}
                  direction="row"
                  align="center"
                  justify="space-between"
                  wrap={['wrap', null, 'nowrap']}
                  columnGap="spacing-md"
                  rowGap="spacing-sm"
                  lineHeight={0}
                  marginTop={['spacing-md', null, 'spacing-lg']}
                  marginBottom={['spacing-md', null, 'spacing-lg']}
                  sx={{
                    minWidth:
                      'calc(var(--chakra-sizes-size-21) - 2 * var(--chakra-space-spacing-lg))',
                  }}
                >
                  <Box
                    width="size-8"
                    sx={{
                      flexGrow: 0,
                      order: 1,
                    }}
                  >
                    <HeaderPrimaryMenuButton />
                  </Box>
                  <Box
                    sx={{
                      flexGrow: [1, null, 0],
                      order: 2,
                      display: 'flex',
                      justifyContent: ['center', null, 'left'],
                    }}
                    width={[
                      null,
                      null,
                      // in Desktop, the menu & logo combined width must match the membership
                      // width so that the search button will be centered:
                      //   logoWidth = (membershipWidth - menuWidth - columnGap)
                      `calc(var(--chakra-sizes-size-16) - var(--chakra-sizes-size-8) - var(--chakra-space-spacing-md))`,
                    ]}
                  >
                    <HeaderLogo />
                  </Box>
                  <Box
                    width={[
                      'calc(100vw - 2 * var(--chakra-space-spacing-lg))',
                      null,
                      'auto',
                    ]}
                    sx={{
                      order: [4, null, 3],
                      textAlign: 'center',
                    }}
                  >
                    <HeaderSearchButton />
                  </Box>
                  <Box
                    width={['size-8', null, 'size-16']}
                    sx={{
                      flexGrow: 0,
                      order: [3, null, 4],
                      textAlign: 'right',
                      textWrap: 'nowrap',
                    }}
                  >
                    {props.country === 'Australia' && <HeaderMembership />}
                  </Box>
                </Stack>
              )}
            </Box>
          </CoreContainer>
          <Box>{innerChildren}</Box>
        </Box>
      </Box>
      <Box marginBottom={['spacing-lg', null, 'spacing-lg']}>
        {outerChildren}
      </Box>
    </HeaderContext.Provider>
  )
}
