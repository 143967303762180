import { StandardSizes } from '@hub/design-system-base'
import { Link, LinkBox, LinkButtonOverlay } from '@hub/link'
import React from 'react'
import { MenuButton } from './menu-button'

type MenuLinkProps = React.ComponentProps<typeof Link> & {
  variant?: 'bar' | 'menu' | 'panel'
  size?: StandardSizes
  tone?: 'neutral' | 'strong'
  openInNewWindow?: boolean
}

export const MenuLink: React.FC<React.PropsWithChildren<MenuLinkProps>> = ({
  variant = 'panel',
  size = 'sm',
  tone,
  openInNewWindow = false,
  ...props
}) => {
  const { type: _type, colorScheme: _colorScheme, ...rest } = props

  if (variant === 'bar') {
    return (
      <LinkBox>
        <LinkButtonOverlay variant="menuButton" size={size} {...rest} />
      </LinkBox>
    )
  }
  return (
    <MenuButton
      as={Link}
      size={size}
      tone={tone}
      {...(openInNewWindow && {
        target: '_blank',
        rel: 'noopener noreferrer',
      })}
      {...rest}
    />
  )
}
