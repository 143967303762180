import React from 'react'
import {
  useGetSources,
  GetSources,
  useAlgoliaClients,
  makeOffsetlessTimeGetters,
  BaseItem,
  SpecialSearchKind,
  useReshape,
  PickedReshape,
} from '../../components/search'
import { getAlgoliaApiKeys } from '../../config'
import { useLocalUser } from '../../hooks/use-local-user'
import { MagicLinkValue, useMagicLink } from '../magic-link'

export function useLayoutSearch(
  country: 'Australia' | 'New Zealand',
  centre:
    | {
        slug: string
        title: string
        longitude: number
        latitude: number
        sys: { id: string }
        state: string
        timeZone: string
      }
    | undefined
): [
  isOpen: boolean,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  specialSearchKind: SpecialSearchKind,
  getSources: GetSources<BaseItem>,
  onSubmit: (query: string) => void,
  reshape: PickedReshape,
] {
  const user = useLocalUser()

  const [isOpenSearchModal, setIsOpenSearchModal] = React.useState(false)
  const [magicLink, setMagicLink] = useMagicLink()
  React.useEffect(() => {
    if (
      magicLink === MagicLinkValue.stores ||
      magicLink === MagicLinkValue.centres
    ) {
      setIsOpenSearchModal(true)
    }
  }, [magicLink])
  React.useEffect(() => {
    if (!isOpenSearchModal) {
      setMagicLink()
    }
  }, [isOpenSearchModal, setMagicLink])
  const specialSearchKind = React.useMemo(() => {
    if (magicLink === MagicLinkValue.centres) {
      return 'centres' as const
    }
    if (magicLink === MagicLinkValue.stores) {
      return 'stores' as const
    }
    return undefined
  }, [magicLink])

  const clients = useAlgoliaClients(getAlgoliaApiKeys())
  const context = {
    centre,
    country,
    specialSearchKind,
    user,
  }
  const timeGetters = makeOffsetlessTimeGetters(country, centre)
  const searchContext = { ...context, ...timeGetters, query: '' }
  const reshape = useReshape(searchContext)

  const getSources = useGetSources(clients, searchContext)

  const onSubmit = (query: string): void => {
    const centrePath = centre ? `/${centre.slug}` : ''
    const q = encodeURIComponent(query || '')
    setIsOpenSearchModal(false)

    // TODO when all clients are under one routing
    // use history.push from the router
    window.location.assign(`${centrePath}/search?q=${q}`)
  }

  return [
    isOpenSearchModal,
    setIsOpenSearchModal,
    specialSearchKind,
    getSources,
    onSubmit,
    reshape,
  ]
}
