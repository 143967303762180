/* eslint-disable react-hooks/exhaustive-deps */
import debounce from 'lodash/debounce'
import React from 'react'

export type Rect = Pick<
  DOMRect,
  'top' | 'right' | 'bottom' | 'left' | 'width' | 'height'
>

const getRect: (htmlElement?: HTMLElement | null) => Rect = htmlElement => {
  // workaround test runner not defining getBoundingClientRect
  if (!htmlElement || typeof htmlElement.getBoundingClientRect !== 'function') {
    return {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: 0,
      height: 0,
    }
  }
  const { top, right, bottom, left, width, height } =
    htmlElement.getBoundingClientRect()
  return { top, right, bottom, left, width, height }
}

const isValidElements = (
  items: (HTMLElement | null | undefined)[]
): items is HTMLElement[] => {
  return items.every(Boolean)
}

export const useRect = (
  getElements: (() => HTMLElement | null | undefined)[]
): (Rect | undefined)[] => {
  const [counter, setCounter] = React.useState(0)
  const [rects, setRects] = React.useState<(Rect | undefined)[]>(() =>
    getElements.map(() => undefined)
  )
  React.useEffect(() => {
    if (!window.ResizeObserver) {
      return () => {}
    }
    const elements = getElements.map(getter => getter())
    if (!isValidElements(elements)) {
      const timeout = setTimeout(() => setCounter(counter => ++counter), 100)
      return () => {
        clearTimeout(timeout)
      }
    }
    const resizeHandler = debounce(() => {
      setRects(elements.map(getRect))
    }, 30)

    const resizeObserver = new ResizeObserver(resizeHandler)
    elements.forEach(element => resizeObserver.observe(element))

    window.addEventListener('scroll', resizeHandler)

    return () => {
      resizeHandler.cancel()
      resizeObserver.disconnect()
      window.removeEventListener('scroll', resizeHandler)
    }
  }, [counter])

  return rects
}
