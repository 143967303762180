import React from 'react'
import { CloudinaryImage } from '../cloudinary-image'
import { FeatureProps } from './feature'

export const FeatureImage: React.FC<
  React.PropsWithChildren<Pick<FeatureProps, 'image'>>
> = ({ image }): JSX.Element | null => {
  if (!image) {
    return null
  }

  return (
    <CloudinaryImage
      ratio="1"
      imageSetOrImage={{
        type: 'SQUARE',
        url: image.src,
        alt: image.description,
      }}
      sizes={214}
    />
  )
}
