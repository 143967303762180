import { RenderContext, Segment } from '../types'

export const HEADINGS = {
  centres: 'Centres',
  westfieldProducts: 'Westfield Gift Cards',
  westfieldServices: 'Westfield Services',
  topStores: 'Top Stores',
  events: 'Events',
  offers: 'Offers',
  news: 'News',
  stories: 'Stories',
  otherStores: 'Other stores',
  popularSearches: 'Popular Searches',
  noResult: '',
} as const

type HeadingName = keyof typeof HEADINGS
export type HeadingValue = (typeof HEADINGS)[HeadingName]

const getSegmentsToHeadings = (
  headings: Record<HeadingName, string>,
  context: RenderContext
): Record<Segment, string> => ({
  westfieldProducts: headings.westfieldProducts,
  nationalStorefronts: headings.topStores,
  stores: headings.topStores,
  sites: headings.westfieldServices,
  services: headings.westfieldServices,
  localOffers: headings.offers,
  nationalOffers: headings.offers,
  events: headings.events,
  localNews: headings.news,
  nationalNews: headings.news,
  localStories: headings.stories,
  nationalStories: headings.stories,
  centres: headings.centres,
  onlineServices: headings.westfieldServices,
  otherStores: headings.otherStores,
  searchSuggestions:
    context.specialSearchKind === 'stores'
      ? headings.topStores
      : headings.popularSearches,
})

interface HeadingInfo {
  getHeadingForSegment: (s: Segment) => string
  headingsToDisplay: string[]
}

export function makeHeadingInfo(context: RenderContext): HeadingInfo {
  const headings = HEADINGS
  const segmentToHeadings = getSegmentsToHeadings(headings, context)
  const getHeadingForSegment = (segment: Segment): string =>
    segmentToHeadings[segment]
  const headingsToDisplay = Object.values(headings)
  return { getHeadingForSegment, headingsToDisplay }
}
