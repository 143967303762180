import React from 'react'
import { ListItem as ChakraListItem, ListItemProps } from '@chakra-ui/react'

export const ListItem: React.FC<
  React.PropsWithChildren<
    Pick<
      ListItemProps,
      'as' | 'sx' | 'children' | 'onMouseEnter' | 'onMouseLeave' | 'onClick'
    >
  >
> = props => <ChakraListItem {...props} />
