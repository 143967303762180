import { useCallback } from 'react'
import {
  AutocompleteReshapeSource,
  AutocompleteReshapeSourcesBySourceId,
} from '@algolia/autocomplete-core'
import { groupBy } from 'lodash'

import { assertSegment, CreateAutocomplete, SearchContext } from '../../types'
import { makeHeadingInfo } from '../../group-and-render-segments/headings'
import { NarrowParameter } from '../../../../types'
import { orderResults } from '../../result-order'
import { makePostMergeSort } from './make-post-merge-sort'
import { substituteSources } from './substitute-sources'

function mergeSources<BaseItem extends Record<string, unknown>>(
  mergedSourceId: string,
  sources: AutocompleteReshapeSource<BaseItem>[],
  sort: (items: BaseItem[], mergedSourceId: string) => BaseItem[]
): AutocompleteReshapeSource<BaseItem> {
  const sourcesBySourceId: AutocompleteReshapeSourcesBySourceId<BaseItem> = {}
  sources.forEach(source => {
    sourcesBySourceId[source.sourceId] = source
  })
  const assertGetSource = (
    item: BaseItem
  ): AutocompleteReshapeSource<BaseItem> => {
    type _Item = BaseItem & { _originalSourceId: string }
    const sourceId = (item as _Item)._originalSourceId
    const source = sourcesBySourceId[sourceId]
    if (source) {
      return source
    } else {
      throw new Error(`Source not found for _originalSourceId: ${sourceId}`)
    }
  }
  return {
    sourceId: mergedSourceId,
    getItems() {
      const unsortedItems = sources
        .map(source =>
          source
            .getItems()
            .map(item => ({ ...item, _originalSourceId: source.sourceId }))
        )
        .flat()
      return sort(unsortedItems, mergedSourceId)
    },
    getItemInputValue(props) {
      return (
        assertGetSource(props.item).getItemInputValue?.(props) ??
        props.state.query
      )
    },
    getItemUrl(props) {
      return assertGetSource(props.item).getItemUrl?.(props)
    },
    onActive(props) {
      return assertGetSource(props.item).onActive?.(props)
    },
    onSelect(props) {
      return assertGetSource(props.item).onSelect?.(props)
    },
  }
}

export type Reshape = Required<Parameters<CreateAutocomplete>[0]>['reshape']
export type PickedReshape = NarrowParameter<Reshape, 'sources'>

export function makeReshape(
  groupSourceId: (sourceId: string) => string,
  categorySlugToHeaderMapping: Record<string, string>,
  context: SearchContext
): PickedReshape {
  return function reshape({ sources }) {
    const sortDuringMerge = makePostMergeSort(sources, context)
    const grouped = groupBy(sources, source => groupSourceId(source.sourceId))
    const entries = Object.entries(grouped)
    const mergedSources = entries.map(([groupedSourceId, sources]) =>
      mergeSources(groupedSourceId, sources, sortDuringMerge)
    )
    const substitutedSources = substituteSources(mergedSources)
    return orderResults(
      substitutedSources,
      context,
      'live',
      mergedSources => mergedSources.sourceId
    )
  }
}
export default function useReshape(
  context: SearchContext,
  categorySlugToHeaderMapping: Record<string, string> = {}
): PickedReshape {
  const groupSourceId = useCallback(
    (sourceId: string) => {
      const { getHeadingForSegment } = makeHeadingInfo(context)
      return getHeadingForSegment(assertSegment(sourceId))
    },
    [context]
  )
  return makeReshape(groupSourceId, categorySlugToHeaderMapping, context)
}
