import React from 'react'
import { Accordion as ChakraAccordion, ExpandedIndex } from '@chakra-ui/react'
import { As, HubStyleObject, forwardRef } from '@hub/design-system-base'

interface AccordionProps {
  sx?: HubStyleObject
  as?: As
  defaultIndex?: number[]
  allowToggle?: boolean
  onChange?: (expandedIndex: ExpandedIndex) => void
}

export const Accordion = forwardRef<AccordionProps, typeof ChakraAccordion>(
  ({ sx, as, defaultIndex, children, allowToggle = false, onChange }, ref) => (
    <ChakraAccordion
      ref={ref}
      sx={sx}
      as={as}
      defaultIndex={defaultIndex}
      allowToggle={allowToggle}
      onChange={onChange}
    >
      {children}
    </ChakraAccordion>
  )
)
