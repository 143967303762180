import { HeadingValue, HEADINGS } from './group-and-render-segments/headings'
import { RenderContext } from './types'

export const liveSearchCentreHeadingOrder: HeadingValue[] = [
  HEADINGS.centres,
  HEADINGS.westfieldProducts,
  HEADINGS.westfieldServices,
  HEADINGS.topStores,
  HEADINGS.otherStores,
  HEADINGS.news,
  HEADINGS.stories,
  HEADINGS.events,
  HEADINGS.offers,
]

export const liveSearchNationalHeadingOrder: HeadingValue[] = [
  HEADINGS.centres,
  HEADINGS.westfieldProducts,
  HEADINGS.westfieldServices,
  HEADINGS.topStores,
  HEADINGS.otherStores,
  HEADINGS.news,
  HEADINGS.stories,
  HEADINGS.events,
  HEADINGS.offers,
]

export const liveSearchFindAStoreHeadingOrder: HeadingValue[] = [
  HEADINGS.topStores,
  HEADINGS.otherStores,
  HEADINGS.centres,
  HEADINGS.westfieldProducts,
  HEADINGS.westfieldServices,
  HEADINGS.news,
  HEADINGS.stories,
  HEADINGS.events,
  HEADINGS.offers,
]

export const resultsPageCentreHeadingOrder: HeadingValue[] = [
  HEADINGS.centres,
  HEADINGS.westfieldProducts,
  HEADINGS.westfieldServices,
  HEADINGS.topStores,
  HEADINGS.otherStores,
  HEADINGS.news,
  HEADINGS.stories,
  HEADINGS.events,
  HEADINGS.offers,
]
export const resultsPageNationalHeadingOrder: HeadingValue[] = [
  HEADINGS.centres,
  HEADINGS.westfieldProducts,
  HEADINGS.westfieldServices,
  HEADINGS.topStores,
  HEADINGS.otherStores,
  HEADINGS.news,
  HEADINGS.stories,
  HEADINGS.events,
  HEADINGS.offers,
]

export function orderResults<T>(
  results: T[],
  context: Pick<RenderContext, 'centre' | 'specialSearchKind'>,
  searchType: 'live' | 'results',
  getHeading: (item: T) => string
): T[] {
  const headingOrder = selectOrder(context, searchType)
  results.sort(
    (a, b) =>
      headingOrder.indexOf(getOfficalHeading(headingOrder, getHeading, a)) -
      headingOrder.indexOf(getOfficalHeading(headingOrder, getHeading, b))
  )
  return results
}

function getOfficalHeading<T>(
  headingOrder: HeadingValue[],
  getHeading: (item: T) => string,
  item: T
): HeadingValue {
  const heading = getHeading(item)
  const index = headingOrder.indexOf(heading as HeadingValue)
  if (index >= 0) {
    return headingOrder[index]
  } else {
    return headingOrder.find(el => heading.includes(el)) ?? HEADINGS.noResult
  }
}

function selectOrder(
  context: Pick<RenderContext, 'centre' | 'specialSearchKind'>,
  searchType: string
): HeadingValue[] {
  const isCentre = !!context.centre
  const isLiveSearch = searchType === 'live'
  const isFindAStore = context.specialSearchKind === 'stores'
  if (!isLiveSearch && isCentre) {
    return resultsPageCentreHeadingOrder
  } else if (!isLiveSearch && !isCentre) {
    return resultsPageNationalHeadingOrder
  } else if (isFindAStore) {
    return liveSearchFindAStoreHeadingOrder
  } else if (isCentre) {
    return liveSearchCentreHeadingOrder
  } else {
    return liveSearchNationalHeadingOrder
  }
}
