import React from 'react'

import { IconButton } from '@hub/button'
import { CrossMediumIcon } from '@hub/icon'
import { HubStyleObject } from '@hub/design-system-base'
interface QuitButtonProps {
  onClickClose: () => void
  sx?: HubStyleObject
}

const QuitButton: React.FC<React.PropsWithChildren<QuitButtonProps>> = ({
  onClickClose,
  sx,
}) => (
  <IconButton
    size="icon-lg"
    sx={{
      backgroundColor: 'transparent',
      color: 'licorice',
      '&:hover': {
        color: 'quartz',
        backgroundColor: 'transparent',
      },
      ...sx,
    }}
    aria-label="Close"
    icon={
      <CrossMediumIcon
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          width: 'size-icon-md',
          height: 'size-icon-md',
          top: 'size-3',
          right: 0,
        }}
      />
    }
    onClick={onClickClose}
  />
)

export default QuitButton
