import { Container } from '@hub/container'
import React from 'react'
import {
  As,
  HubStyleObject,
  forwardRef,
  StandardSizes,
  HubResponsiveArray,
  HubResponsiveValue,
} from '@hub/design-system-base'

interface CoreContainerProps {
  sx?: HubStyleObject
  as?: As
  fadeOutOverflow?: React.ComponentProps<typeof Container>['fadeOutOverflow']
  gutter?: HubResponsiveArray<string>
  maxWidth?: StandardSizes
  isMobileFullWidth?: boolean
}

export const CoreContainer = forwardRef<CoreContainerProps, typeof Container>(
  (
    { sx, as, children, fadeOutOverflow, gutter, maxWidth, isMobileFullWidth },
    _ref
  ) => {
    const sxMemo = React.useMemo(
      () => ({
        ...fadeOutStyles(fadeOutOverflow),
        ...sx,
        maxWidth: maxWidth || 'size-max-fixed',
      }),
      [fadeOutOverflow, maxWidth, sx]
    )
    return (
      <Container
        sx={sxMemo}
        as={as}
        gutter={
          gutter ||
          (isMobileFullWidth
            ? ['spacing-none', 'spacing-lg', 'spacing-2xl']
            : ['spacing-mobile-margin', 'spacing-lg', 'spacing-2xl'])
        }
        fadeOutOverflow={fadeOutOverflow}
      >
        {children}
      </Container>
    )
  }
)

export { ContainerContext as CoreContainerContext } from '@hub/container'

function fadeOutStyles(
  fadeOutOverflow?: HubResponsiveValue<boolean>
): HubStyleObject {
  if (!fadeOutOverflow) {
    return {}
  }
  if (!Array.isArray(fadeOutOverflow)) {
    return {
      overflowX: 'scroll',
      overflowY: 'hidden',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      scrollbarWidth: 'none',
    }
  }

  const responsivePadding = (breakpointIndex: number): string => {
    const values = ['spacing-md', 'spacing-md', 'spacing-2xl']
    return values[Math.min(breakpointIndex, values.length)]
  }

  return {
    overflowX: fadeOutOverflow.map(value =>
      value === true ? 'scroll' : value === false ? 'hidden' : null
    ),
    overflowY: fadeOutOverflow.map(value =>
      value === true ? 'hidden' : value === false ? 'initial' : null
    ),

    paddingLeft: fadeOutOverflow.map((value, i) =>
      value === true
        ? responsivePadding(i)
        : value == false
        ? responsivePadding(i)
        : null
    ),
    paddingRight: 0,
    scrollBehavior: 'smooth',

    /* Hide scrollbar for Chrome, Safari and Opera */
    '&::-webkit-scrollbar': {
      display: 'none',
    },

    /* Hide scrollbar for IE, Edge and Firefox */
    msOverflowStyle: 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
  }
}
