import React from 'react'
import { Box } from '@hub/box'
import {
  TrackComponentArgsSimplified as TrackComponentArgs,
  useTrackComponentSimplified as useTrackComponent,
} from '@scentregroup/analytics'

type TrackBoxProps = TrackComponentArgs & React.ComponentProps<typeof Box>

export const TrackBox: React.FC<React.PropsWithChildren<TrackBoxProps>> = ({
  onCreateImpressionWhenView,
  onImpressionTrackEvent,
  onImpressionTrackEventOnce,
  onClickTrackEvent,
  children,
  ...boxProps
}: TrackBoxProps) => {
  const { onClick: onClickTrack, inViewRef } = useTrackComponent({
    onCreateImpressionWhenView,
    onImpressionTrackEvent,
    onClickTrackEvent,
    onImpressionTrackEventOnce,
  })
  const onClick: React.MouseEventHandler = e => {
    boxProps.onClick?.(e)
    onClickTrack(e)
  }
  return (
    <Box {...boxProps} ref={inViewRef} onClick={onClick}>
      {children}
    </Box>
  )
}
