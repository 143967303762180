import analyticsUtils from './index.js'

/**
 * @deprecated instead do:
 * const analytics = useAnalytics()
 * analytics.trackEvent(//...)
 */
export default function trackClickEvent(
  eventCategory?: string,
  eventAction?: string,
  eventLabel?: string,
  metadata?: Record<string, unknown>
): void {
  return trackGeneralEvent(
    analyticsUtils.events.USER_CLICK,
    eventCategory,
    eventAction,
    eventLabel,
    metadata
  )
}

/**
 * @deprecated instead do:
 * const analytics = useAnalytics()
 * analytics.trackEvent(//...)
 */
export function trackGeneralEvent(
  eventType: string,
  eventCategory?: string,
  eventAction?: string,
  eventLabel?: string,
  metadata?: Record<string, unknown>
): void {
  // Only send fields which contain values
  return analyticsUtils.DEPRECATED_gaTagTracking({
    event: eventType,
    ...(eventCategory && { eventCategory }),
    ...(eventAction && { eventAction }),
    ...(eventLabel && { eventLabel }),
    ...metadata,
  })
}

export const trackEventWithSelector =
  (tracker: (label: string) => void, selector = 'a,button') =>
  (e: React.MouseEvent): void => {
    if (e?.target instanceof Element) {
      const desiredTarget = e.target.closest(selector)
      if (e.currentTarget.contains(desiredTarget)) {
        tracker(e?.target?.textContent ?? '')
      }
    }
  }
