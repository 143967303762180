import analyticsUtils from '../../helpers/analytics-utils'
import getDisplayNameForHit from './get-display-name-for-hit'
import { SegmentedHit } from './types'

export const makeOnResultClick =
  (queryText: string) =>
  (heading: string, hit: SegmentedHit): void => {
    analyticsUtils.DEPRECATED_gaTagTracking({
      event: analyticsUtils.events.SEARCH,
      eventCategory: analyticsUtils.categories.GLOBAL_SEARCH,
      eventAction: analyticsUtils.actions.SEARCH_RESULT_ClICKED,
      eventLabel: queryText,
      globalResultClicked: `${heading}/${getDisplayNameForHit(hit)}`,
    })
  }

export const logOnClearSearch = (query: string): void => {
  analyticsUtils.DEPRECATED_gaTagTracking({
    event: analyticsUtils.events.SEARCH,
    eventCategory: analyticsUtils.categories.GLOBAL_SEARCH,
    eventAction: analyticsUtils.actions.CLICK_TO_CLEAR_SEARCH_KEYWORD,
    eventLabel: query,
  })
}

export const logHideSearch = (query: string): void =>
  analyticsUtils.DEPRECATED_gaTagTracking({
    event: analyticsUtils.events.SEARCH,
    eventCategory: analyticsUtils.categories.GLOBAL_SEARCH,
    eventAction: analyticsUtils.actions.CLICK_TO_CLOSE_SEARCH,
    eventLabel: query,
  })

export const logShowSearch = (): void =>
  analyticsUtils.DEPRECATED_gaTagTracking({
    event: analyticsUtils.events.SEARCH,
    eventCategory: analyticsUtils.categories.GLOBAL_SEARCH,
    eventAction: analyticsUtils.actions.CLICK_TO_START_SEARCH,
  })

export const logOnResultsChange = (query: string, numHits: number): void =>
  analyticsUtils.DEPRECATED_gaTagTracking({
    event: analyticsUtils.events.SEARCH,
    eventCategory: analyticsUtils.categories.GLOBAL_SEARCH,
    eventAction: analyticsUtils.actions.SEARCH_SHOWN,
    eventLabel: query,
    noReturnedResults: numHits,
  })

export const logSeeAllProducts = (): void =>
  analyticsUtils.DEPRECATED_gaTagTracking({
    event: analyticsUtils.events.SEARCH,
    eventCategory: analyticsUtils.categories.GLOBAL_SEARCH,
    eventAction: analyticsUtils.actions.SEARCH_RESULT_ClICKED,
    eventLabel: analyticsUtils.labels.SEE_ALL_PRODUCTS,
  })
