/* eslint sort-keys: "error" */
export const events = {
  ADD_TO_CART: 'add_to_cart',
  ADD_TO_CART_COMPLETE: 'addToCartComplete',
  CHECKOUT_OPTION: 'checkoutOption',
  DATA_LAYER: 'dataLayer',
  FILTER_BRAND: 'filter_brand',
  FILTER_COLOR: 'filter_colour',
  FILTER_PRICE: 'filter_price',
  FILTER_STORE: 'filter_store',
  MODULE_IMPRESSION: 'module_impression',
  MOVIES: 'movie_interaction',
  NOT_FOUND_404: 'Error Code (404)',
  PRODUCTS_BUY_NOW: 'addToCart',
  PRODUCTS_CENTRE_SELECTED: 'select_centre',
  PRODUCTS_CLICK: 'productClick',
  PRODUCTS_MENU_CLICK: 'products_click',
  PRODUCTS_PAGE_NEXT_SELECTED: 'next_prev_arrow',
  PRODUCTS_PAGE_PREV_NEXT_SELECTED: 'next_prev_arrow',
  PRODUCTS_SHOW_MORE_DETAIL: 'more_less_click',
  PRODUCTS_SORT_SELECTED: 'sort_by',
  PRODUCTS_VIEW_PRODUCT: 'productDetail',
  PRODUCT_IMPRESSION: 'productImpression',
  PROMOTION_CLICK: 'promoClick',
  PROMOTION_IMPRESSION: 'promoImpression',
  PURCHASE: 'purchase',
  REMOVE_FROM_CART: 'remove_from_cart',
  SEARCH: 'SearchInteraction',
  SELECT_CATEGORY: 'category_selection',
  SEND_HIT_CHECKOUT: 'sendhitCheckout',
  STORE_CLICK: 'store_click',
  USER_CLICK: 'user_interaction',
  VIEW_ITEM: 'view_item',
  WHATS_ON_FILTERS: 'whatson-filter',
} as const

export const categories = {
  BREADCRUMB_CLICK: 'Breadcrumb Click',
  CATEGORY_DECK: 'Category Deck',
  CATEGORY_SELECTION: 'Category Selection',
  CENTRE_SEARCH: 'centreSearch',
  CHINA_WESTFIELD_CLICK: 'china.westfield',
  COLOUR_CARD: 'Colour Card',
  COMPARISON_MODULE_BUY: 'Comparison module Buy',
  COMPARISON_MODULE_CTA: 'Comparison module CTA',
  CORP_MODULE_CTA: 'Corp module CTA',
  DIGITAL_GIFT_CARD: 'Digital Gift Card',
  DROPDOWN_CLICK: 'Products Dropdown Click',
  EVENT_DETAILS_INTERACTION: 'Event Details Interaction',
  EVENT_SHARE: 'Event - Share',
  FEATURE_CAROUSEL: 'Feature Carousel',
  FEATURE_CTA: 'Feature module CTA',
  GIFTCARD_CLICK: 'Gift Card',
  GLOBAL_SEARCH: 'globalSearch',
  HERO_FEATURES_CAROUSEL: 'Hero Features Carousel',
  INLINE_DECK: 'Inline Deck',
  MARKETPLACE: 'Marketplace',
  MORE_LESS_CLICK: 'More Less Click',
  MOVIE_NOT_FOUND: 'Movie not found, redirect',
  NATIONAL: 'National',
  NEWS_CTA: 'News CTA',
  PAGE_SHARE: 'Page - Share', // (fallback for non-specific)
  PRODUCT_COLLECTION_DECK: 'Product Collection Deck',
  PRODUCT_FILTER: 'Filter',
  PRODUCT_REEL: 'Product Reel',
  PROMOTION_CTA: 'Promotion Module CTA Click',
  SETTINGS_CLICK: 'Change setting',
  SPOTLIGHT_CTA: 'Spotlight CTA',
  STORE_GRID: 'Store Grid',
  STORE_SEARCH: 'storeSearch',
  STORE_SELECTION: 'Store Selection',
  THE_EDIT: 'The Edit',
  THE_EDIT_DECK: 'The Edit Deck',
  UNIVERSAL: 'Universal',
  UNIVERSAL_HEADER: 'Universal Header',
  USER_GENERATED_CONTENT: 'User Generated Content',
  UTILITY_PANEL: 'Utility Panel',
  WESTFIELD_PLUS_CLICK: 'Westfield Plus',
  WHATS_HAPPENING_CENTER: 'Whats Happening - Centre Page',
  WHATS_HAPPENING_DECK: 'Whats Happening Deck',
  WHATS_HAPPENING_FEATURED: 'Whats Happening - Featured',
  WHATS_HAPPENING_FILTERS: 'Whats Happening Filters',
  WHATS_HAPPENING_SEE_MORE: 'Whats Happening Page',
} as const

export enum ACTIONS {
  clicked = 'Clicked',
  selected = 'Selected',
  unselected = 'Unselected',
  expanded = 'Expanded',
  viewed = 'Viewed',
  completed = 'Completed',
}

export const actions = {
  ADD_TO_CART: 'Add to cart',
  BREADCRUMB: {
    ROOT: 'Products',
    T1: 'T1',
    T2: 'T2',
    T3: 'T3',
  },
  CAL_DOWNLOAD_LINK: 'Download Calendar Invite',
  CANCEL_SHARE: 'Cancel share',
  CENTRE_CLICK: 'Centre Click',
  CENTRE_SELECTED: 'Centre Selected',
  CHINA_WESTFIELD_NAV_CLICK: 'Centre Navbar Text Link',
  CLICK: 'Click',
  CLICK_TO_CLEAR_SEARCH_KEYWORD: 'clickToClearSearchKeyword',
  CLICK_TO_CLOSE_SEARCH: 'clickToCloseSearch',
  CLICK_TO_SEE_ALL_CENTRES: 'clickToSeeAllCentres',
  CLICK_TO_SET_LOCATION: 'clickToSetGeolocation',
  CLICK_TO_SET_LOCATION_DENIED: 'clickToSetGeolocationDenied',
  CLICK_TO_START_SEARCH: 'clickToStartSearch',
  COLLAPSE_FILTERS: 'Collapse Filters',
  CTA: 'CTA',
  DATE_RANGE_EXPAND: 'Date Range Expand',
  DATE_RANGE_SELECT: 'Date Range Select',
  DATE_SELECT: 'Date Select',
  DECREASE_QUANTITY: 'Decrease quantity',
  EXPAND_FILTERS: 'Expand Filters',
  FILTER_BRAND: 'Brand',
  FILTER_COLOR: 'Colour',
  FILTER_DESELECT: 'Filter Deselect',
  FILTER_PRICE: 'Price',
  FILTER_SELECT: 'Filter Select',
  FILTER_STORE: 'Store',
  GIFTCARD_CART_CLICK: 'CART Header Title',
  GIFTCARD_FOOTER_CLICK: 'Footer Click',
  GIFTCARD_HEADER_CLICK: 'GC Header Title',
  GIFTCARD_NAT_HOME: 'Nat Home Module',
  GIFTCARD_NAT_HOME_TEXT: 'Nat Home Text Link',
  GIFTCARD_NAV_CLICK: 'Navigation Menu',
  GIFTCARD_NAV_ICON_CLICK: 'Navigation Icon',
  GIFTCARD_STOREFRONT_CLICK: 'Storefront link',
  HOMEPAGE_CLICK: 'Homepage Click',
  INCREASE_QUANTITY: 'Increase quantity',
  INSTA_DECK_LINK: 'Instagram Deck Link',
  INSTA_DECK_SCROLL: 'Instagram Deck Scrolled',
  INSTA_DECK_SHOW: 'Instagram Deck Displayed',
  INSTA_DECK_TILE: 'Instagram Deck Enlarge tile (mobile only)',
  LESS: 'Less',
  LOGIN: 'Login',
  MORE: 'More',
  MOVIE_NOT_FOUND_ROUTE: 'Movie not found, user redirected to movies',
  NEWS_SEE_MORE: 'See More News',
  NEXT_SLIDE: 'Next Slide',
  PREVIOUS_SLIDE: 'Previous Slide',
  RESET_FILTERS: 'Reset Filters',
  REVIEWS_MODULE_SHOW: 'Reviews module displayed',
  REVIEWS_SEE_MORE: 'Reviews module show more',
  SEARCH_RESULT_ClICKED: 'searchResultClicked',
  SEARCH_SHOWN: 'searchShown',
  SELECT_CATEGORY_FROM_FILTER: 'Left Navigation Panel',
  SELECT_CATEGORY_FROM_HOMEPAGE: 'Homepage',
  SHARE_THIS_EVENT: 'Share this event',
  SHARE_THIS_PAGE: 'Share this page', // (fallback for non-specific)
  SHARE_TO_FACEBOOK: 'Share to facebook',
  SIGN_UP: 'Sign Up',
  SORT: 'Sort',
  STORES_SEE_MORE: 'Stores See More',
  STORE_AFTERPAY_FILTER: 'storeAfterpayCheckbox',
  STORE_CATEGORY_CLICKED: 'storeCategoryClicked',
  STORE_DISPLAY_GRID_LIST: 'storeDisplayGridOrList',
  STORE_GIFTCARD_FILTER: 'storeGiftcardCheckbox',
  STORE_SUB_CATEGORY_CLICKED: 'storeSubCategoryClicked',
  UNIVERSAL_HEADER_NAV_CLOSE: 'Nav Close',
  UNIVERSAL_HEADER_NAV_LINK_CLICK: 'Nav Link Click',
  UNIVERSAL_HEADER_NAV_MENU_CLOSE: 'Nav Menu Close',
  UNIVERSAL_HEADER_NAV_MENU_OPEN: 'Nav Menu Open',
  UNIVERSAL_HEADER_NAV_OPEN: 'Nav Open',
  VIEW: 'View',
  WESTFIELD_PLUS_NAV_CLICK: 'Navigation Menu',
} as const

export const settings = {
  SCROLL_HORIZONTAL_THRESHOLD: 200,
} as const

export const positions = {
  FEATURED_CONTENT: 'Whats Happening - Featured',
  SPOTLIGHT: 'Spotlight - Featured',
  WHATS_HAPPENING_CENTRE: 'Whats Happening - Centre Page',
} as const

export const names = {
  SPOTLIGHT: 'Spotlight',
} as const

export const dimensions = {
  DIMENSION90: '8',
} as const

export const labels = {
  ACCOUNT_ICON: `Account Icon`,
  ADDRESS_LOOKUP_BILLING: 'Address Lookup - Billing',
  ADDRESS_LOOKUP_DELIVERY: 'Address Lookup - Delivery',
  ADD_TO_CART: 'Add to cart',
  ADD_TO_CART_FORM_ERROR: 'Add to cart options error',
  ARROW_LEFT: 'Arrow Left',
  ARROW_RIGHT: 'Arrow Right',
  BACK_BUTTON: 'Back Button',
  BACK_TO_CART: 'Back To Cart',
  BENEFITS_STRIP: 'Benefits strip',
  BILLING_DETAILS_SECTION: 'Billing details section',
  BILLING_SAME_AS_DELIVERY: 'Billing Same As Delivery',
  CALL_TO_ACTION_BUTTON: 'Call to action button',
  CATEGORY_BACK_BUTTON: 'Category Back Button',
  CATEGORY_LIST_ITEM_CLICKED: 'Category list',
  CC_ENTER_SUBURB_POSTCODE: 'C&C - Enter suburb or postcode',
  CLICK_TEXT: 'Click text',
  CLOSE_DIALOG_BOX: 'Close Dialog Box',
  CONTINUE_AS_GUEST: 'Continue As Guest',
  CONTINUE_LOCATION_ERROR: 'Continue Location Error',
  CONTINUE_SHOPPING: 'Continue Shopping',
  CONTINUE_STOCK_ERROR: 'Continue Stock Error',
  CONTINUE_TO_PAYMENT: 'Continue To Payment',
  CREATED_AN_ACCOUNT: 'Created An Account',
  CUSTOMER_DETAILS_SECTION: 'Customer details section',
  DECREASE_QUANTITY: 'Decrease quantity',
  EDIT_CART: 'Edit Cart',
  EDIT_CC_LOCATION: 'Edit click & collect Location',
  EDIT_DELIVERY_LOCATION: 'Edit Delivery Location',
  EDIT_DETAILS: 'Edit Details',
  EXPAND: 'Expand',
  FILTERS_MOBILE: 'Filters Mobile',
  FOCUS_QUANTITY: 'Focus quantity',
  FULFILLMENT_METHOD: 'Fulfillment Method',
  FULFILLMENT_METHOD_SECTION: 'Fulfillment method section',
  GO_TO_CART: 'Go To Cart',
  INCREASE_QUANTITY: 'Increase quantity',
  ITEMS_PER_PAGE_EXPANDED: `Items Per Page`,
  LOGGED_IN_MEMBER_CHECKOUT: 'Logged In Member Checkout',
  LOGIN: 'Login',
  MANUAL_ADDRESS_SEARCH_BILLING: 'Manual address search - billing',
  MANUAL_ADDRESS_SEARCH_DELIVERY: 'Manual address search - delivery',
  MEMBER_CHECKOUT: 'Member Checkout',
  NEXT_CLICK: 'Next Click',
  PAYMENT_SECTION: 'Payment section',
  PAY_AND_PLACE_ORDER: 'Pay and place order',
  PREVIOUS_CLICK: 'Previous Click',
  PRODUCT_IMAGES: `Product Images`,
  REMEMBER_ADDRESS: 'Remember Address',
  REMEMBER_CARD: 'Remember Card',
  REMOVED_ITEM: 'Removed Item',
  SAVE_PREFERRED_DELIVERY_ADDRESS: 'Save Preferred Delivery Address',
  SAVE_PREFERRED_PAYMENT: 'Save Preferred Payment',
  SEE_ALL_PRODUCTS: 'Products/See all products',
  SET_CC_LOCATION: 'Set click & collect Location',
  SET_DELIVERY_LOCATION: 'Set Delivery Location',
  SIGN_UP_POST_CHECKOUT: 'Sign up post checkout',
  SIZE_GUIDE: `Size Guide`,
  SORT_BY: `Sort By`,
  STOREFRONT_LINK: `Storefront Link`,
  STORY: (label: string): string => `Story (${label})`,
  SUCCESSFUL_LOGIN: 'Successful Login',
  SUCCESSFUL_LOGIN_APPLE: 'Successful Login - Apple',
  SUCCESSFUL_LOGIN_GOOGLE: 'Successful Login - Google',
  SUCCESSFUL_SIGN_UP: 'Successful Sign Up',
  SUCCESSFUL_SIGN_UP_APPLE: 'Successful Sign Up - Apple',
  SUCCESSFUL_SIGN_UP_GOOGLE: 'Successful Sign Up - Google',
  TERMS_AND_CONDITIONS_CHECKBOX: 'Terms and Conditions checkbox',
  VIEW_CART: `View Cart`,
  ccCentreDropdown: (centreName: string) =>
    `C&C Centre Dropdown List | Westfield ${centreName}`,
  createCategory: (label: string): string => `Category (${label})`,
  createCategoryBreadcrumb: (label: string): string =>
    `Category Breadcrumb (${label})`,
  createCcCentre: (centreName: string): string => `C&C Centre (${centreName})`,
  createDeliveryPostcode: (postcode: string): string =>
    `Delivery Postcode (${postcode})`,
  createFiltersFacet: (label: string): string => `Filters ${label}`,
  createFooterLink: (label: string): string => `Footer (${label})`,
  createInfoAccordion: (name: string, isExpanded: boolean): string =>
    `Info Accordion (${name} | ${isExpanded ? 'open' : 'closed'} )`,
  createItemsPerPage: (label: string): string => `Items Per Page (${label})`,
  createLogoLabel: (logo: string | undefined): string => {
    switch (logo) {
      case 'direct':
        return 'Westfield Direct Logo'
      case 'westfield-plus':
        return 'Westfield Plus Logo'
      case 'westfield':
        return 'Westfield Logo'
      default:
        return 'Westfield Logo'
    }
  },
  createOutOfStockProduct: (productName: string): string =>
    `Out of Stock product variant (${productName})`,
  createOutOfStockProductVariant: (productName: string): string =>
    `Out of Stock product variant (${productName})`,
  createPageNumber: (number: string): string => `Page Number (${number})`,
  createProductCategory: (category: string): string =>
    `Product Category (${category})`,
  createRemovedFilterFacetPills: (name: string, value: string): string =>
    `Pill Faceted Option (${name} | ${value})`,
  createSaleItems: (checked: boolean): string =>
    `Sale Items (${checked ? 'true' : 'false'})`,
  createSelectedFilterFacet: (name: string, value: string): string =>
    `Faceted Option (${name} | ${value})`,
  createSortByOption: (option: string): string => `Sort By Option (${option})`,
  createStore: (name: string): string => `Store (${name})`,
  createTitleAndPosition: (title: string, position: number): string =>
    `${title} | P${position}`,
} as const

export const affiliations = {
  WESTFIELD_DIRECT_AU: 'Westfield - Direct - AU',
} as const
