export const completelyCanonicalUrl = (
  url: string,
  approvedParams: string[] = [],
  defaultParams: Record<string, string | number> = {}
): string => {
  // URL doesn't support not providing a protocol so www.example.com as input will fail without this
  let updatedUrlString = url
  if (!url.match(/^[a-zA-Z]+?:\/\//)) {
    updatedUrlString = `https://${updatedUrlString}`
  }

  const updatedUrl = new URL(updatedUrlString)
  updatedUrl.protocol = 'https:'
  updatedUrl.pathname = updatedUrl.pathname.replace(/\/$/, '')
  updatedUrl.hostname = /^[^.]+(\.com(?:\.au)?|\.co\.nz)$/.test(
    updatedUrl.hostname
  )
    ? `www.${updatedUrl.hostname}`
    : updatedUrl.hostname
  updatedUrl.username = ''
  updatedUrl.password = ''
  updatedUrl.hash = ''

  if (approvedParams && approvedParams.length > 0) {
    const searchParams = new URLSearchParams(updatedUrl.search)
    const entries = [...searchParams.entries()]
    const filteredEntries = entries.filter(key =>
      approvedParams.includes(key[0])
    )
    const filteredSearchParams = new URLSearchParams()
    filteredEntries.forEach(entry => filteredSearchParams.append(...entry))

    if (defaultParams) {
      Object.keys(defaultParams).forEach(key => {
        if (!filteredSearchParams.has(key) && approvedParams.includes(key)) {
          filteredSearchParams.append(key, defaultParams[key].toString())
        }
      })
    }

    filteredSearchParams.sort()
    updatedUrl.search = filteredSearchParams.toString()
  } else {
    updatedUrl.search = ''
  }

  return updatedUrl.toString()
}
