import trackClickEvent from '@scentregroup/shared/helpers/analytics-utils/track-event'
import {
  categories,
  labels,
  ACTIONS,
} from '@scentregroup/shared/helpers/analytics-utils/constants'

type TrackedActions = {
  [key in keyof typeof ACTIONS]: (category: string, label: string) => void
}
const trackClickEvents: TrackedActions = Object.keys(ACTIONS).reduce(
  (actions, key) => ({
    ...actions,
    [key]: (category: string, label: string): void =>
      trackClickEvent(category, ACTIONS[key as keyof typeof ACTIONS], label),
  }),
  {} as TrackedActions
)

export { categories, labels }

export default trackClickEvents
