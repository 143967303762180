const parts = ['container', 'requiredIndicator', 'helperText']

export const formTheme = {
  parts,
  baseStyle: {
    container: {
      width: '100%',
      position: 'relative',
    },
    requiredIndicator: {
      marginStart: 1,
      color: 'error',
    },
    helperText: {
      mt: 2,
      color: 'smoke',
      lineHeight: 'normal',
      fontSize: 'font-small',
    },
  },
}
