import { useState, useCallback } from 'react'
import useFeatureFlag from '@scentregroup/shared/hooks/use-feature-flag'
import { FeatureFlagName } from '@scentregroup/shared/feature-flags'
import { MaybeState, OnStateChange, ResetState } from '../types'

interface SyncState {
  autocompleteState: MaybeState
  onStateChange: OnStateChange
  resetState: ResetState
}

export default function useSyncState(): SyncState {
  const [autocompleteState, setAutocompleteState] = useState<MaybeState>({})
  const resetState = (): void => {
    setAutocompleteState({
      activeItemId: null,
      collections: [],
      completion: null,
      context: {},
      isOpen: false,
      query: '',
      status: 'idle',
    })
  }
  const disableLiveSearch = useFeatureFlag(
    FeatureFlagName.NEXT_PUBLIC_FEAT_FLAG_DISABLE_LIVE_SEARCH
  )
  const onStateChange: OnStateChange = ({ state: newState }) => {
    setAutocompleteState(oldState => {
      const hasActiveItemId = newState.activeItemId !== null
      const collectionEmpty =
        newState.collections.length === 0 && !disableLiveSearch
      return hasActiveItemId || collectionEmpty ? oldState : newState
    })
  }
  return {
    autocompleteState,
    onStateChange: useCallback(onStateChange, [disableLiveSearch]),
    resetState: useCallback(resetState, []),
  }
}
