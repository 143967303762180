import React from 'react'
import { Overlay } from '@hub/overlay'
import { HubResponsiveValue, StandardColors } from '@hub/design-system-base'
import { NavigationContextProvider } from './navigation-context'
import { useManagedChildren } from './use-managed-children'
import { Portal } from '@hub/portal'
import { useHubResponsiveValue } from '@hub/overlay/src/overlay-content'

type NavigationProps = {
  backgroundColor?: HubResponsiveValue<StandardColors>
  fadeDuration?: number
  isOpen?: boolean
  trigger?: 'click' | 'hover' | ('click' | 'hover' | null)[]
  placement?: 'left' | 'right' | 'below'
  containerRef?: React.RefObject<HTMLElement | null>
  data?: unknown
  allowOpen?: unknown
  onClick?: React.MouseEventHandler
  onOpen?: (data?: unknown) => void
  onClose?: () => void
}

const MaybePortal: React.FC<
  React.PropsWithChildren<{
    containerRef?: React.RefObject<HTMLElement | null>
  }>
> = ({ containerRef, children }) => {
  if (!containerRef) {
    return <>{children}</>
  }
  return <Portal containerRef={containerRef}>{children}</Portal>
}

export const Navigation: React.FC<React.PropsWithChildren<NavigationProps>> = ({
  backgroundColor = 'white',
  isOpen,
  fadeDuration,
  trigger: responsiveTrigger,
  children,
  placement = 'below',
  containerRef,
  allowOpen,
  onClick,
  onOpen,
  onClose,
}) => {
  const ref = React.useRef<HTMLElement>(null)
  const [activeChild, registerChild, closeAll] = useManagedChildren()
  const trigger =
    useHubResponsiveValue(responsiveTrigger || 'click') ?? undefined

  const handleOverlayClose = React.useCallback(
    (recursive?: boolean) => {
      closeAll()
      if (recursive || recursive === undefined) {
        onClose?.()
      }
    },
    [closeAll, onClose]
  )

  const [isFirstMount, setIsFirstMount] = React.useState(true)
  React.useEffect(() => {
    if (isFirstMount) {
      setIsFirstMount(false)
      return
    }
    if (activeChild) {
      onOpen?.()
    } else {
      // onClose?.()
    }
    // exclude 'isFirstMount' from deps to avoid retriggering this effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChild, onClose, onOpen])

  const handleMouseLeave = React.useCallback(() => {
    if (trigger === 'hover' && activeChild) {
      handleOverlayClose()
    }
  }, [activeChild, handleOverlayClose, trigger])

  React.useEffect(() => {
    // `allowOpen` is really here to close menus when navigating to a new page
    if (allowOpen === false) {
      handleOverlayClose()
    }
  }, [allowOpen, handleOverlayClose])

  return (
    <MaybePortal containerRef={containerRef}>
      <nav ref={ref} onMouseLeave={handleMouseLeave}>
        <Overlay
          containerRef={ref}
          fadeDuration={fadeDuration}
          isOpen={isOpen !== false && Boolean(activeChild)}
          trigger={trigger}
          onClose={handleOverlayClose}
          placement={placement === 'below' ? 'top' : placement}
          backgroundColor={backgroundColor}
        >
          <NavigationContextProvider
            value={{
              activeChild: isOpen !== false ? activeChild : undefined,
              navigationPlacement: placement,
              overlayContentRef: ref,
              registerChild,
              trigger,
              onClose: handleOverlayClose,
              onClickLink: onClick,
            }}
          >
            {children}
          </NavigationContextProvider>
        </Overlay>
      </nav>
    </MaybePortal>
  )
}
