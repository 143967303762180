import { SGPageTemplateImage } from '@scentregroup/shared/types/page-templates'

/* eslint-disable max-len */
const BYNDER_URL_PREFIX = 'https://cam.scentregroup.io/m/'
const CLOUDINARY_URL = 'https://images.scentregroup.io/video/upload/'
const CLOUDINARY_MAPPED_FOLDER = '/cinematograph_media/'
const DESKTOP_OPTIONS = 'vc_h264,c_fill,g_center,h_1028,w_2400'
const MOBILE_OPTIONS = 'vc_h264,c_fill,g_center,h_426,w_640'
const SQUARE_OPTIONS = 'vc_h264,f_mp4,c_fill,g_center,h_1024,w_1024'
const WIDE_OPTIONS = 'vc_h264,f_mp4,c_fill,g_center,ar_21:9,w_2048'

export enum DeviceType {
  mobile = 'mobile',
  desktop = 'desktop',
  square = 'square',
  wide = 'wide',
}

const stripOutBynderUrl = (url: string): string =>
  url.replace(BYNDER_URL_PREFIX, '')
const getOptionsByDeviceType = (deviceType: DeviceType): string => {
  switch (deviceType) {
    case DeviceType.square:
      return SQUARE_OPTIONS
    case DeviceType.wide:
      return WIDE_OPTIONS
    case DeviceType.desktop:
      return DESKTOP_OPTIONS
    default:
      return MOBILE_OPTIONS
  }
}

export const cloudinaryVideoUrl = ({
  baseUrl,
  deviceType,
  removeAudio = false,
}: {
  baseUrl: string
  deviceType: DeviceType
  removeAudio?: boolean
}): string => {
  const url = stripOutBynderUrl(baseUrl)
  let options = getOptionsByDeviceType(deviceType)
  if (removeAudio) {
    options += ',ac_none'
  }

  return `${CLOUDINARY_URL}${options}${CLOUDINARY_MAPPED_FOLDER}${url}`
}

export const videoSrcFromSGPageTemplateImage = (
  templateImage: SGPageTemplateImage,
  deviceType?: DeviceType
): string | undefined => {
  const { cinemagraphUrl } = templateImage
  if (!cinemagraphUrl) {
    return undefined
  }
  return cloudinaryVideoUrl({
    baseUrl: cinemagraphUrl,
    removeAudio: true,
    deviceType: deviceType ?? DeviceType.square,
  })
}
