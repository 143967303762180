import {
  RenderedSectionResults,
  GroupAndRenderSegmentsArgs,
  HitsGroup,
} from '../types'
import getRenderForSegment from './get-render-for-segment'
import groupSegmentsByHeadings from './group-segments-by-heading'

import { makeHeadingInfo } from './headings'

export function groupSegments(args: GroupAndRenderSegmentsArgs): HitsGroup[] {
  const { headingsToDisplay, getHeadingForSegment } = makeHeadingInfo(
    args.context
  )
  return groupSegmentsByHeadings({
    ...args,
    headingsToDisplay,
    getHeadingForSegment,
  })
}

export default function groupAndRenderSegments(
  args: GroupAndRenderSegmentsArgs
): RenderedSectionResults[] {
  const grouped = groupSegments(args)

  return grouped.map(({ heading, groupedHits, indexName, queryId }) => ({
    queryId,
    indexName,
    heading,
    hits: groupedHits.map(({ hit, context, onResultClick }, index) =>
      getRenderForSegment({
        hit,
        context: { ...context, queryID: queryId, indexName },
        onResultClick,
        index,
      })
    ),
  }))
}
