import React from 'react'
import { Avatar } from '@hub/avatar'
import { Box } from '@hub/box'
import { Button } from '@hub/button'
import { UserMediumIcon } from '@hub/icon'
import { Text } from '@hub/text'
import useIsMounted from '../../hooks/use-is-mounted'
import { useHeaderProps } from './header'

export const HeaderMembership: React.FC = () => {
  const { user, onClickSecondaryMenu, onClickLogin } = useHeaderProps()
  const isLoggedIn = user?.isLoggedIn
  const firstName = user?.firstName
  const onClick = user?.isLoggedIn ? onClickSecondaryMenu : onClickLogin
  const isMounted = useIsMounted()

  if (!isMounted) {
    return null
  }
  if (isLoggedIn) {
    return (
      <Button
        size="icon-lg"
        variant="headerButton"
        onClick={onClick}
        aria-label={'membership menu'}
        textAlign="left"
        textTransform="none"
        fontWeight="initial"
        leftIcon={<Avatar name={firstName ?? undefined} />}
      >
        <Box sx={{ display: ['none', null, 'block'] }}>
          <Text fontSize="font-sm" tone="strong">
            Account
          </Text>
          <Text fontSize="font-xs" tone="neutral">
            Settings
          </Text>
        </Box>
      </Button>
    )
  }
  return (
    <Button
      size="icon-lg"
      variant="headerButton"
      colorScheme="licorice"
      onClick={onClick}
      aria-label="membership login or sign up"
      textAlign="left"
      textTransform="none"
      fontWeight="initial"
      leftIcon={<UserMediumIcon boxSize="size-icon-md" />}
    >
      <Box sx={{ display: ['none', null, 'block'] }}>
        <Text fontSize="font-sm" tone="strong">
          My account
        </Text>
        <Text fontSize="font-xs" tone="neutral">
          Login or Sign up
        </Text>
      </Box>
    </Button>
  )
}
