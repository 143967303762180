import React from 'react'

export const useContentIsOpen = (
  isOpen: boolean | undefined
): [
  isOpen: boolean,
  onOpen: (id: symbol) => void,
  onClose: (id?: symbol) => void,
] => {
  const [state, setState] = React.useState(() => new Set<symbol>())
  const isOpenOrContentIsOpen = React.useMemo(
    () => isOpen || state.size > 0,
    [state, isOpen]
  )
  const onOpen = React.useCallback((id: symbol) => {
    setState(state => new Set(state).add(id))
  }, [])
  const onClose = React.useCallback((id?: symbol) => {
    if (!id) {
      setState(new Set())
    } else {
      setState(state => {
        const next = new Set(state)
        next.delete(id)
        return next
      })
    }
  }, [])
  return [isOpenOrContentIsOpen, onOpen, onClose]
}
