import React from 'react'
import { getFadeKeyframes } from './styles'
import { SystemStyleObject } from '@chakra-ui/react'
import { useRect, Rect } from './use-rect'
import { Placement } from './overlay'
import { useOverlayRef } from '.'

type State = {
  isOpen: boolean
  backgroundProps: { animation?: string; sx?: SystemStyleObject }
  gutterProps?: { animation?: string; sx?: SystemStyleObject }
}

const stateReducer = (
  state: State | null,
  {
    placement,
    isOpen,
    duration,
    overlayRect,
    bodyRect,
  }: {
    placement: Placement
    isOpen: boolean
    duration: number
    overlayRect?: Rect
    bodyRect?: Rect
  }
): State => {
  const backgroundFadeKeyframes = getFadeKeyframes(isOpen, 0.8)

  if (placement === 'top') {
    const backgroundProps = {
      animation:
        state?.isOpen !== isOpen
          ? `${backgroundFadeKeyframes} 1 ${duration}s ease-out`
          : undefined,
      sx: {
        top: [
          0,
          null,
          `calc(${Math.floor(overlayRect?.bottom ?? 0)}px - ${Math.floor(
            bodyRect?.top ?? 0
          )}px)`,
        ],
        bottom: 0,
        left: 0,
        right: 0,
        position: 'absolute',
        backgroundColor: 'licorice',
        zIndex: 'menuOverlay',
        pointerEvents: isOpen ? undefined : 'none',
        opacity: isOpen ? 0.8 : 0,
        display: isOpen ? 'block' : 'none',
      },
    }
    return { isOpen, backgroundProps }
  }

  if (placement === 'left') {
    const gutterFadeKeyframes = getFadeKeyframes(isOpen, 1)
    const backgroundProps = {
      animation:
        state?.isOpen !== isOpen
          ? `${backgroundFadeKeyframes} 1 ${duration}s ease-in`
          : undefined,
      sx: {
        top: 0,
        bottom: 0,
        left: [0, null, Math.ceil(overlayRect?.left ?? 0)],
        right: 0,
        position: 'absolute',
        backgroundColor: 'licorice',
        zIndex: 'menuOverlay',
        pointerEvents: isOpen ? undefined : 'none',
        opacity: isOpen ? 0.8 : 0,
        display: isOpen ? 'block' : 'none',
      },
    }
    const gutterProps = {
      animation:
        state?.isOpen !== isOpen
          ? `${gutterFadeKeyframes} 1 ${duration / 2}s ease-in`
          : undefined,
      sx: {
        top: 0,
        bottom: 0,
        left: 0,
        width: [0, null, Math.ceil(overlayRect?.left ?? 0)],
        position: 'absolute',
        zIndex: 'menuOverlay',
        pointerEvents: isOpen ? undefined : 'none',
        opacity: isOpen ? 1 : 0,
        display: isOpen ? 'block' : 'none',
      },
    }

    return { isOpen, backgroundProps, gutterProps }
  }

  if (placement === 'right') {
    const gutterFadeKeyframes = getFadeKeyframes(isOpen, 1)
    const backgroundProps = {
      animation:
        state?.isOpen !== isOpen
          ? `${backgroundFadeKeyframes} 1 ${duration}s ease-in`
          : undefined,
      sx: {
        top: 0,
        bottom: 0,
        left: 0,
        right: Math.ceil(overlayRect?.right ?? 0),
        width: Math.ceil(overlayRect?.right ?? 0),
        position: 'absolute',
        backgroundColor: 'licorice',
        zIndex: 'menuOverlay',
        pointerEvents: isOpen ? undefined : 'none',
        opacity: isOpen ? 0.8 : 0,
        display: isOpen ? 'block' : 'none',
      },
    }
    const gutterProps = {
      animation:
        state?.isOpen !== isOpen
          ? `${gutterFadeKeyframes} 1 ${duration / 2}s ease-in`
          : undefined,
      sx: {
        top: 0,
        bottom: 0,
        right: 15,
        width: Math.ceil(overlayRect?.left ?? 0),
        position: 'absolute',
        zIndex: 'menuOverlay',
        pointerEvents: isOpen ? undefined : 'none',
        opacity: isOpen ? 1 : 0,
        display: isOpen ? 'block' : 'none',
      },
    }
    return { isOpen, backgroundProps, gutterProps }
  }

  // shouldn't happen
  return {
    isOpen,
    backgroundProps: {
      animation: undefined,
      sx: { opacity: 0 },
    },
  }
}

export const useOverlay = (
  duration: number,
  isOpen: boolean | undefined,
  placement: Placement,
  isOpenOrIsContentOpen: boolean,
  containerRef?: React.RefObject<HTMLElement | null>
): [state: State, ref: React.RefObject<HTMLElement | null>] => {
  const ref = useOverlayRef()
  const [state, dispatchState] = React.useReducer(
    stateReducer,
    stateReducer(null, {
      duration,
      isOpen: isOpen ?? false,
      placement,
    })
  )

  const [bodyRect, overlayRect] = useRect([
    () => document?.querySelector('body'),
    () => (containerRef || ref).current,
  ])

  React.useEffect(() => {
    dispatchState({
      duration,
      isOpen: isOpenOrIsContentOpen,
      placement,
      overlayRect,
      bodyRect,
    })
  }, [
    containerRef,
    placement,
    duration,
    bodyRect,
    overlayRect,
    isOpenOrIsContentOpen,
  ])

  return [state, ref]
}
