import { AccordionItem } from '@hub/accordion'
import { Stack } from '@hub/stack'
import React from 'react'
import {
  NavigationContextProvider,
  useNavigationContext,
} from './navigation-context'

export const NavigationAccordion: React.FC<
  React.PropsWithChildren<{ index?: number }>
> = ({ children, index }) => {
  const { accordionExpandedIndex } = useNavigationContext()
  return (
    <AccordionItem>
      <Stack shouldWrapChildren={false} sx={{ gap: 'spacing-sm' }}>
        <NavigationContextProvider
          value={{ isOpen: accordionExpandedIndex === index }}
        >
          {children}
        </NavigationContextProvider>
      </Stack>
    </AccordionItem>
  )
}
