import React from 'react'
import { Box } from '@hub/box'
import { CoreContainer } from '@hub/core-container'
import { LinkItem, MegaMenuFeature, MegaMenuLinkItem } from './types'
import { MegaMenuItem } from './mega-menu-item'
import { Grid, GridItem } from '@hub/grid'
import { Text } from '@hub/text'
import { Feature } from './feature'
import { MenuLink } from '../../../../packages/hub/navigation/src/menu-link'

export type MegaMenuProps = React.PropsWithChildren<{
  items: MegaMenuLinkItem[]
  feature?: MegaMenuFeature
  onClickLink?: (
    event: React.MouseEvent,
    path: (MegaMenuLinkItem | LinkItem)[]
  ) => void
  onClickFeature?: React.MouseEventHandler
}>

const MegaMenu: React.FC<React.PropsWithChildren<MegaMenuProps>> = ({
  items,
  feature,
  onClickLink,
  onClickFeature,
}) => {
  return (
    <CoreContainer
      sx={{
        fontSize: 'font-sm',
        paddingY: 'spacing-md',
        display: 'flex',
        minHeight: 'size-4xl',
        justifyContent: 'space-between',
        backgroundColor: 'surfaceBrandPrimary',
      }}
    >
      <Grid
        templateColumns={[null, null, 'repeat(3, 1fr)']}
        gap={['spacing-2xl', 'spacing-2xl', 'spacing-none', 'spacing-none']}
      >
        {feature?.link?.mobileLabel && (
          <GridItem
            sx={{
              width: 'size-17',
              display: ['block', null, 'none'],
            }}
          >
            <Text tone="strong">{feature.mobileTitle}</Text>
            <MenuLink
              href={feature.link.url}
              onClick={event => onClickFeature?.(event)}
            >
              {feature.link.mobileLabel}
            </MenuLink>
          </GridItem>
        )}
        {items?.map((item, i) => (
          <GridItem sx={{ width: 'size-17' }} key={i}>
            <MegaMenuItem onClick={onClickLink} megaMenuItem={item} />
          </GridItem>
        ))}
      </Grid>
      {feature && (
        <Box
          sx={{
            maxWidth: [0, 0, 0, '25%'],
            flexBasis: [0, 0, 0, '25%'],
            display: ['none', null, null, 'initial'],
          }}
        >
          <Feature
            {...feature}
            onClick={
              onClickFeature ? event => onClickFeature(event) : undefined
            }
          />
        </Box>
      )}
    </CoreContainer>
  )
}

export default MegaMenu
