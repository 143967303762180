import { IProductPriceRange } from '../types'

const FRAC_DIGITS = 2

export function displayStringForPriceRange(range?: IProductPriceRange): string {
  if (!range?.min) {
    return ''
  }
  if (range.min === range.max) {
    return `$${range.min.toFixed(FRAC_DIGITS)}`
  }
  return `$${range.min.toFixed(FRAC_DIGITS)} – $${range.max.toFixed(
    FRAC_DIGITS
  )}`
}

export function displayStringForPriceRangeWithoutCurrency(
  range?: IProductPriceRange
): string {
  if (!range?.min) {
    return ''
  }

  if (range.min === range.max) {
    return `${range.min.toFixed(FRAC_DIGITS)}`
  }
  return `${range.min.toFixed(FRAC_DIGITS)} – ${range.max.toFixed(FRAC_DIGITS)}`
}

export function displayMinimumPriceWithoutCurrency(
  range?: IProductPriceRange
): string {
  if (!range?.min) {
    return ''
  }
  return `${range.min.toFixed(FRAC_DIGITS)}`
}
