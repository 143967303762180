import React, { ReactElement, Fragment } from 'react'
import { kebabCase } from 'lodash'
import { forwardRef } from '@hub/design-system-base'
import { MenuButton, MenuLink } from '@hub/navigation'
import { H4 } from '@hub/heading'
export type LinkContent = {
  text: string
  url: string
  newTab?: boolean
  trackingLabel?: string
  links?: LinkContent[]
}

export type ButtonContent = {
  text: string
  onClick: () => void
  trackingLabel?: string
}

export interface LinkSectionTrackers {
  clicked?: (label: string) => void
}

export interface LinkSectionPropsType {
  heading: LinkContent | ButtonContent
  links?: LinkContent[]
  trackers?: LinkSectionTrackers
}

const extraLinkProps = (newTab = false): { rel?: string; target?: string } =>
  newTab ? { rel: 'noopener noreferrer', target: '_blank' } : {}

export const LinkSection = ({
  heading,
  links,
  trackers,
}: LinkSectionPropsType): ReactElement => (
  <>
    {heading && (
      <>
        {'url' in heading ? (
          <MenuLink
            {...extraLinkProps(heading.newTab)}
            href={heading.url}
            id={kebabCase(heading.text)}
            sx={{
              display: 'block',
            }}
            onClick={() =>
              trackers?.clicked?.(heading.trackingLabel ?? heading.text)
            }
          >
            <H4>{heading.text}</H4>
          </MenuLink>
        ) : (
          <MenuButton
            tone="strong"
            as={forwardRef((props, ref) => (
              <button
                {...props}
                ref={ref}
                type="button"
                aria-labelledby={kebabCase(heading.text)}
              />
            ))}
            sx={{
              fontSize: 'font-xl',
              color: 'inherit',
            }}
            onClick={() => {
              trackers?.clicked?.(heading.trackingLabel ?? heading.text)
              heading.onClick()
            }}
          >
            {heading.text}
          </MenuButton>
        )}
      </>
    )}
    {links &&
      links.length > 0 &&
      links.map((link, index) => (
        <Fragment key={index}>
          <MenuLink
            {...extraLinkProps(link.newTab)}
            href={link.url}
            sx={{
              display: 'block',
              fontSize: 'font-body',
            }}
            onClick={() => trackers?.clicked?.(link.trackingLabel ?? link.text)}
          >
            {link.text}
          </MenuLink>
          {link.links &&
            link.links.length &&
            link.links.map((subLink, subIndex) => (
              <MenuLink
                key={subIndex}
                {...extraLinkProps(subLink.newTab)}
                href={subLink.url}
                sx={{
                  display: 'block',
                  fontSize: 'font-body',
                }}
                onClick={() =>
                  trackers?.clicked?.(subLink.trackingLabel ?? subLink.text)
                }
              >
                {subLink.text}
              </MenuLink>
            ))}
        </Fragment>
      ))}
  </>
)
