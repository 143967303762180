/* eslint-disable react/display-name */
import React from 'react'
import { Text } from '@hub/text'
import {
  As,
  HubStyleObject,
  StandardTextAlignment,
  StandardFontSizes,
  StandardLineHeights,
  StandardColors,
  HubResponsiveValue,
  forwardRef,
} from '@hub/design-system-base'
import { Heading as ChakraHeading } from '@chakra-ui/react'

interface HeadingProps {
  as?: As
  tone?: 'neutral' | 'strong' | 'emphasized'
  sx?: HubStyleObject
  align?: StandardTextAlignment
  color?: StandardColors
  lineHeight?: HubResponsiveValue<StandardLineHeights>
  className?: string
  fontSize?: HubResponsiveValue<StandardFontSizes>
  id?: string
}

interface SubHeadingProps {
  size: 'small' | 'medium'
  as?: As
  sx?: HubStyleObject
  id?: string
}

export const SubHeading = forwardRef<SubHeadingProps, typeof ChakraHeading>(
  ({ children, size, as = 'h4', sx, id }, ref) => (
    <ChakraHeading
      ref={ref}
      as={as}
      sx={sx}
      id={id}
      fontWeight={'medium'}
      fontSize={size === 'small' ? 'font-small' : 'font-body'}
      lineHeight={'base'}
      textTransform={'uppercase'}
    >
      {children}
    </ChakraHeading>
  )
)

const createHeading = (
  defaultAs: As,
  baseFontSize: HubResponsiveValue<StandardFontSizes>,
  baseLineHeight: HubResponsiveValue<StandardLineHeights>
) =>
  forwardRef<HeadingProps, typeof Text>(
    (
      {
        as = defaultAs,
        className,
        tone = 'strong',
        align,
        sx,
        lineHeight = baseLineHeight,
        color,
        children,
        fontSize = baseFontSize,
        id,
      },
      ref
    ) => (
      <Text
        ref={ref}
        as={as}
        className={className}
        fontSize={fontSize}
        tone={tone}
        sx={sx}
        align={align}
        lineHeight={lineHeight}
        color={color}
        id={id}
      >
        {children}
      </Text>
    )
  )

export const H1 = createHeading('h1', ['font-h1-mobile', 'font-h1'], 'shorter')
export const H2 = createHeading('h2', ['font-h2-mobile', 'font-h2'], 'shorter')
export const H3 = createHeading('h3', ['font-h3-mobile', 'font-h3'], 'shorter')
export const H4 = createHeading('h4', ['font-h4-mobile', 'font-h4'], 'shorter')
export const H5 = createHeading('h5', ['font-h5-mobile', 'font-h5'], 'shorter')
export const H6 = createHeading('h6', ['font-h6-mobile', 'font-h6'], 'shorter')
