//
// This file is auto generated. See ./README.md for details
//
export { default as AlertLargeSVG } from './assets/large/alert.inline.svg'
export { default as ArrowDownLargeSVG } from './assets/large/arrow-down.inline.svg'
export { default as ArrowLeftLargeSVG } from './assets/large/arrow-left.inline.svg'
export { default as ArrowRightLargeSVG } from './assets/large/arrow-right.inline.svg'
export { default as ArrowUpLargeSVG } from './assets/large/arrow-up.inline.svg'
export { default as AtmLargeSVG } from './assets/large/atm.inline.svg'
export { default as BikeRepairStationLargeSVG } from './assets/large/bike-repair-station.inline.svg'
export { default as BusLargeSVG } from './assets/large/bus.inline.svg'
export { default as CarWashLargeSVG } from './assets/large/car-wash.inline.svg'
export { default as CarLargeSVG } from './assets/large/car.inline.svg'
export { default as CentreMapLargeSVG } from './assets/large/centre-map.inline.svg'
export { default as ChevronDownLargeSVG } from './assets/large/chevron-down.inline.svg'
export { default as ChevronLeftLargeSVG } from './assets/large/chevron-left.inline.svg'
export { default as ChevronRightLargeSVG } from './assets/large/chevron-right.inline.svg'
export { default as ChevronUpLargeSVG } from './assets/large/chevron-up.inline.svg'
export { default as CommunityRoomsLargeSVG } from './assets/large/community-rooms.inline.svg'
export { default as ConciergeLargeSVG } from './assets/large/concierge.inline.svg'
export { default as DeliveryLargeSVG } from './assets/large/delivery.inline.svg'
export { default as DepartmentStoreLargeSVG } from './assets/large/department-store.inline.svg'
export { default as DigitalGiftCardLargeSVG } from './assets/large/digital-gift-card.inline.svg'
export { default as DryCleaningLargeSVG } from './assets/large/dry-cleaning.inline.svg'
export { default as ElectricalVehicleChargingStationLargeSVG } from './assets/large/electrical-vehicle-charging-station.inline.svg'
export { default as EmailLargeSVG } from './assets/large/email.inline.svg'
export { default as EscalatorDownLargeSVG } from './assets/large/escalator-down.inline.svg'
export { default as EscalatorUpLargeSVG } from './assets/large/escalator-up.inline.svg'
export { default as EscalatorLargeSVG } from './assets/large/escalator.inline.svg'
export { default as FamilyLargeSVG } from './assets/large/family.inline.svg'
export { default as FashionStylingLargeSVG } from './assets/large/fashion-styling.inline.svg'
export { default as FashionLargeSVG } from './assets/large/fashion.inline.svg'
export { default as FloralLargeSVG } from './assets/large/floral.inline.svg'
export { default as FoodAndDrinkLargeSVG } from './assets/large/food-and-drink.inline.svg'
export { default as GiftCardLargeSVG } from './assets/large/gift-card.inline.svg'
export { default as GiftCardsLargeSVG } from './assets/large/gift-cards.inline.svg'
export { default as HandsfreeShoppingLargeSVG } from './assets/large/handsfree-shopping.inline.svg'
export { default as HealthAndBeautyLargeSVG } from './assets/large/health-and-beauty.inline.svg'
export { default as HorizontalOverflowLargeSVG } from './assets/large/horizontal-overflow.inline.svg'
export { default as InfoConciergeLargeSVG } from './assets/large/info-concierge.inline.svg'
export { default as InteriorStylingLargeSVG } from './assets/large/interior-styling.inline.svg'
export { default as JusticeOfThePeaceLargeSVG } from './assets/large/justice-of-the-peace.inline.svg'
export { default as KidsPlayworldLargeSVG } from './assets/large/kids-playworld.inline.svg'
export { default as LiftLargeSVG } from './assets/large/lift.inline.svg'
export { default as LocationDropPinLargeSVG } from './assets/large/location-drop-pin.inline.svg'
export { default as LocationMarkerLargeSVG } from './assets/large/location-marker.inline.svg'
export { default as LuggageStorageLargeSVG } from './assets/large/luggage-storage.inline.svg'
export { default as MapDirectionsLargeSVG } from './assets/large/map-directions.inline.svg'
export { default as MobilityScooterHireLargeSVG } from './assets/large/mobility-scooter-hire.inline.svg'
export { default as MoviesLargeSVG } from './assets/large/movies.inline.svg'
export { default as MusicLargeSVG } from './assets/large/music.inline.svg'
export { default as PadlockLockedLargeSVG } from './assets/large/padlock-locked.inline.svg'
export { default as PadlockUnlockedLargeSVG } from './assets/large/padlock-unlocked.inline.svg'
export { default as ParentsRoomLargeSVG } from './assets/large/parents-room.inline.svg'
export { default as ParkingLargeSVG } from './assets/large/parking.inline.svg'
export { default as PhoneLargeSVG } from './assets/large/phone.inline.svg'
export { default as RetailsiteLargeSVG } from './assets/large/retailsite.inline.svg'
export { default as ReturnsLargeSVG } from './assets/large/returns.inline.svg'
export { default as SearchLargeSVG } from './assets/large/search.inline.svg'
export { default as ShoppingCartLargeSVG } from './assets/large/shopping-cart.inline.svg'
export { default as SmartscreenLargeSVG } from './assets/large/smartscreen.inline.svg'
export { default as StairsDownLargeSVG } from './assets/large/stairs-down.inline.svg'
export { default as StairsUpLargeSVG } from './assets/large/stairs-up.inline.svg'
export { default as StoreLargeSVG } from './assets/large/store.inline.svg'
export { default as SuperscreenLargeSVG } from './assets/large/superscreen.inline.svg'
export { default as SustainabilityLargeSVG } from './assets/large/sustainability.inline.svg'
export { default as TaxiLargeSVG } from './assets/large/taxi.inline.svg'
export { default as TickCircleLargeSVG } from './assets/large/tick-circle.inline.svg'
export { default as TickLargeSVG } from './assets/large/tick.inline.svg'
export { default as TicklessParkingLargeSVG } from './assets/large/tickless-parking.inline.svg'
export { default as ToiletAccessibleLargeSVG } from './assets/large/toilet-accessible.inline.svg'
export { default as ToiletFemaleLargeSVG } from './assets/large/toilet-female.inline.svg'
export { default as ToiletMaleLargeSVG } from './assets/large/toilet-male.inline.svg'
export { default as ToiletLargeSVG } from './assets/large/toilet.inline.svg'
export { default as TrainLargeSVG } from './assets/large/train.inline.svg'
export { default as TramLargeSVG } from './assets/large/tram.inline.svg'
export { default as UberPickupLargeSVG } from './assets/large/uber-pickup.inline.svg'
export { default as UndercoverParkingLargeSVG } from './assets/large/undercover-parking.inline.svg'
export { default as UnmeteredParkingLargeSVG } from './assets/large/unmetered-parking.inline.svg'
export { default as ValetParkingLargeSVG } from './assets/large/valet-parking.inline.svg'
export { default as WalletLargeSVG } from './assets/large/wallet.inline.svg'
export { default as WestfieldAppLargeSVG } from './assets/large/westfield-app.inline.svg'
export { default as WestfieldDirectServiceLargeSVG } from './assets/large/westfield-direct-service.inline.svg'
export { default as WestfieldDirectLargeSVG } from './assets/large/westfield-direct.inline.svg'
export { default as WestfieldPlusLargeSVG } from './assets/large/westfield-plus.inline.svg'
export { default as WestfieldSuiteLargeSVG } from './assets/large/westfield-suite.inline.svg'
export { default as WhatsHappeningLargeSVG } from './assets/large/whats-happening.inline.svg'
export { default as WifiLargeSVG } from './assets/large/wifi.inline.svg'
export { default as AfterpayMediumSVG } from './assets/medium/afterpay.inline.svg'
export { default as AlertMediumSVG } from './assets/medium/alert.inline.svg'
export { default as ArrowDownMediumSVG } from './assets/medium/arrow-down.inline.svg'
export { default as ArrowLeftMediumSVG } from './assets/medium/arrow-left.inline.svg'
export { default as ArrowRightMediumSVG } from './assets/medium/arrow-right.inline.svg'
export { default as ArrowUpMediumSVG } from './assets/medium/arrow-up.inline.svg'
export { default as CarMediumSVG } from './assets/medium/car.inline.svg'
export { default as CentreMapMediumSVG } from './assets/medium/centre-map.inline.svg'
export { default as ChevronDownMediumSVG } from './assets/medium/chevron-down.inline.svg'
export { default as ChevronLeftMediumSVG } from './assets/medium/chevron-left.inline.svg'
export { default as ChevronRightMediumSVG } from './assets/medium/chevron-right.inline.svg'
export { default as ChevronUpMediumSVG } from './assets/medium/chevron-up.inline.svg'
export { default as ClearMediumSVG } from './assets/medium/clear.inline.svg'
export { default as ClickAndCollectMediumSVG } from './assets/medium/click-and-collect.inline.svg'
export { default as CommentMediumSVG } from './assets/medium/comment.inline.svg'
export { default as CopyMediumSVG } from './assets/medium/copy.inline.svg'
export { default as CrossMediumSVG } from './assets/medium/cross.inline.svg'
export { default as DeliveryMediumSVG } from './assets/medium/delivery.inline.svg'
export { default as DirectLogoMediumSVG } from './assets/medium/direct-logo.inline.svg'
export { default as DownloadMediumSVG } from './assets/medium/download.inline.svg'
export { default as EditMediumSVG } from './assets/medium/edit.inline.svg'
export { default as EmailMediumSVG } from './assets/medium/email.inline.svg'
export { default as EventMediumSVG } from './assets/medium/event.inline.svg'
export { default as EyeClosedMediumSVG } from './assets/medium/eye-closed.inline.svg'
export { default as EyeOpenMediumSVG } from './assets/medium/eye-open.inline.svg'
export { default as FacebookMediumSVG } from './assets/medium/facebook.inline.svg'
export { default as GiftCardsMediumSVG } from './assets/medium/gift-cards.inline.svg'
export { default as GridViewMediumSVG } from './assets/medium/grid-view.inline.svg'
export { default as HamburgerMediumSVG } from './assets/medium/hamburger.inline.svg'
export { default as HoursMediumSVG } from './assets/medium/hours.inline.svg'
export { default as InfoConciergeMediumSVG } from './assets/medium/info-concierge.inline.svg'
export { default as InstagramMediumSVG } from './assets/medium/instagram.inline.svg'
export { default as ListViewMediumSVG } from './assets/medium/list-view.inline.svg'
export { default as LocationDropPinMediumSVG } from './assets/medium/location-drop-pin.inline.svg'
export { default as NotificationMediumSVG } from './assets/medium/notification.inline.svg'
export { default as OfferMediumSVG } from './assets/medium/offer.inline.svg'
export { default as PadlockLockedMediumSVG } from './assets/medium/padlock-locked.inline.svg'
export { default as PadlockUnlockedMediumSVG } from './assets/medium/padlock-unlocked.inline.svg'
export { default as ParkingMediumSVG } from './assets/medium/parking.inline.svg'
export { default as PaymentMediumSVG } from './assets/medium/payment.inline.svg'
export { default as PhoneMediumSVG } from './assets/medium/phone.inline.svg'
export { default as ReturnsMediumSVG } from './assets/medium/returns.inline.svg'
export { default as SearchMediumSVG } from './assets/medium/search.inline.svg'
export { default as SettingsMediumSVG } from './assets/medium/settings.inline.svg'
export { default as ShoppingCartMediumSVG } from './assets/medium/shopping-cart.inline.svg'
export { default as SortAscendingMediumSVG } from './assets/medium/sort-ascending.inline.svg'
export { default as StoreMediumSVG } from './assets/medium/store.inline.svg'
export { default as TargetMediumSVG } from './assets/medium/target.inline.svg'
export { default as TickCircleMediumSVG } from './assets/medium/tick-circle.inline.svg'
export { default as TickMediumSVG } from './assets/medium/tick.inline.svg'
export { default as TrashBinMediumSVG } from './assets/medium/trash-bin.inline.svg'
export { default as TwitterMediumSVG } from './assets/medium/twitter.inline.svg'
export { default as UserMediumSVG } from './assets/medium/user.inline.svg'
export { default as WalletMediumSVG } from './assets/medium/wallet.inline.svg'
export { default as WaterMediumSVG } from './assets/medium/water.inline.svg'
export { default as WebsiteMediumSVG } from './assets/medium/website.inline.svg'
export { default as WestfieldDirectReturnsMediumSVG } from './assets/medium/westfield-direct-returns.inline.svg'
export { default as WestfieldDirectSwooshMediumSVG } from './assets/medium/westfield-direct-swoosh.inline.svg'
export { default as WestfieldDirectMediumSVG } from './assets/medium/westfield-direct.inline.svg'
export { default as WestfieldWMediumSVG } from './assets/medium/westfield-w.inline.svg'
export { default as WirelessMediumSVG } from './assets/medium/wireless.inline.svg'
export { default as AlertSmallSVG } from './assets/small/alert.inline.svg'
export { default as AmexSmallSVG } from './assets/small/amex.inline.svg'
export { default as ArrowDownSmallSVG } from './assets/small/arrow-down.inline.svg'
export { default as ArrowLeftSmallSVG } from './assets/small/arrow-left.inline.svg'
export { default as ArrowRightSmallSVG } from './assets/small/arrow-right.inline.svg'
export { default as ArrowUpSmallSVG } from './assets/small/arrow-up.inline.svg'
export { default as ChevronDownSmallSVG } from './assets/small/chevron-down.inline.svg'
export { default as ChevronLeftSmallSVG } from './assets/small/chevron-left.inline.svg'
export { default as ChevronRightSmallSVG } from './assets/small/chevron-right.inline.svg'
export { default as ChevronUpSmallSVG } from './assets/small/chevron-up.inline.svg'
export { default as ClearInvertedSmallSVG } from './assets/small/clear-inverted.inline.svg'
export { default as ClearSmallSVG } from './assets/small/clear.inline.svg'
export { default as CommentSmallSVG } from './assets/small/comment.inline.svg'
export { default as CrossSmallSVG } from './assets/small/cross.inline.svg'
export { default as DeliverySmallSVG } from './assets/small/delivery.inline.svg'
export { default as EditSmallSVG } from './assets/small/edit.inline.svg'
export { default as EventSmallSVG } from './assets/small/event.inline.svg'
export { default as EyeClosedSmallSVG } from './assets/small/eye-closed.inline.svg'
export { default as EyeOpenSmallSVG } from './assets/small/eye-open.inline.svg'
export { default as HoursSmallSVG } from './assets/small/hours.inline.svg'
export { default as InfoConciergeSmallSVG } from './assets/small/info-concierge.inline.svg'
export { default as ListViewSmallSVG } from './assets/small/list-view.inline.svg'
export { default as LocationDropPinSmallSVG } from './assets/small/location-drop-pin.inline.svg'
export { default as MastercardSmallSVG } from './assets/small/mastercard.inline.svg'
export { default as MoviesSmallSVG } from './assets/small/movies.inline.svg'
export { default as OfferSmallSVG } from './assets/small/offer.inline.svg'
export { default as PaymentSmallSVG } from './assets/small/payment.inline.svg'
export { default as PhoneSmallSVG } from './assets/small/phone.inline.svg'
export { default as QuestionMarkSmallSVG } from './assets/small/question-mark.inline.svg'
export { default as RefineSmallSVG } from './assets/small/refine.inline.svg'
export { default as SearchSmallSVG } from './assets/small/search.inline.svg'
export { default as ShoppingCartSmallSVG } from './assets/small/shopping-cart.inline.svg'
export { default as StarSmallSVG } from './assets/small/star.inline.svg'
export { default as TickCircleSmallSVG } from './assets/small/tick-circle.inline.svg'
export { default as TickSmallSVG } from './assets/small/tick.inline.svg'
export { default as UserSmallSVG } from './assets/small/user.inline.svg'
export { default as VisaSmallSVG } from './assets/small/visa.inline.svg'
