import querystring, { ParsedUrlQuery } from 'querystring'

export const transformParsedUrlQuery = (
  parsedQuery: ParsedUrlQuery
): string[][] =>
  Object.entries(parsedQuery).flatMap(([key, value]) => {
    if (Array.isArray(value)) {
      return value.map(v => [key, v])
    }
    return [[key, value ?? '']]
  })

const routeMatcher = new RegExp(
  '(\\[\\[\\.\\.\\.(?<optionalCatchAll>\\w+)\\]\\])|(\\[\\.\\.\\.(?<catchAll>\\w+)\\])|(\\[(?<dynamic>\\w+)\\])',
  'g'
)
/**
 * Get a URL path with query/search parameters from a
 * router object (eg. from Next.js useRouter).
 *
 * Will replace dynamic route parameters and include the URL search params.
 *
 * Optional `overrides` parameter allows rewriting the dynamic
 * route parameters and search parameters.
 *
 * Set an override value to `undefined` to remove a search parameter.
 *
 * Returns a string to use as a link to the route.
 */
export function getAbsolutePathFromRouter(
  router: {
    route: string
    basePath: string
    query: ParsedUrlQuery
  },
  queryOverrides?: Record<string, string | string[] | undefined>,
  queryExtra?: Record<string, string | string[]>
): string {
  const { route, basePath } = router
  const query = { ...router.query, ...queryExtra }
  const override = queryOverrides
    ? rewriteParsedUrlQuery(query, queryOverrides)
    : query
  const e = encodeURIComponent
  const catchAllReplacer = (k: string): string => {
    const a = override && override[k]
    if (Array.isArray(a)) {
      return a.map(e).join('/')
    }
    if (typeof a === 'string') {
      return e(a)
    }
    return ''
  }
  const dynamicReplacer = (k: string): string =>
    (override && override[k] && e(String(override[k]))) || ''
  const matches = Array.from(route.matchAll(routeMatcher))
  const path = matches
    .reduce(
      (s, m) =>
        s.replace(
          m[0],
          (m.groups &&
            (catchAllReplacer(m.groups.optionalCatchAll) ||
              catchAllReplacer(m.groups.catchAll) ||
              dynamicReplacer(m.groups.dynamic))) ||
            ''
        ),
      route
    )
    .replace(/\/$/, '')

  const entries = transformParsedUrlQuery(override)
  const searchParams = new URLSearchParams(entries)
  for (const { groups } of matches) {
    if (!groups) {
      continue
    }
    searchParams.delete(
      groups.optionalCatchAll || groups.catchAll || groups.dynamic
    )
  }
  if (Array.from(searchParams).length > 0) {
    return `${basePath}${path}?${searchParams.toString()}`
  }
  return `${basePath}${path}`
}

export function rewriteParsedUrlQuery(
  query: ParsedUrlQuery,
  params: Record<string, string | string[] | undefined>
): ParsedUrlQuery {
  const keys = Object.keys(params)
  const entries = transformParsedUrlQuery(query).reduce((entries, [k, v]) => {
    if (keys.includes(k) && entries.find(([key]) => k === key)) {
      return entries
    }
    const value = keys.includes(k) ? params[k] : v
    if (value === undefined) {
      return entries
    }
    if (Array.isArray(value)) {
      return [...entries, ...value.map(val => [k, val])]
    }
    return [...entries, [k, value]]
  }, [] as string[][])
  return querystring.parse(new URLSearchParams(entries).toString())
}
