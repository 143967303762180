import React, { useContext, PropsWithChildren, useCallback } from 'react'
import Head from 'next/head'
import { Link } from '@scentregroup/shared/link'
import { Box } from '@hub/box'
import { CoreContainer } from '@hub/core-container'
import { H1 } from '@hub/heading'
import {
  AdTargetingContext,
  BasicPageContext,
  CentreContext,
} from '@scentregroup/shared/context'
import { UniversalFooter } from '../../components/universal-footer'
import { completelyCanonicalUrl } from '../../helpers/canonical-link'
import { Centre } from '../../../../apps/website/lib/get-centre-by-slug'
import { ITopStripProps } from '../top-strip'
import trackClickEvents, { categories, labels } from '../analytics/trackEvents'
import {
  Header,
  HeaderBreadcrumb,
  HeaderInner,
  HeaderMenus,
  HeaderNavigationBar,
  HeaderOuter,
  HeaderPrimaryMenu,
  HeaderQuickLinks,
  HeaderSearch,
  HeaderSecondaryMenu,
} from '../header'
import { CentresProvider } from '../../centres'
import { useLocalUser } from '../../hooks/use-local-user'
import registerRedirect from '../../helpers/register-redirect'
import { MagicLinkValue, useMagicLink } from '../magic-link'
import { useLayoutSearch } from './use-layout-search'
import { useLayoutLogo } from './use-layout-logo'
import { useLayoutNavigation } from './use-layout-navigation'
import { MetaData } from '../../types/meta-data'
import { StaticData } from '../../helpers/fetch-header-static-data/fetch-universal-header-static-data'
import { INavigationMenuWithMegaMenu } from '../header/types'
import { useLayoutAnalytics } from './use-layout-analytics'

export interface ILayoutProps {
  country: 'Australia' | 'New Zealand'
  header: {
    variant: string
    data: StaticData | null
  } | null
  topStrip?: ITopStripProps | null
  centre?: Centre & { navigationMenu: INavigationMenuWithMegaMenu }
  metaData?: MetaData
  pageTitle?: string
  showFooter?: boolean
  showCVPStrip?: boolean
  taskMode?: boolean
  backLink?: string
  mainStyles?: React.CSSProperties
  showRobotsMetaTag?: boolean
  quickLinks?: React.ComponentProps<typeof HeaderQuickLinks>['items']
  breadcrumbs?: React.ComponentProps<typeof HeaderBreadcrumb>['items']
  onClickBack?: React.MouseEventHandler
}

function safeMeta<T = any>(value: T): T | undefined {
  return typeof value === 'string' && value === '' ? undefined : value
}

const handleClickTopStrip: React.MouseEventHandler = () => {
  trackClickEvents.clicked(categories.UNIVERSAL_HEADER, labels.BENEFITS_STRIP)
}

export default function Layout({
  country,
  centre,
  header,
  metaData,
  pageTitle,
  showFooter = true,
  taskMode = false,
  backLink,
  children,
  mainStyles,
  topStrip: topStripProps,
  showRobotsMetaTag = true,
  quickLinks,
  breadcrumbs,
  showCVPStrip = true,
  onClickBack,
}: PropsWithChildren<ILayoutProps>): JSX.Element {
  const showChrome = !useContext(BasicPageContext)

  const canonical = safeMeta(metaData?.canonical)
  const title = safeMeta(metaData?.title)
  const description = safeMeta(metaData?.description)

  const twitterCreator = safeMeta(metaData?.twitter?.creator)

  const openGraphUrl = safeMeta(metaData?.openGraph?.url) ?? canonical
  const openGraphTitle = safeMeta(metaData?.openGraph?.title) ?? title
  const openGraphDescription =
    safeMeta(metaData?.openGraph?.description) ?? description
  const openGraphImage = safeMeta(metaData?.openGraph?.image)

  let twitterCard = safeMeta(metaData?.twitter?.card)
  if (openGraphImage) {
    twitterCard ??= 'summary_large_image'
  } else if (openGraphTitle || openGraphDescription) {
    twitterCard ??= 'summary'
  }

  // Slightly different from the logic in Westfield Direct layout as it has to deal with legacy canonical support
  const completeCanonical =
    canonical && completelyCanonicalUrl(canonical, metaData?.canonicalParams)

  const { logoLink, logoLabel, logoSlug } = useLayoutLogo(
    centre,
    header?.variant
  )
  const {
    isOpenPrimaryMenu,
    setIsOpenPrimaryMenu,
    isOpenSecondaryMenu,
    setIsOpenSecondaryMenu,
    giftCards,
    primaryMenuItems,
    secondaryMenuItems,
  } = useLayoutNavigation(
    header?.data?.giftCards,
    header?.data?.primaryLinks,
    header?.data?.tertiaryLinks
  )

  const [
    isOpenSearchModal,
    setIsOpenSearchModal,
    specialSearchKind,
    getSources,
    searchOnSubmit,
    reshape,
  ] = useLayoutSearch(country, centre)
  const user = useLocalUser()
  const [magicLink, setMagicLink] = useMagicLink()
  React.useEffect(() => {
    if (
      magicLink === MagicLinkValue.stores ||
      magicLink === MagicLinkValue.centres
    ) {
      setIsOpenSearchModal(true)
    }
  }, [magicLink, setIsOpenSearchModal])
  React.useEffect(() => {
    if (!isOpenSearchModal) {
      setMagicLink()
    }
  }, [isOpenSearchModal, setMagicLink])

  const { centres } = header?.data || {}

  const handleClosePrimaryMenu = React.useCallback(
    () => setIsOpenPrimaryMenu(false),
    [setIsOpenPrimaryMenu]
  )

  const analyticsHandlers = useLayoutAnalytics()

  const onSubmit = useCallback(
    (query: string): void => {
      if (query.length > 0) {
        analyticsHandlers.handleClickSearchSubmit(query, centre)
        searchOnSubmit(query)
      }
    },
    [analyticsHandlers, centre, searchOnSubmit]
  )

  return (
    <CentreContext.Provider value={centre}>
      <CentresProvider centres={centres || []}>
        <Head>
          {title && <title>{title}</title>}
          {description && (
            <meta key="description" name="description" content={description} />
          )}

          {twitterCreator && (
            <meta name="twitter:creator" content={twitterCreator} />
          )}
          {twitterCard && <meta name="twitter:card" content={twitterCard} />}
          {openGraphUrl && <meta property="og:url" content={openGraphUrl} />}
          {openGraphTitle && (
            <meta property="og:title" content={openGraphTitle} />
          )}
          {openGraphDescription && (
            <meta property="og:description" content={openGraphDescription} />
          )}
          {openGraphImage && (
            <meta property="og:image" content={openGraphImage} />
          )}

          {completeCanonical && (
            <>
              <meta
                key="og:url"
                property="og:url"
                content={completeCanonical}
              />
              <link key="canonical" rel="canonical" href={completeCanonical} />
            </>
          )}
          {showRobotsMetaTag && (
            <meta name="robots" content="max-image-preview:large" />
          )}
        </Head>
        <AdTargetingContext.Provider value={{ centreSlug: centre?.slug }}>
          <nav aria-label="Header">
            {showChrome && (
              <Header
                country={country}
                logoLink={logoLink}
                logoLabel={logoLabel}
                logoSlug={logoSlug}
                topStripProps={
                  (showCVPStrip ? topStripProps : null) ?? undefined
                }
                user={user}
                taskMode={taskMode}
                backLink={backLink}
                onClickPrimaryMenu={() => setIsOpenPrimaryMenu(true)}
                onClickSecondaryMenu={() => setIsOpenSecondaryMenu(true)}
                onClickTopStrip={handleClickTopStrip}
                onClickLogin={() => registerRedirect()}
                onClickSearch={() => setIsOpenSearchModal(true)}
                onClickBack={onClickBack}
                onClickLogo={analyticsHandlers.handleClickLogo}
              >
                <HeaderMenus>
                  <HeaderPrimaryMenu
                    isOpen={isOpenPrimaryMenu}
                    onClose={handleClosePrimaryMenu}
                    country={country}
                    centre={centre}
                    giftCards={giftCards ?? []}
                    onClickLink={analyticsHandlers.handleClickMenuLink}
                    onClickButton={analyticsHandlers.handleClickMenuButton}
                  />
                  <HeaderSecondaryMenu
                    name={user?.firstName ?? undefined}
                    isOpen={isOpenSecondaryMenu}
                    onClose={() => setIsOpenSecondaryMenu(false)}
                    onClickLink={analyticsHandlers.handleClickMenuLink}
                  />
                  <HeaderSearch
                    isOpen={isOpenSearchModal}
                    onClose={() => setIsOpenSearchModal(false)}
                    getSources={getSources}
                    specialSearchKind={specialSearchKind}
                    country={country}
                    onSubmit={onSubmit}
                    reshape={reshape}
                  />
                </HeaderMenus>
                <HeaderInner>
                  <HeaderNavigationBar
                    primaryMenuItems={
                      primaryMenuItems?.length ? primaryMenuItems : undefined
                    }
                    secondaryMenuItems={secondaryMenuItems}
                    onClickLink={analyticsHandlers.handleClickNavigationBarLink}
                  />
                  <HeaderQuickLinks items={quickLinks} />
                </HeaderInner>
                <HeaderOuter>
                  <HeaderBreadcrumb items={breadcrumbs} />
                </HeaderOuter>
              </Header>
            )}
            {pageTitle && (
              <CoreContainer>
                <H1 sx={{ marginBottom: 'spacing-sm' }}>{pageTitle}</H1>
              </CoreContainer>
            )}
          </nav>

          <Box
            as="main"
            id="main-content"
            style={mainStyles}
            sx={{ flex: '1 0 auto' }}
          >
            {children}
          </Box>

          {showChrome && showFooter && (
            <Box sx={{ marginTop: ['spacing-4xl', null, 'spacing-5xl'] }}>
              {taskMode ? (
                <UniversalFooter country={country} taskMode={true} />
              ) : (
                <UniversalFooter
                  Link={Link}
                  centre={centre}
                  country={country}
                  onFindACentre={() => {
                    setMagicLink(MagicLinkValue.centres)
                  }}
                />
              )}
            </Box>
          )}
        </AdTargetingContext.Provider>
      </CentresProvider>
    </CentreContext.Provider>
  )
}
