import React, { useCallback, useEffect } from 'react'
import { Box } from '@hub/box'

import { Auto, BaseItem, RenderContext, ResetState } from '../types'
import type { RefsAndCursor } from './use-refs-and-cursor'
import SearchInput from './search-input'
import QuitButton from './quit-button'
import ResultsPanel from './results-panel'
import { AutocompleteCollection } from '@algolia/autocomplete-core'
import { StandardColors } from '@hub/design-system-base'
import { CoreContainer } from '@hub/core-container'

interface SearchFormProps {
  refsAndCursor: RefsAndCursor
  autocomplete: Auto
  resetState: ResetState
  collections: AutocompleteCollection<BaseItem>[]
  context: RenderContext
  onClickClose: () => void
  placeholder?: string
  reinitialiseQuery: () => void
  setNonDebouncedQuery: (query: string) => void
  backgroundColor: StandardColors
}

const SearchForm: React.FC<React.PropsWithChildren<SearchFormProps>> = ({
  refsAndCursor,
  autocomplete,
  placeholder,
  resetState,
  collections,
  context,
  onClickClose,
  reinitialiseQuery,
  setNonDebouncedQuery,
  backgroundColor,
}) => {
  const { autocompleteRefs, resetCursor } = refsAndCursor

  useEffect(() => {
    autocomplete.refresh()
    // Honestly we only want to run this once, when the component first renders
    // We don't care if the dependency changes I promise.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const inputElement = refsAndCursor.autocompleteRefs.getElems().inputElement
  const clearQuery = useCallback((): void => {
    // But we still need to set the input value when the query is cleared
    if (inputElement) {
      inputElement.value = ''
      inputElement.focus()
    }
    resetState()
    resetCursor()
    setNonDebouncedQuery('')
  }, [inputElement, resetState, setNonDebouncedQuery, resetCursor])

  return (
    <Box
      sx={{
        background: backgroundColor,
        width: '100%',
        maxWidth: [null, null, '520px'],
        marginTop: ['spacing-md', 'spacing-md', 'spacing-lg'],
        marginLeft: ['spacing-none', 'spacing-none', 'spacing-3xl'],
        marginRight: ['spacing-none', 'spacing-none', 'spacing-2xl'],
      }}
      {...autocomplete.getRootProps(refsAndCursor.autocompleteRefs.getElems())}
    >
      <CoreContainer
        gutter={['spacing-mobile-margin', 'spacing-lg', 'spacing-md']}
      >
        <form
          ref={autocompleteRefs.formRef}
          {...autocomplete.getFormProps(autocompleteRefs.getElems())}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: ['block', 'block', 'none'],
                marginBottom: 'spacing-md',
              }}
            >
              <QuitButton
                sx={{
                  padding: 'spacing-none',
                  float: 'right',
                  background: 'transparent',
                }}
                onClickClose={onClickClose}
              />
            </Box>
            <SearchInput
              {...{
                query: context.query,
                onClickClose,
                refsAndCursor,
                autocomplete,
                placeholder,
                clearQuery,
                reinitialiseQuery,
                setNonDebouncedQuery,
              }}
            />
            <ResultsPanel
              {...{
                backgroundColor,
                collections,
                context,
                refsAndCursor,
                autocomplete,
                reinitialiseQuery,
              }}
            />
          </Box>
        </form>
      </CoreContainer>
    </Box>
  )
}

export default SearchForm
