import React from 'react'
import { Icon as ChakraIcon } from '@chakra-ui/react'
import {
  HubResponsiveValue,
  StandardIconSizes,
  HubStyleObject,
  forwardRef,
} from '@hub/design-system-base'
import { default as WestfieldMembershipLogoSVG } from './assets/westfield-membership-logo.inline.svg'

interface WestfieldMembershipLogoProps {
  boxSize?: HubResponsiveValue<StandardIconSizes>
  sx?: HubStyleObject
  label?: string
}

export const WestfieldMembershipLogo: React.FC<React.PropsWithChildren<WestfieldMembershipLogoProps>> =
  forwardRef<WestfieldMembershipLogoProps, typeof ChakraIcon>(
    (
      { boxSize = 'size-icon-line-height-shorter', sx, label = 'Westfield' },
      ref
    ) => (
      <ChakraIcon
        aria-label={label}
        ref={ref}
        minW={boxSize}
        maxH={boxSize}
        w="size-auto"
        h="size-auto"
        as={WestfieldMembershipLogoSVG}
        sx={sx}
      />
    )
  )
