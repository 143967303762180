import React from 'react'
import { Stack } from '@hub/stack'
import { LinkBox } from '@hub/link'
import { LinkItem } from './types'
import { FeatureDetails } from './feature-details'
import { FeatureImage } from './feature-image'

export interface FeatureProps {
  image?: {
    src: string
    description: string
  }
  title?: string
  body?: string
  link?: LinkItem
  onClick?: React.MouseEventHandler
}

export const Feature: React.FC<React.PropsWithChildren<FeatureProps>> = ({
  title,
  link,
  body,
  onClick,
  image,
}) => {
  const commonStackProps = {
    sx: {
      width: 'size-xl',
      maxHeight: 'size-full',
      display: ['none', null, null, 'initial'],
    },
    gap: 'spacing-md',
    shouldWrapChildren: false,
  }

  return link ? (
    <LinkBox>
      <Stack {...commonStackProps}>
        <FeatureImage image={image} />
        <FeatureDetails
          title={title}
          body={body}
          link={link}
          onClick={onClick}
        />
      </Stack>
    </LinkBox>
  ) : (
    <Stack {...commonStackProps}>
      <FeatureImage image={image} />
      <FeatureDetails title={title} body={body} />
    </Stack>
  )
}
